import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { useSelector } from "react-redux";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import { compName } from "../../../../utils/constant";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import DoughnutChart from "../../../CommonComponent/DoughnutChart ";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import lowPrio from "../../../../Assets/Images/Revenue-management/lowPriority.svg";
import highPrio from "../../../../Assets/Images/Revenue-management/highPriority.svg";

const WeeklyData = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  let otbOcc = 0,
    otbRms = 0,
    otbAdr = 0,
    otbRev = 0,
    stlyOcc = 0,
    stlyRms = 0,
    stlyAdr = 0,
    stlyRev = 0,
    lyOcc = 0,
    lyRms = 0,
    lyAdr = 0,
    lyRev = 0,
    budgetOcc = 0,
    budgetRms = 0,
    budgetAdr = 0,
    budgetRev = 0,
    forecastOcc = 0,
    forecastRms = 0,
    forecastAdr = 0,
    forecastRev = 0;
  const dayDataArrRef = useRef();
  const [dayData, setDayData] = useState(null);
  const [dayDataLoading, setDayDataLoading] = useState(false);
  const prevDayDataArrRef = useRef();
  const [prevDayData, setPrevDayData] = useState(null);
  const [prevDayDataLoading, setPrevDayDataLoading] = useState(false);
  const [reservationData, setReservationData] = useState(null);
  const [reservationLoading, setReservationLoading] = useState(false);
  const groupDataArrRef = useRef();
  const [groupData, setGroupData] = useState(null);
  const [groupLoading, setGroupLoading] = useState(false);
  const varianceRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const clickedDateForNote = useRef();
  const callApi = useRef(false);
  const isFilterApiCalled = useRef(false);
  const [segmentDrilldownData, setSegmentDrilldownData] = useState(null);
  const [loading, setLoading] = useState(false);
  const rateCodeDataRef = useRef(false);
  const companyDataRef = useRef(false);
  const guestDataRef = useRef(false);
  const totalRef = useRef();
  const clickedTrRef = useRef();
  const selectedMarketSegmentRef = useRef();
  const selectedRateCodeRef = useRef();
  const selectedCompanyRef = useRef();
  const calledMarketSegmentRef = useRef([]);
  const [msLoading, setMsLoading] = useState(false);
  const calledRateCodeRef = useRef([]);
  const [rateCodeLoading, setRateCodeLoading] = useState(false);
  const calledCompanyRef = useRef([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [ReservationRateTitle, setReservationRateTitle] = useState(null);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  let curr = new Date(para?.asOfDate);
  let first = curr.getDate() - curr.getDay();
  let last = first + 6;

  let firstday = new Date(curr.setDate(first));
  let lastday = new Date(curr.setDate(last));

  const getDayData = async () => {
    setDayDataLoading(true);
    let dayDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.WEEKLY_DATA_WIDGET_ONE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, dayDataResponse);

    if (response?.isValidate) {
      // let curr = new Date(para?.asOfDate);

      // let first = curr.getDate() - curr.getDay();
      // let last = first + 6;

      // let firstday = new Date(curr.setDate(first));
      // let lastday = new Date(curr.setDate(last));

      let firstday = moment(para?.asOfDate).startOf("week").toDate();
      let lastday = moment(para?.asOfDate).endOf("week").toDate();

      dayDataArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      setDayData(response?.data?.data);
      setDayDataLoading(false);
    }

    if (!response?.isValidate) {
      // let curr = new Date(para?.asOfDate);
      // let first = curr.getDate() - curr.getDay();
      // let last = first + 6;

      // let firstday = new Date(curr.setDate(first));
      // let lastday = new Date(curr.setDate(last));

      let firstday = moment(para?.asOfDate).startOf("week").toDate();
      let lastday = moment(para?.asOfDate).endOf("week").toDate();
      dayDataArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      setDayData(null);
      setDayDataLoading(false);
    }
  };

  const getPrevDayData = async () => {
    setPrevDayDataLoading(true);
    let prevWeekAsOfDate = new Date(para?.asOfDate);
    prevWeekAsOfDate.setDate(prevWeekAsOfDate.getDate() - 7);
    let dayDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.WEEKLY_DATA_WIDGET_ONE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: commonService.getDateForNode(prevWeekAsOfDate),
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, dayDataResponse);

    if (response?.isValidate) {
      // let curr = new Date(prevWeekAsOfDate);
      // let first = curr.getDate() - curr.getDay();
      // let last = first + 6;

      // let firstday = new Date(curr.setDate(first));
      // let lastday = new Date(curr.setDate(last));

      let firstday = moment(prevWeekAsOfDate).startOf("week").toDate();
      let lastday = moment(prevWeekAsOfDate).endOf("week").toDate();
      prevDayDataArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      setPrevDayData(response?.data?.data);
      setPrevDayDataLoading(false);
    }

    if (!response?.isValidate) {
      // let curr = new Date(prevWeekAsOfDate);
      // let first = curr.getDate() - curr.getDay();
      // let last = first + 6;

      // let firstday = new Date(curr.setDate(first));
      // let lastday = new Date(curr.setDate(last));
      let firstday = moment(prevWeekAsOfDate).startOf("week").toDate();
      let lastday = moment(prevWeekAsOfDate).endOf("week").toDate();
      prevDayDataArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      setPrevDayData(null);
      setPrevDayDataLoading(false);
    }
  };

  const findDiffRate = (adr, rate, min, max) => {
    let diff = ((rate - adr) / adr) * 100;
    if (diff < 0) {
      diff = -diff;
      if (min < diff) {
        return {
          status: 1,
          diff: diff,
        };
      }
    }
    if (diff > 0) {
      if (max < diff) {
        return {
          status: 2,
          diff: diff,
        };
      } else {
        return 0;
      }
    }
  };

  const getReservationData = async () => {
    setReservationRateTitle(null);
    setReservationLoading(true);
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.WEEKLY_DATA_WIDGET_THREE,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      setReservationRateTitle({
        min: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.min,
        max: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.max,
      });
      setReservationData(
        response?.data?.data.map((d) => ({
          ...d,
          RateStatus: findDiffRate(
            d.ADR,
            d.Rate,
            JSON.parse(d?.rulevalues)?.[0]?.min,
            JSON.parse(d?.rulevalues)?.[0]?.max
          ),
        }))
      );
      setReservationLoading(false);
    }

    if (!response?.isValidate) {
      setReservationData(null);
      setReservationLoading(false);
    }
  };

  const getGroupData = async () => {
    setGroupLoading(true);
    let groupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.WEEKLY_DATA_WIDGET_TWO,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
        },
      },
      false
    );

    let response = apiResponse(false, groupResponse);

    if (response?.isValidate) {
      let curr = new Date(para?.asOfDate);
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;
      let firstday = new Date(curr.setDate(first));
      let lastday = new Date(curr.setDate(last));

      let prevcurr = new Date(para?.asOfDate);
      prevcurr.setDate(prevcurr.getDate() - 7);
      let prevfirst = prevcurr.getDate() - prevcurr.getDay();
      let prevlast = prevfirst + 6;
      let prevfirstday = new Date(prevcurr.setDate(prevfirst));
      let prevlastday = new Date(prevcurr.setDate(prevlast));

      let nextcurr = new Date(para?.asOfDate);
      nextcurr.setDate(nextcurr.getDate() + 7);
      let nextfirst = nextcurr.getDate() - nextcurr.getDay();
      let nextlast = nextfirst + 6;
      let nextfirstday = new Date(nextcurr.setDate(nextfirst));
      let nextlastday = new Date(nextcurr.setDate(nextlast));

      groupDataArrRef.current = [
        `${commonService.getDateInFormat(
          prevfirstday
        )} - ${commonService.getDateInFormat(prevlastday)}`,
        `${commonService.getDateInFormat(
          firstday
        )} - ${commonService.getDateInFormat(lastday)}`,
        `${commonService.getDateInFormat(
          nextfirstday
        )} - ${commonService.getDateInFormat(nextlastday)}`,
      ];

      let total = {
        tRmsVar: 0,
        tAdrVar: 0,
        tRevVar: 0,
        gRmsVar: 0,
        gAdrVar: 0,
        gRevVar: 0,
        prevtRmsVar: 0,
        prevtAdrVar: 0,
        prevtRevVar: 0,
        prevgRmsVar: 0,
        prevgAdrVar: 0,
        prevgRevVar: 0,
        nexttRmsVar: 0,
        nexttAdrVar: 0,
        nexttRevVar: 0,
        nextgRmsVar: 0,
        nextgAdrVar: 0,
        nextgRevVar: 0,
      };

      total.tRmsVar =
        (response?.data?.data?.[0]?.Transient_RMS
          ? response?.data?.data?.[0]?.Transient_RMS
          : 0) -
        (response?.data?.data?.[0]?.LY_Transient_RMS
          ? response?.data?.data?.[0]?.LY_Transient_RMS
          : 0);
      total.tAdrVar =
        (response?.data?.data?.[0]?.Transient_ADR
          ? response?.data?.data?.[0]?.Transient_ADR
          : 0) -
        (response?.data?.data?.[0]?.LY_Transient_ADR
          ? response?.data?.data?.[0]?.LY_Transient_ADR
          : 0);
      total.tRevVar =
        (response?.data?.data?.[0]?.Transient_REV
          ? response?.data?.data?.[0]?.Transient_REV
          : 0) -
        (response?.data?.data?.[0]?.LY_Transient_REV
          ? response?.data?.data?.[0]?.LY_Transient_REV
          : 0);

      total.gRmsVar =
        (response?.data?.data?.[0]?.Group_RMS
          ? response?.data?.data?.[0]?.Group_RMS
          : 0) -
        (response?.data?.data?.[0]?.LY_Group_RMS
          ? response?.data?.data?.[0]?.LY_Group_RMS
          : 0);
      total.gAdrVar =
        (response?.data?.data?.[0]?.Group_ADR
          ? response?.data?.data?.[0]?.Group_ADR
          : 0) -
        (response?.data?.data?.[0]?.LY_Group_ADR
          ? response?.data?.data?.[0]?.LY_Group_ADR
          : 0);
      total.gRevVar =
        (response?.data?.data?.[0]?.Group_REV
          ? response?.data?.data?.[0]?.Group_REV
          : 0) -
        (response?.data?.data?.[0]?.LY_Group_REV
          ? response?.data?.data?.[0]?.LY_Group_REV
          : 0);

      total.prevtRmsVar =
        (response?.data?.data?.[0]?.Previous_Week_Transient_RMS
          ? response?.data?.data?.[0]?.Previous_Week_Transient_RMS
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Transient_RMS
          ? response?.data?.data?.[0]?.Previous_Week_LY_Transient_RMS
          : 0);
      total.prevtAdrVar =
        (response?.data?.data?.[0]?.[0]?.Previous_Week_Transient_ADR
          ? response?.data?.data?.[0]?.Previous_Week_Transient_ADR
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Transient_ADR
          ? response?.data?.data?.[0]?.Previous_Week_LY_Transient_ADR
          : 0);
      total.prevtRevVar =
        (response?.data?.data?.[0]?.Previous_Week_Group_REV
          ? response?.data?.data?.[0]?.Previous_Week_Group_REV
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Transient_REv
          ? response?.data?.data?.[0]?.Previous_Week_LY_Transient_REv
          : 0);

      total.prevgRmsVar =
        (response?.data?.data?.[0]?.Previous_Week_Group_RMS
          ? response?.data?.data?.[0]?.Previous_Week_Group_RMS
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Group_RMS
          ? response?.data?.data?.[0]?.Previous_Week_LY_Group_RMS
          : 0);
      total.prevgAdrVar =
        (response?.data?.data?.[0]?.Previous_Week_Group_ADR
          ? response?.data?.data?.[0]?.Previous_Week_Group_ADR
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Group_ADR
          ? response?.data?.data?.[0]?.Previous_Week_LY_Group_ADR
          : 0);
      total.prevgRevVar =
        (response?.data?.data?.[0]?.Previous_Week_Group_REV
          ? response?.data?.data?.[0]?.Previous_Week_Group_REV
          : 0) -
        (response?.data?.data?.[0]?.Previous_Week_LY_Group_REV
          ? response?.data?.data?.[0]?.Previous_Week_LY_Group_REV
          : 0);

      total.nexttRmsVar =
        (response?.data?.data?.[0]?.Next_Week_Transient_RMS
          ? response?.data?.data?.[0]?.Next_Week_Transient_RMS
          : 0) -
        (response?.data?.data?.Next_Week_LY_Transient_RMS
          ? response?.data?.data?.Next_Week_LY_Transient_RMS
          : 0);
      total.nexttAdrVar =
        (response?.data?.data?.[0]?.Next_Week_Transient_ADR
          ? response?.data?.data?.[0]?.Next_Week_Transient_ADR
          : 0) -
        (response?.data?.data?.[0]?.Next_Week_LY_Transient_ADR
          ? response?.data?.data?.[0]?.Next_Week_LY_Transient_ADR
          : 0);
      total.nexttRevVar =
        (response?.data?.data?.[0]?.Next_Week_Transient_REV
          ? response?.data?.data?.[0]?.Next_Week_Transient_REV
          : 0) -
        (response?.data?.data?.[0]?.Next_Week_LY_Transient_REv
          ? response?.data?.data?.[0]?.Next_Week_LY_Transient_REv
          : 0);

      total.nextgRmsVar =
        (response?.data?.data?.[0]?.Next_Week_Group_RMS
          ? response?.data?.data?.[0]?.Next_Week_Group_RMS
          : 0) -
        (response?.data?.data?.[0]?.Next_Week_LY_Group_RMS
          ? response?.data?.data?.[0]?.Next_Week_LY_Group_RMS
          : 0);
      total.nextgAdrVar =
        (response?.data?.data?.[0]?.Next_Week_Group_ADR
          ? response?.data?.data?.[0]?.Next_Week_Group_ADR
          : 0) -
        (response?.data?.data?.[0]?.Next_Week_LY_Group_ADR
          ? response?.data?.data?.[0]?.Next_Week_LY_Group_ADR
          : 0);
      total.nextgRevVar =
        (response?.data?.data?.[0]?.Next_Week_Group_REV
          ? response?.data?.data?.[0]?.Next_Week_Group_REV
          : 0) -
        (response?.data?.data?.[0]?.Next_Week_LY_Group_REV
          ? response?.data?.data?.[0]?.Next_Week_LY_Group_REV
          : 0);
      varianceRef.current = total;
      setGroupData(response?.data?.data);
      setGroupLoading(false);
    }

    if (!response?.isValidate) {
      let curr = new Date(para?.asOfDate);
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;
      let firstday = new Date(curr.setDate(first));
      let lastday = new Date(curr.setDate(last));

      let prevcurr = new Date(para?.asOfDate);
      prevcurr.setDate(prevcurr.getDate() - 7);
      let prevfirst = prevcurr.getDate() - prevcurr.getDay();
      let prevlast = first + 6;
      let prevfirstday = new Date(prevcurr.setDate(prevfirst));
      let prevlastday = new Date(prevcurr.setDate(prevlast));

      let nextcurr = new Date(para?.asOfDate);
      nextcurr.setDate(nextcurr.getDate() + 7);
      let nextfirst = nextcurr.getDate() - nextcurr.getDay();
      let nextlast = first + 6;
      let nextfirstday = new Date(nextcurr.setDate(nextfirst));
      let nextlastday = new Date(nextcurr.setDate(nextlast));

      groupDataArrRef.current = [
        `${commonService.getDateInFormat(
          prevfirstday
        )} - ${commonService.getDateInFormat(prevlastday)}`,
        `${commonService.getDateInFormat(
          firstday
        )} - ${commonService.getDateInFormat(lastday)}`,
        `${commonService.getDateInFormat(
          nextfirstday
        )} - ${commonService.getDateInFormat(nextlastday)}`,
      ];
      setGroupData(null);
      setGroupLoading(false);
    }
  };

  const showOnApiCall = (idArray) => {
    for (let i = 0; i < idArray?.length; i++) {
      const classList = document.getElementsByClassName(idArray[i]);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        }
      }
    }
  };

  const handleShowHideRow = (className, itemDetail) => {
    if (document.getElementById(className) !== null) {
      if (document.getElementById(className).classList.contains("active")) {
        document.getElementById(className).classList.remove("active");
      } else {
        document.getElementById(className).classList.add("active");
      }
    }

    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        } else {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };

  const checkActiveRow = (e, guid, itemDetail) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      e.currentTarget.classList.remove("singleDateSummmaryTr");
    } else {
      e.currentTarget.classList.add("active");
      e.currentTarget.classList.add("singleDateSummmaryTr");
    }
  };

  const getMarketSegmentData = async () => {
    setLoading(true);

    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.EMAIL_WIDGET.WEEKLY_MS,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let total = {
        leftRoom: 0,
        leftAdr: 0,
        leftRev: 0,
        leftRevContri: 100,
        rightRoom: 0,
        rightAdr: 0,
        rightRev: 0,
        rightRevContri: 100,
      };
      let doughnutData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };

      let groupingData = dataFromApi?.map((item) => {
        // let color = Math.floor(Math.random() * 16777215).toString(16);
        let color = commonService.CreateColor(`${item.marketsegment}`).BGColor;
        let marketSegmentName = item.marketsegment;
        let marketSegmentPr = item?.left_RMS ? item?.left_RMS : 0;
        doughnutData.labels.push(marketSegmentName);
        doughnutData.datasets[0].data.push(marketSegmentPr);
        doughnutData.datasets[0].backgroundColor.push(`${color}`);
        doughnutData.datasets[0].hoverBackgroundColor.push(`${color}`);

        item.items = [];
        item.guid = commonService.GenerateGUID();
        item.name = item?.marketsegment;
        item.varientRms =
          (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
          (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
        item.varientAdr =
          (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
          (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
        item.varientRev =
          (item?.left_REV ? parseInt(item?.left_REV) : 0) -
          (item?.right_REV ? parseInt(item?.right_REV) : 0);

        if (item?.left_RMS) {
          total.leftRoom = total.leftRoom + parseInt(item?.left_RMS);
        }
        if (item?.left_ADR) {
          total.leftAdr = total.leftAdr + parseInt(item?.left_ADR);
        }
        if (item?.left_REV) {
          total.leftRev = total.leftRev + parseInt(item?.left_REV);
        }

        if (item?.right_RMS) {
          total.rightRoom = total.rightRoom + parseInt(item?.right_RMS);
        }
        if (item?.right_ADR) {
          total.rightAdr = total.rightAdr + parseInt(item?.right_ADR);
        }
        if (item?.right_REV) {
          total.rightRev = total.rightRev + parseInt(item?.right_REV);
        }

        return item;
      });

      setChartData(doughnutData);
      setSegmentDrilldownData(groupingData);
      totalRef.current = total;
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSegmentDrilldownData([]);
      setLoading(false);
    }
  };

  const getRateCodeData = async () => {
    setMsLoading(true);
    let rateCodeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.EMAIL_WIDGET.WEEKLY_RATECODE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          selectedMarketSegment: selectedMarketSegmentRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, rateCodeResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );

        if (findMarketsegment >= 0) {
          item.name = item?.RateCode;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items = [
            ...groupingJson?.[findMarketsegment]?.items,
            item,
          ];
        }
      });
      calledMarketSegmentRef.current = [
        ...calledMarketSegmentRef.current,
        selectedMarketSegmentRef.current,
      ];
      setSegmentDrilldownData(groupingJson);
      setMsLoading(false);
    }

    if (!response?.isValidate) {
      setMsLoading(false);
    }
  };

  const getCompanyData = async () => {
    setRateCodeLoading(true);
    let companyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.EMAIL_WIDGET.WEEKLY_COMPANY,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          selectedMarketSegment: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, companyDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        if (findRatecode >= 0) {
          item.name = item?.Company;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items,
            item,
          ];
        }
      });
      calledRateCodeRef.current = [
        ...calledRateCodeRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      setRateCodeLoading(false);
    }

    if (!response?.isValidate) {
      setRateCodeLoading(false);
    }
  };

  const getGuestData = async () => {
    setCompanyLoading(true);
    let guestDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.EMAIL_WIDGET.WEEKLY_GUESTNAME,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          selectedMarketSegment: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
          selectedCompany: selectedCompanyRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, guestDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        let findCompany = groupingJson[findMarketsegment].items[
          findRatecode
        ].items?.findIndex((x) => x?.name === item?.Company);

        if (findCompany >= 0) {
          item.name = item?.GuestName;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items[
            findCompany
          ].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items[
              findCompany
            ].items,
            item,
          ];
        }
      });
      calledCompanyRef.current = [
        ...calledCompanyRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
          company: selectedCompanyRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      setCompanyLoading(false);
    }

    if (!response?.isValidate) {
      setCompanyLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getDayData();
        getPrevDayData();
        getReservationData();
        getGroupData();
      }
    }
  }, [para?.asOfDate]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getMarketSegmentData();
      }
    }
  }, [para?.asOfDate, callApi.current]);

  useLayoutEffect(() => {
    if (clickedTrRef.current) {
      showOnApiCall(clickedTrRef.current);
    }
  }, [
    rateCodeDataRef.current,
    companyDataRef.current,
    guestDataRef.current,
    segmentDrilldownData,
  ]);

  const [tblids, settblids] = useState({
    dailyData: commonService.GenerateGUID(),
    groupData: commonService.GenerateGUID(),
    reservationData: commonService.GenerateGUID(),
    drilldown: commonService.GenerateGUID(),
  });

  const chartDoughnutOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        spacing: 0,
      },
    },
    legend: {
      display: true,
      position: "right",
    },
  };

  return (
    <div>
      {/* DAY DATA */}
      <div className="weeklydaily-widget-container">
        <p>Strategy Data With Variance of Previous Week</p>
        {api !== 0 && (
          <div className="gap-1 weeklydaily-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.dailyData,
                    "Email Weekly Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.weeklyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.dailyData}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.dailyData,
                    // "Email Weekly Data"
                    `${para?.propertyCode}-Email Weekly Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <Table id={tblids.dailyData} responsive>
              <thead>
                <tr>
                  <th className="border_right"></th>
                  <th colSpan={8} className="text-center border_right">
                    On The Book
                  </th>
                  <th colSpan={8} className="text-center border_right">
                    Net Stly
                  </th>
                  <th colSpan={8} className="text-center border_right">
                    Total Ly
                  </th>
                  <th colSpan={8} className="text-center border_right">
                    Budget
                  </th>
                  <th colSpan={8} className="text-center">
                    Forecast
                  </th>
                </tr>
                <tr>
                  <th className="border_right"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="border_right text-end"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="border_right text-end"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="border_right text-end"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="border_right text-end"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="border_right text-end"></th>
                </tr>
              </thead>
              <tbody>
                {dayDataLoading === true || prevDayDataLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  dayDataArrRef.current?.map((item, index) => {
                    let currentWeek = dayData?.filter(
                      (x) =>
                        moment(x?.Dates).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    let prevWeek = prevDayData?.filter(
                      (x) =>
                        moment(x?.Dates).format("YYYY-MM-DD") ===
                        moment(prevDayDataArrRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    let occVar =
                      (currentWeek?.[0]?.OCC ? currentWeek?.[0]?.OCC : 0) -
                      (prevWeek?.[0]?.OCC ? prevWeek?.[0]?.OCC : 0);
                    let rmsVar =
                      (currentWeek?.[0]?.RMS ? currentWeek?.[0]?.RMS : 0) -
                      (prevWeek?.[0]?.RMS ? prevWeek?.[0]?.RMS : 0);
                    let adrVar =
                      (currentWeek?.[0]?.ADR ? currentWeek?.[0]?.ADR : 0) -
                      (prevWeek?.[0]?.ADR ? prevWeek?.[0]?.ADR : 0);
                    let revVar =
                      (currentWeek?.[0]?.REV ? currentWeek?.[0]?.REV : 0) -
                      (prevWeek?.[0]?.REV ? prevWeek?.[0]?.REV : 0);

                    let stlyoccVar =
                      (currentWeek?.[0]?.STLY_OCC
                        ? currentWeek?.[0]?.STLY_OCC
                        : 0) -
                      (prevWeek?.[0]?.STLY_OCC ? prevWeek?.[0]?.STLY_OCC : 0);
                    let stlyrmsVar =
                      (currentWeek?.[0]?.STLY_RMS
                        ? currentWeek?.[0]?.STLY_RMS
                        : 0) -
                      (prevWeek?.[0]?.STLY_RMS ? prevWeek?.[0]?.STLY_RMS : 0);
                    let stlyadrVar =
                      (currentWeek?.[0]?.STLY_ADR
                        ? currentWeek?.[0]?.STLY_ADR
                        : 0) -
                      (prevWeek?.[0]?.STLY_ADR ? prevWeek?.[0]?.STLY_ADR : 0);
                    let stlyrevVar =
                      (currentWeek?.[0]?.STLY_REV
                        ? currentWeek?.[0]?.STLY_REV
                        : 0) -
                      (prevWeek?.[0]?.STLY_REV ? prevWeek?.[0]?.STLY_REV : 0);

                    let lyoccVar =
                      (currentWeek?.[0]?.LY_OCC
                        ? currentWeek?.[0]?.LY_OCC
                        : 0) -
                      (prevWeek?.[0]?.LY_OCC ? prevWeek?.[0]?.LY_OCC : 0);
                    let lyrmsVar =
                      (currentWeek?.[0]?.LY_RMS
                        ? currentWeek?.[0]?.LY_RMS
                        : 0) -
                      (prevWeek?.[0]?.LY_RMS ? prevWeek?.[0]?.LY_RMS : 0);
                    let lyadrVar =
                      (currentWeek?.[0]?.LY_ADR
                        ? currentWeek?.[0]?.LY_ADR
                        : 0) -
                      (prevWeek?.[0]?.LY_ADR ? prevWeek?.[0]?.LY_ADR : 0);
                    let lyrevVar =
                      (currentWeek?.[0]?.LY_REV
                        ? currentWeek?.[0]?.LY_REV
                        : 0) -
                      (prevWeek?.[0]?.LY_REV ? prevWeek?.[0]?.LY_REV : 0);

                    let budgetoccVar =
                      (currentWeek?.[0]?.BUDGET_OCC
                        ? currentWeek?.[0]?.BUDGET_OCC
                        : 0) -
                      (prevWeek?.[0]?.BUDGET_OCC
                        ? prevWeek?.[0]?.BUDGET_OCC
                        : 0);
                    let budgetrmsVar =
                      (currentWeek?.[0]?.BUDGET_RMS
                        ? currentWeek?.[0]?.BUDGET_RMS
                        : 0) -
                      (prevWeek?.[0]?.BUDGET_RMS
                        ? prevWeek?.[0]?.BUDGET_RMS
                        : 0);
                    let budgetadrVar =
                      (currentWeek?.[0]?.BUDGET_ADR
                        ? currentWeek?.[0]?.BUDGET_ADR
                        : 0) -
                      (prevWeek?.[0]?.BUDGET_ADR
                        ? prevWeek?.[0]?.BUDGET_ADR
                        : 0);
                    let budgetrevVar =
                      (currentWeek?.[0]?.BUDGET_REV
                        ? currentWeek?.[0]?.BUDGET_REV
                        : 0) -
                      (prevWeek?.[0]?.BUDGET_REV
                        ? prevWeek?.[0]?.BUDGET_REV
                        : 0);

                    let forecastoccVar =
                      (currentWeek?.[0]?.Forecast_OCC
                        ? currentWeek?.[0]?.Forecast_OCC
                        : 0) -
                      (prevWeek?.[0]?.Forecast_OCC
                        ? prevWeek?.[0]?.Forecast_OCC
                        : 0);
                    let forecastrmsVar =
                      (currentWeek?.[0]?.Forecast_RMS
                        ? currentWeek?.[0]?.Forecast_RMS
                        : 0) -
                      (prevWeek?.[0]?.Forecast_RMS
                        ? prevWeek?.[0]?.Forecast_RMS
                        : 0);
                    let forecastadrVar =
                      (currentWeek?.[0]?.Forecast_ADR
                        ? currentWeek?.[0]?.Forecast_ADR
                        : 0) -
                      (prevWeek?.[0]?.Forecast_ADR
                        ? prevWeek?.[0]?.Forecast_ADR
                        : 0);
                    let forecastrevVar =
                      (currentWeek?.[0]?.Forecast_REV
                        ? currentWeek?.[0]?.Forecast_REV
                        : 0) -
                      (prevWeek?.[0]?.Forecast_REV
                        ? prevWeek?.[0]?.Forecast_REV
                        : 0);

                    otbOcc =
                      otbOcc +
                      (currentWeek?.[0]?.OCC
                        ? parseInt(currentWeek?.[0]?.OCC)
                        : 0);
                    otbRms =
                      otbRms +
                      (currentWeek?.[0]?.RMS
                        ? parseInt(currentWeek?.[0]?.RMS)
                        : 0);
                    otbAdr =
                      otbAdr +
                      (currentWeek?.[0]?.ADR
                        ? parseFloat(currentWeek?.[0]?.ADR)
                        : 0);
                    otbRev =
                      otbRev +
                      (currentWeek?.[0]?.REV
                        ? parseFloat(currentWeek?.[0]?.REV)
                        : 0);

                    stlyOcc =
                      stlyOcc +
                      (currentWeek?.[0]?.STLY_OCC
                        ? parseInt(currentWeek?.[0]?.STLY_OCC)
                        : 0);
                    stlyRms =
                      stlyRms +
                      (currentWeek?.[0]?.STLY_RMS
                        ? parseInt(currentWeek?.[0]?.STLY_RMS)
                        : 0);
                    stlyAdr =
                      stlyAdr +
                      (currentWeek?.[0]?.STLY_ADR
                        ? parseFloat(currentWeek?.[0]?.STLY_ADR)
                        : 0);
                    stlyRev =
                      stlyRev +
                      (currentWeek?.[0]?.STLY_REV
                        ? parseFloat(currentWeek?.[0]?.STLY_REV)
                        : 0);

                    lyOcc =
                      lyOcc +
                      (currentWeek?.[0]?.LY_OCC
                        ? parseInt(currentWeek?.[0]?.LY_OCC)
                        : 0);
                    lyRms =
                      lyRms +
                      (currentWeek?.[0]?.LY_RMS
                        ? parseInt(currentWeek?.[0]?.LY_RMS)
                        : 0);
                    lyAdr =
                      lyAdr +
                      (currentWeek?.[0]?.LY_ADR
                        ? parseFloat(currentWeek?.[0]?.LY_ADR)
                        : 0);
                    lyRev =
                      lyRev +
                      (currentWeek?.[0]?.LY_REV
                        ? parseFloat(currentWeek?.[0]?.LY_REV)
                        : 0);

                    budgetOcc =
                      budgetOcc +
                      (currentWeek?.[0]?.BUDGET_OCC
                        ? parseInt(currentWeek?.[0]?.BUDGET_OCC)
                        : 0);
                    budgetRms =
                      budgetRms +
                      (currentWeek?.[0]?.BUDGET_RMS
                        ? parseInt(currentWeek?.[0]?.BUDGET_RMS)
                        : 0);
                    budgetAdr =
                      budgetAdr +
                      (currentWeek?.[0]?.BUDGET_ADR
                        ? parseFloat(currentWeek?.[0]?.BUDGET_ADR)
                        : 0);
                    budgetRev =
                      budgetRev +
                      (currentWeek?.[0]?.BUDGET_REV
                        ? parseFloat(currentWeek?.[0]?.BUDGET_REV)
                        : 0);

                    forecastOcc =
                      forecastOcc +
                      (currentWeek?.[0]?.Forecast_OCC
                        ? parseInt(currentWeek?.[0]?.Forecast_OCC)
                        : 0);
                    forecastRms =
                      forecastRms +
                      (currentWeek?.[0]?.Forecast_RMS
                        ? parseInt(currentWeek?.[0]?.Forecast_RMS)
                        : 0);
                    forecastAdr =
                      forecastAdr +
                      (currentWeek?.[0]?.Forecast_ADR
                        ? parseFloat(currentWeek?.[0]?.Forecast_ADR)
                        : 0);
                    forecastRev =
                      forecastRev +
                      (currentWeek?.[0]?.Forecast_REV
                        ? parseFloat(currentWeek?.[0]?.Forecast_REV)
                        : 0);

                    return (
                      <>
                        <tr key={item} className="shadowOnHover">
                          <td className="border_right">
                            {commonService.getDateInFormat(item)}
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.OCC ? currentWeek?.[0]?.OCC : 0}%
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              occVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {occVar}%
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.RMS
                              ? commonService.formateRoom(currentWeek?.[0]?.RMS)
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              rmsVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateRoom(rmsVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.ADR
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              adrVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(adrVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.REV
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              revVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(revVar)}
                          </td>

                          <td className="text-center">
                            {currentWeek?.[0]?.STLY_OCC
                              ? currentWeek?.[0]?.STLY_OCC
                              : 0}
                            %
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              stlyoccVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {stlyoccVar}%
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.STLY_RMS
                              ? commonService.formateRoom(
                                  currentWeek?.[0]?.STLY_RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              stlyrmsVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateRoom(stlyrmsVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.STLY_ADR
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.STLY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              stlyadrVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(stlyadrVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.STLY_REV
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.STLY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              stlyrevVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(stlyrevVar)}
                          </td>

                          <td className="text-center">
                            {currentWeek?.[0]?.LY_OCC
                              ? currentWeek?.[0]?.LY_OCC
                              : 0}
                            %
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              lyoccVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {lyoccVar}%
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.LY_RMS
                              ? commonService.formateRoom(
                                  currentWeek?.[0]?.LY_RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              lyrmsVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateRoom(lyrmsVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.LY_ADR
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.LY_ADR
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              lyadrVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(lyadrVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.LY_REV
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.LY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              lyrevVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(lyrevVar)}
                          </td>

                          <td className="text-center">
                            {currentWeek?.[0]?.BUDGET_OCC
                              ? currentWeek?.[0]?.BUDGET_OCC
                              : 0}
                            %
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              budgetoccVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {budgetoccVar}%
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.BUDGET_RMS
                              ? commonService.formateRoom(
                                  Math.round(currentWeek?.[0]?.BUDGET_RMS)
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              budgetrmsVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateRoom(budgetrmsVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.BUDGET_ADR
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.BUDGET_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              budgetadrVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(budgetadrVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.BUDGET_REV
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.BUDGET_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              budgetrevVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(budgetrevVar)}
                          </td>

                          <td className="text-center">
                            {currentWeek?.[0]?.Forecast_OCC
                              ? currentWeek?.[0]?.Forecast_OCC
                              : 0}
                            %
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              forecastoccVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {forecastoccVar}%
                          </td>
                          <td className="text-center">
                            {currentWeek?.[0]?.Forecast_RMS
                              ? commonService.formateRoom(
                                  currentWeek?.[0]?.Forecast_RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              forecastrmsVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateRoom(forecastrmsVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.Forecast_ADR
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.Forecast_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              forecastadrVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(forecastadrVar)}
                          </td>
                          <td className="text-end">
                            {currentWeek?.[0]?.Forecast_REV
                              ? commonService.formateAmount(
                                  currentWeek?.[0]?.Forecast_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              forecastrevVar > 0 ? "greenText" : "redText"
                            }`}
                          >
                            {commonService.formateAmount(forecastrevVar)}
                          </td>
                        </tr>
                        {index === dayDataArrRef.current?.length - 1 && (
                          <tr>
                            <td className="boldTd border_right">Total</td>
                            <td className="boldTd">
                              {otbOcc > 0 ? parseInt(otbOcc / 7) : 0}%
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd">
                              {commonService.formateRoom(otbRms)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {otbAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(otbAdr / 7)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(otbRev)}
                            </td>
                            <td className="boldTd border_right"></td>

                            <td className="boldTd">
                              {stlyOcc > 0 ? parseInt(stlyOcc / 7) : 0}%
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd">
                              {commonService.formateRoom(stlyRms)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {stlyAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(stlyAdr / 7)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(stlyRev)}
                            </td>
                            <td className="boldTd border_right"></td>

                            <td className="boldTd">
                              {lyOcc > 0 ? parseInt(lyOcc / 7) : 0}%
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd">
                              {commonService.formateRoom(lyRms)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {lyAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(lyAdr / 7)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(lyRev)}
                            </td>
                            <td className="boldTd border_right"></td>

                            <td className="boldTd">
                              {budgetOcc > 0 ? parseInt(budgetOcc / 7) : 0}%
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd">
                              {commonService.formateRoom(budgetRms)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {budgetAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(budgetAdr / 7)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(budgetRev)}
                            </td>
                            <td className="boldTd border_right"></td>

                            <td className="boldTd">
                              {forecastOcc > 0 ? parseInt(forecastOcc / 7) : 0}%
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd">
                              {commonService.formateRoom(forecastRms)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {forecastAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(forecastAdr / 7)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(forecastRev)}
                            </td>
                            <td className="boldTd"></td>
                          </tr>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* TRANSIENT/GROUP DATA */}
      <div className="mt-3 weeklygroup-widget-container">
        <p>Transient/Group With Variance (LY)</p>
        {api !== 0 && (
          <div className="gap-1 weeklygroup-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.groupData,
                    "Email Weekly Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.weeklyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.groupData}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.groupData,
                    // "Email Weekly Data"
                    `${para?.propertyCode}-Email Weekly Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <Table id={tblids.groupData} responsive>
              <thead>
                <tr>
                  <th className="border_right"></th>
                  <th className="text-center border_right" colSpan={6}>
                    Transient
                  </th>
                  <th className="text-center border_right" colSpan={6}>
                    Group
                  </th>
                </tr>
                <tr>
                  <th className="border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end"></th>
                </tr>
              </thead>
              <tbody>
                {groupLoading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : groupData ? (
                  <>
                    <tr className="shadowOnHover">
                      <td className="border_right">
                        {groupDataArrRef.current[0]}
                      </td>
                      <td className="text-center">
                        {groupData?.[0]?.Previous_Week_Transient_RMS
                          ? commonService.formateRoom(
                              groupData?.[0]?.Previous_Week_Transient_RMS
                            )
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.prevtRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateRoom(
                          varianceRef.current?.prevtRmsVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Previous_Week_Transient_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Previous_Week_Transient_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.prevtAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.prevtAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Previous_Week_Transient_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Previous_Week_Transient_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size border_right ${
                          varianceRef.current?.prevtRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.prevtRevVar
                        )}
                      </td>

                      <td className="text-center">
                        {groupData?.[0]?.Previous_Week_Group_RMS
                          ? commonService.formateRoom(
                              groupData?.[0]?.Previous_Week_Group_RMS
                            )
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.prevgRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateRoom(
                          varianceRef.current?.prevgRmsVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Previous_Week_Group_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Previous_Week_Group_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.prevgAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.prevgAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Previous_Week_Group_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Previous_Week_Group_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.prevgRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.prevgRevVar
                        )}
                      </td>
                    </tr>
                    <tr className="shadowOnHover">
                      <td className="border_right">
                        {groupDataArrRef.current[1]}
                      </td>
                      <td className="text-center">
                        {groupData?.[0]?.Transient_RMS
                          ? groupData?.[0]?.Transient_RMS
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.tRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {varianceRef.current?.tRmsVar}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Transient_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Transient_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.tAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.tAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Transient_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Transient_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size border_right ${
                          varianceRef.current?.tRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.tRevVar
                        )}
                      </td>

                      <td className="text-center">
                        {groupData?.[0]?.Group_RMS
                          ? groupData?.[0]?.Group_RMS
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.gRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {varianceRef.current?.gRmsVar}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Group_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Group_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.gAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.gAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Group_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Group_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.gRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.gRevVar
                        )}
                      </td>
                    </tr>
                    <tr className="shadowOnHover">
                      <td className="border_right">
                        {groupDataArrRef.current[2]}
                      </td>
                      <td className="text-center">
                        {groupData?.[0]?.Next_Week_Transient_RMS
                          ? groupData?.[0]?.Next_Week_Transient_RMS
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.nexttRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {varianceRef.current?.nexttRmsVar}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Next_Week_Transient_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Next_Week_Transient_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.nexttAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.nexttAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Next_Week_Transient_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Next_Week_Transient_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size border_right ${
                          varianceRef.current?.nexttRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.nexttRevVar
                        )}
                      </td>

                      <td className="text-center">
                        {groupData?.[0]?.Next_Week_Group_RMS
                          ? groupData?.[0]?.Next_Week_Group_RMS
                          : 0}
                      </td>
                      <td
                        className={`text-center variance-font-size ${
                          varianceRef.current?.nextgRmsVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {varianceRef.current?.nextgRmsVar}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Next_Week_Group_ADR
                          ? commonService.formateAmount(
                              groupData?.[0]?.Next_Week_Group_ADR
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.nextgAdrVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.nextgAdrVar
                        )}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.Next_Week_Group_REV
                          ? commonService.formateAmount(
                              groupData?.[0]?.Next_Week_Group_REV
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td
                        className={`text-end variance-font-size ${
                          varianceRef.current?.nextgRevVar > 0
                            ? "greenText"
                            : "redText"
                        }`}
                      >
                        {commonService.formateAmount(
                          varianceRef.current?.nextgRevVar
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* DRILLDOWN DATA */}
      <div className="mt-3 weeklydrilldown-widget-container">
        <p>{`Segment Drildown (${commonService.getDateInFormat(
          firstday
        )} - ${commonService.getDateInFormat(lastday)})`}</p>
        {api !== 0 && (
          <div className="gap-1 weeklydrilldown-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.drilldown,
                    "Email Weekly Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.weeklyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.drilldown}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.drilldown,
                    // "Email Weekly Data"
                    `${para?.propertyCode}-Email Weekly Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table id={tblids.drilldown} responsive>
                <thead>
                  <tr>
                    <th className="border_right text-center"></th>
                    <th colSpan={4} className="border_right text-center">
                      Current Year
                      {/* {`${commonService.getDateInFormat(
                        leftFilterRef.current?.stayDateStart
                      )} - ${commonService.getDateInFormat(
                        leftFilterRef.current?.stayDateEnd
                      )} as of ${commonService.getDateInFormat(
                        leftFilterRef.current?.asOfDate
                      )}`} */}
                    </th>
                    <th colSpan={4} className="border_right text-center">
                      Last Year
                      {/* {`${commonService.getDateInFormat(
                        rightFilterRef.current?.stayDateStart
                      )} - ${commonService.getDateInFormat(
                        rightFilterRef.current?.stayDateEnd
                      )} as of ${commonService.getDateInFormat(
                        rightFilterRef.current?.asOfDate
                      )}`} */}
                    </th>
                    <th colSpan={4} className="text-center">
                      Variance
                    </th>
                  </tr>
                  <tr>
                    <th className="border_right"></th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="text-center">Rev Contribution</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    segmentDrilldownData &&
                    segmentDrilldownData?.map((item, index) => {
                      let msLeftRevContri = 0,
                        msRightRevContri = 0;
                      if (item?.left_REV > 0 && totalRef.current?.leftRev > 0) {
                        msLeftRevContri = (
                          (parseInt(item?.left_REV) * 100) /
                          totalRef.current?.leftRev
                        )?.toFixed(1);
                      }

                      if (
                        item?.right_REV > 0 &&
                        totalRef.current?.rightRev > 0
                      ) {
                        msRightRevContri = (
                          (parseInt(item?.right_REV) * 100) /
                          totalRef.current?.rightRev
                        )?.toFixed(1);
                      }
                      return (
                        <>
                          <tr id={item?.guid} className="shadowOnHover">
                            <td className="border_right">
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  let findSelectedMarketSegment =
                                    calledMarketSegmentRef.current?.filter(
                                      (x) => x === item?.name
                                    );

                                  if (findSelectedMarketSegment?.length > 0) {
                                    const classList =
                                      document.getElementsByClassName(
                                        item?.guid
                                      );
                                    if (
                                      classList?.[0]?.classList?.contains(
                                        "nestedTableRowNone"
                                      )
                                    ) {
                                      handleShowHideRow(item?.guid);
                                    } else {
                                      handleShowHideRow(item?.guid, item);
                                    }
                                  } else {
                                    if (!msLoading) {
                                      selectedMarketSegmentRef.current =
                                        item?.name;
                                      clickedTrRef.current = [item?.guid];
                                      getRateCodeData();
                                    }
                                  }
                                }}
                              >
                                <p>{item?.name}</p>
                                <img src={downArrow} />

                                {msLoading &&
                                  selectedMarketSegmentRef.current ===
                                    item?.name && (
                                    <div
                                      class="spinner-border text-primary ms-2"
                                      role="status"
                                      style={{
                                        height: "13px",
                                        width: "13px",
                                        color: "#3565FC",
                                      }}
                                    >
                                      <span class="sr-only"></span>
                                    </div>
                                  )}
                              </div>
                            </td>
                            <td className="text-center">
                              {item?.left_RMS
                                ? commonService.formateRoom(item?.left_RMS)
                                : 0}
                            </td>
                            <td className="text-end">
                              {item?.left_ADR
                                ? `${commonService.formateAmount(
                                    item?.left_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {item?.left_REV
                                ? `${commonService.formateAmount(
                                    item?.left_REV
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msLeftRevContri}%
                            </td>
                            <td className="text-center">
                              {item?.right_RMS
                                ? commonService.formateRoom(item?.right_RMS)
                                : 0}
                            </td>
                            <td className="text-end">
                              {item?.right_ADR
                                ? `${commonService.formateAmount(
                                    item?.right_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {item?.right_REV
                                ? `${commonService.formateAmount(
                                    item?.right_REV
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msRightRevContri}%
                            </td>
                            <td
                              className={`text-center ${
                                item?.varientRms < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateRoom(item?.varientRms)}
                            </td>
                            <td
                              className={`text-end ${
                                item?.varientAdr < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateAmount(item?.varientAdr)}
                            </td>
                            <td
                              className={`text-end ${
                                item?.varientRev < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateAmount(item?.varientRev)}
                            </td>
                            <td
                              className={`text-center ${
                                (msLeftRevContri - msRightRevContri)?.toFixed(
                                  1
                                ) < 0
                                  ? "redText"
                                  : ""
                              }`}
                            >
                              {(msLeftRevContri - msRightRevContri)?.toFixed(1)}
                              %
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => {
                              let rateCodeLeftRevContri = 0,
                                rateCodeRightRevContri = 0;
                              if (
                                rateCodeItem?.left_REV > 0 &&
                                totalRef.current?.leftRev > 0
                              ) {
                                rateCodeLeftRevContri = (
                                  (parseInt(rateCodeItem?.left_REV) * 100) /
                                  totalRef.current?.leftRev
                                )?.toFixed(1);
                              }

                              if (
                                rateCodeItem?.right_REV > 0 &&
                                totalRef.current?.rightRev > 0
                              ) {
                                rateCodeRightRevContri = (
                                  (parseInt(rateCodeItem?.right_REV) * 100) /
                                  totalRef.current?.rightRev
                                )?.toFixed(1);
                              }
                              return (
                                <>
                                  <tr
                                    className={`${item?.guid} nestedTableRowNone shadowOnHover`}
                                    id={rateCodeItem?.guid}
                                  >
                                    <td className="border_right">
                                      <div
                                        style={{ paddingLeft: "10px" }}
                                        className="d-flex gap-2"
                                        onClick={() => {
                                          if (!rateCodeLoading) {
                                            selectedMarketSegmentRef.current =
                                              item?.name;
                                            selectedRateCodeRef.current =
                                              rateCodeItem?.name;
                                          }
                                          let findSelectedRateCode =
                                            calledRateCodeRef.current?.filter(
                                              (x) =>
                                                x?.ms ===
                                                  selectedMarketSegmentRef.current &&
                                                x?.rc ===
                                                  selectedRateCodeRef.current
                                            );

                                          if (
                                            findSelectedRateCode?.length > 0
                                          ) {
                                            const classList =
                                              document.getElementsByClassName(
                                                rateCodeItem?.guid
                                              );

                                            if (
                                              classList?.[0]?.classList?.contains(
                                                "nestedTableRowNone"
                                              )
                                            ) {
                                              handleShowHideRow(
                                                rateCodeItem?.guid
                                              );
                                            } else {
                                              handleShowHideRow(
                                                rateCodeItem?.guid,
                                                rateCodeItem
                                              );
                                            }
                                          } else {
                                            if (!rateCodeLoading) {
                                              clickedTrRef.current = [
                                                ...clickedTrRef.current,
                                                rateCodeItem?.guid,
                                              ];
                                              getCompanyData();
                                            }
                                          }
                                        }}
                                      >
                                        <p>{rateCodeItem?.name}</p>
                                        <img src={downArrow} />
                                        {rateCodeLoading &&
                                          selectedMarketSegmentRef.current ===
                                            item?.name &&
                                          selectedRateCodeRef.current ===
                                            rateCodeItem?.name && (
                                            <div
                                              class="spinner-border text-primary ms-2"
                                              role="status"
                                              style={{
                                                height: "13px",
                                                width: "13px",
                                                color: "#3565FC",
                                              }}
                                            >
                                              <span class="sr-only"></span>
                                            </div>
                                          )}
                                      </div>
                                    </td>

                                    <td className="text-center">
                                      {rateCodeItem?.left_RMS
                                        ? commonService.formateRoom(
                                            rateCodeItem?.left_RMS
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_ADR
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_ADR
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_REV
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_REV
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeLeftRevContri}%
                                    </td>
                                    <td className="text-center">
                                      {rateCodeItem?.right_RMS
                                        ? commonService.formateRoom(
                                            rateCodeItem?.right_RMS
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.right_ADR
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.right_ADR
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.right_REV
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.right_REV
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeRightRevContri}%
                                    </td>
                                    <td
                                      className={`text-center ${
                                        rateCodeItem?.varientRms < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateRoom(
                                        rateCodeItem?.varientRms
                                      )}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeItem?.varientAdr < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateAmount(
                                        rateCodeItem?.varientAdr
                                      )}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeItem?.varientRev < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateAmount(
                                        rateCodeItem?.varientRev
                                      )}
                                    </td>
                                    <td
                                      className={`text-center ${
                                        (
                                          rateCodeLeftRevContri -
                                          rateCodeRightRevContri
                                        )?.toFixed(1) < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {(
                                        rateCodeLeftRevContri -
                                        rateCodeRightRevContri
                                      )?.toFixed(1)}
                                      %
                                    </td>
                                  </tr>
                                  {Array.isArray(rateCodeItem?.items) &&
                                    rateCodeItem?.items?.length > 0 &&
                                    rateCodeItem?.items?.map(
                                      (companyItem, companyIndex) => {
                                        let companyItemLeftRevContri = 0,
                                          companyItemRightRevContri = 0;
                                        if (
                                          companyItem?.left_REV > 0 &&
                                          totalRef.current?.leftRev > 0
                                        ) {
                                          companyItemLeftRevContri = (
                                            (parseInt(companyItem?.left_REV) *
                                              100) /
                                            totalRef.current?.leftRev
                                          )?.toFixed(1);
                                        }

                                        if (
                                          companyItem?.right_REV > 0 &&
                                          totalRef.current?.rightRev > 0
                                        ) {
                                          companyItemRightRevContri = (
                                            (parseInt(companyItem?.right_REV) *
                                              100) /
                                            totalRef.current?.rightRev
                                          )?.toFixed(1);
                                        }
                                        return (
                                          <>
                                            <tr
                                              className={`${rateCodeItem?.guid} nestedTableRowNone shadowOnHover`}
                                              id={companyItem?.guid}
                                            >
                                              <td className="border_right">
                                                <div
                                                  style={{
                                                    paddingLeft: "20px",
                                                  }}
                                                  className="d-flex gap-2"
                                                  onClick={() => {
                                                    if (!companyLoading) {
                                                      selectedMarketSegmentRef.current =
                                                        item?.name;
                                                      selectedRateCodeRef.current =
                                                        rateCodeItem?.name;
                                                      selectedCompanyRef.current =
                                                        companyItem?.name;
                                                    }
                                                    let findSelectedCompany =
                                                      calledCompanyRef.current?.filter(
                                                        (x) =>
                                                          x?.ms ===
                                                            selectedMarketSegmentRef.current &&
                                                          x?.rc ===
                                                            selectedRateCodeRef.current &&
                                                          x?.company ===
                                                            selectedCompanyRef.current
                                                      );

                                                    if (
                                                      findSelectedCompany?.length >
                                                      0
                                                    ) {
                                                      const classList =
                                                        document.getElementsByClassName(
                                                          companyItem?.guid
                                                        );

                                                      if (
                                                        classList?.[0]?.classList?.contains(
                                                          "nestedTableRowNone"
                                                        )
                                                      ) {
                                                        handleShowHideRow(
                                                          companyItem?.guid
                                                        );
                                                      } else {
                                                        handleShowHideRow(
                                                          companyItem?.guid,
                                                          companyItem
                                                        );
                                                      }
                                                    } else {
                                                      if (!companyLoading) {
                                                        clickedTrRef.current = [
                                                          ...clickedTrRef.current,
                                                          companyItem?.guid,
                                                        ];
                                                        getGuestData();
                                                      }
                                                    }
                                                  }}
                                                >
                                                  <p>{companyItem?.name}</p>
                                                  <img src={downArrow} />
                                                  {companyLoading &&
                                                    selectedMarketSegmentRef.current ===
                                                      item?.name &&
                                                    selectedRateCodeRef.current ===
                                                      rateCodeItem?.name &&
                                                    selectedCompanyRef.current ===
                                                      companyItem?.name && (
                                                      <div
                                                        class="spinner-border text-primary ms-2"
                                                        role="status"
                                                        style={{
                                                          height: "13px",
                                                          width: "13px",
                                                          color: "#3565FC",
                                                        }}
                                                      >
                                                        <span class="sr-only"></span>
                                                      </div>
                                                    )}
                                                </div>
                                              </td>

                                              <td className="text-center">
                                                {companyItem?.left_RMS
                                                  ? commonService.formateRoom(
                                                      companyItem?.left_RMS
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_ADR
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_ADR
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_REV
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_REV
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemLeftRevContri}%
                                              </td>
                                              <td className="text-center">
                                                {companyItem?.right_RMS
                                                  ? commonService.formateRoom(
                                                      companyItem?.right_RMS
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.right_ADR
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.right_ADR
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.right_REV
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.right_REV
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemRightRevContri}%
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  companyItem?.varientRms < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateRoom(
                                                  companyItem?.varientRms
                                                )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyItem?.varientAdr < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateAmount(
                                                  companyItem?.varientAdr
                                                )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyItem?.varientRev < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateAmount(
                                                  companyItem?.varientRev
                                                )}
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  (
                                                    companyItemLeftRevContri -
                                                    companyItemRightRevContri
                                                  )?.toFixed(1) < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {(
                                                  companyItemLeftRevContri -
                                                  companyItemRightRevContri
                                                )?.toFixed(1)}
                                                %
                                              </td>
                                            </tr>
                                            {Array.isArray(
                                              companyItem?.items
                                            ) &&
                                              companyItem?.items?.length > 0 &&
                                              companyItem?.items?.map(
                                                (guestItem, guestIndex) => {
                                                  let guestLeftRevContri = 0,
                                                    guestRightRevContri = 0;
                                                  if (
                                                    guestItem?.left_REV > 0 &&
                                                    totalRef.current?.leftRev >
                                                      0
                                                  ) {
                                                    guestLeftRevContri = (
                                                      (parseInt(
                                                        guestItem?.left_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.leftRev
                                                    )?.toFixed(1);
                                                  }

                                                  if (
                                                    guestItem?.right_REV > 0 &&
                                                    totalRef.current?.rightRev >
                                                      0
                                                  ) {
                                                    guestRightRevContri = (
                                                      (parseInt(
                                                        guestItem?.right_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.rightRev
                                                    )?.toFixed(1);
                                                  }
                                                  return (
                                                    <tr
                                                      className={`${companyItem?.guid} nestedTableRowNone shadowOnHover`}
                                                      onClick={(e) => {
                                                        checkActiveRow(e);
                                                      }}
                                                    >
                                                      <td className="border_right">
                                                        <div
                                                          style={{
                                                            paddingLeft: "30px",
                                                          }}
                                                          className="d-flex gap-2"
                                                        >
                                                          <p>
                                                            {guestItem?.name}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td className="text-center">
                                                        {guestItem?.left_RMS
                                                          ? commonService.formateRoom(
                                                              guestItem?.left_RMS
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_ADR
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_ADR
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_REV
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_REV
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestLeftRevContri}%
                                                      </td>
                                                      <td className="text-center">
                                                        {guestItem?.right_RMS
                                                          ? commonService.formateRoom(
                                                              guestItem?.right_RMS
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.right_ADR
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.right_ADR
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.right_REV
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.right_REV
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestRightRevContri}%
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          guestItem?.varientRms <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateRoom(
                                                          guestItem?.varientRms
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestItem?.varientAdr <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateAmount(
                                                          guestItem?.varientAdr
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestItem?.varientRev <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateAmount(
                                                          guestItem?.varientRev
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          (
                                                            guestLeftRevContri -
                                                            guestRightRevContri
                                                          )?.toFixed(1) < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {(
                                                          guestLeftRevContri -
                                                          guestRightRevContri
                                                        )?.toFixed(1)}
                                                        %
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </>
                              );
                            })}
                          {index === segmentDrilldownData?.length - 1 && (
                            <tr>
                              <td className="border_right boldTd">Totals</td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.leftRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {totalRef.current.leftRoom > 0 &&
                                totalRef.current.leftRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.leftRev /
                                          totalRef.current.leftRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.leftRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {totalRef.current.leftRevContri}%
                              </td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.rightRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {totalRef.current.rightRoom > 0 &&
                                totalRef.current.rightRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.rightRev /
                                          totalRef.current.rightRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.rightRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {totalRef.current.rightRevContri}%
                              </td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.leftRoom -
                                    totalRef.current.rightRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {propertyDetailFromRTK?.currency_term
                                  ? propertyDetailFromRTK?.currency_term
                                  : "$"}
                                {(totalRef.current.leftRoom > 0 &&
                                totalRef.current.leftRev > 0
                                  ? Math.round(
                                      totalRef.current.leftRev /
                                        totalRef.current.leftRoom
                                    )
                                  : 0) -
                                  (totalRef.current.rightRoom > 0 &&
                                  totalRef.current.rightRev > 0
                                    ? Math.round(
                                        totalRef.current.rightRev /
                                          totalRef.current.rightRoom
                                      )
                                    : 0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.leftRev -
                                    totalRef.current.rightRev
                                )}
                              </td>
                              <td className="boldTd"></td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* MARKET SEGMENT CHART */}
      <div className="mt-3">
        <p>Market Mix</p>
        <div>
          {chartData && (
            <DoughnutChart data={chartData} options={chartDoughnutOptions} />
          )}
        </div>
      </div>

      {/* RESERVATION DATA */}
      <div className="mt-3 weeklyreservation-widget-container">
        <p>{`Reservation Activity (${commonService.getDateInFormat(
          firstday
        )} - ${commonService.getDateInFormat(lastday)})`}</p>
        {api !== 0 && (
          <div className="gap-1 weeklyreservation-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.reservationData,
                    "Email Weekly Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.weeklyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.reservationData}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.reservationData,
                    // "Email Weekly Data"
                    `${para?.propertyCode}-Email Weekly Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <div className="table-responsive tblDynamicPickupSticky">
              <Table id={tblids.reservationData}>
                <thead className="tbl-header-sticky">
                  <tr>
                    <th className="text-center" colSpan={15}>
                      Reservation Activity
                      <span style={{ marginLeft: "10px", fontSize: "7px" }}>
                        {`${
                          ReservationRateTitle
                            ? `(Reservation Rate < ${ReservationRateTitle.min} % or > ${ReservationRateTitle.max} % by Rate of the Day (ADR))`
                            : ""
                        }`}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>RateCode</th>
                    <th>Company</th>
                    <th>GroupName</th>
                    <th>GuestName</th>
                    <th>Account</th>
                    <th>ArrivalDate</th>
                    <th className="width150">RateCodeDescript</th>
                    <th className="text-center">LOS</th>
                    <th>Status</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Rate</th>
                    <th className="text-center">RoomType</th>
                    <th>User</th>
                    <th>CombinedChannel</th>
                    <th>BookingDate</th>
                  </tr>
                </thead>
                <tbody>
                  {reservationLoading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : reservationData ? (
                    reservationData?.map((item, index) => {
                      let status = "";
                      if (item?.Status === "R") {
                        status = "reserved";
                      } else if (item?.Status === "I") {
                        status = "in house";
                      } else if (item?.Status === "O") {
                        status = "checked out";
                      } else if (item?.Status === "C") {
                        status = "cancelled";
                      } else if (item?.Status === "N") {
                        status = "no show";
                      }
                      return (
                        <tr className="shadowOnHover">
                          <td>{item?.RateCode}</td>
                          <td>{item?.Company}</td>
                          <td>{item?.GroupName}</td>
                          <td>{item?.GuestName}</td>
                          <td>{item?.Account}</td>
                          <td>
                            {commonService.getDateInFormat(item?.ArrivalDate)}
                          </td>
                          <td>{item?.RateCodeDescript}</td>
                          <td className="text-center">{item?.LOS}</td>
                          <td>{status}</td>
                          <td className="text-end">
                            {commonService.formateAmount(
                              Math.round(item?.ADR ? item?.ADR : 0)
                            )}
                          </td>
                          <td className="text-end">
                            {item?.RateStatus.status == 1 ? (
                              <img
                                src={lowPrio}
                                title={item?.RateStatus.diff.toFixed(2) + "%"}
                              />
                            ) : item?.RateStatus.status == 2 ? (
                              <img
                                src={highPrio}
                                title={item?.RateStatus.diff.toFixed(2) + "%"}
                              />
                            ) : null}
                            {commonService.formateAmount(
                              Math.round(item?.Rate ? item?.Rate : 0)
                            )}
                          </td>
                          <td className="text-center">{item?.RoomType}</td>
                          <td>{item?.User}</td>
                          <td>{item?.CombinedChannel}</td>
                          <td>{item?.BookingDate}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={15} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"WeeklyData"} />}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default WeeklyData;
