import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Table, Button, Form, Col, Row } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { useParams } from "react-router-dom";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import moment from "moment";

const SeasonalityAnalysis = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const [loading, setLoading] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);
  const yearsArr = commonService.getPastYearForDropDown(4);
  yearsArr.unshift(new Date().getFullYear());
  yearsArr.unshift("Avg Monthly Perfomance");
  const [selectedType, setSelectedType] = useState("rev");
  const widgetDetailRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  let postitiveCount = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };

  let negativeCount = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };

  const getAnalysisData = async () => {
    setLoading(true);
    let analysisResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.ANNUAL_SUMMARY.SEASONALITY_ANALYSIS,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, analysisResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      setAnalysisData(dataFromApi);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setAnalysisData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getAnalysisData();
      }
    }
  }, [para?.asOfDate]);

  const findBackgroundColor = (avgTotal, singleData) => {
    let bgColor = "";
    let percentage = Math.round((singleData * 100) / avgTotal);

    if (percentage < -30) {
      bgColor = "#000000";
    } else if (percentage >= -30 && percentage < -15) {
      bgColor = "#FF0000";
    } else if (percentage >= -15 && percentage < -10) {
      bgColor = "#F1A983";
    } else if (percentage >= -10 && percentage < 0) {
      bgColor = "#F7C7AC";
    } else if (percentage >= 0 && percentage < 5) {
      bgColor = "#FBE2D5";
    } else if (percentage >= 5 && percentage < 15) {
      bgColor = "#FFFC9D";
    } else if (percentage >= 15 && percentage < 25) {
      bgColor = "#8ED973";
    } else {
      bgColor = "#3C7D22";
    }

    return { bgColor, percentage };
  };

  const [tblids, settblids] = useState({
    seasonAnalysis: commonService.GenerateGUID(),
  });

  return (
    <div>
      <Col xl={12}>
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <div
            className="d-flex gap-2 crm-dashboard-bot-top"
            style={{ marginBottom: 0 }}
          >
            <div className="form-groups form-groups-radio-btn">
              <div className="form-groups-radio">
                <div className="form-radio">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radioGroup2"
                    id="Revenue"
                    checked={selectedType === "rev" ? true : false}
                    onClick={() => {
                      setSelectedType("rev");
                    }}
                  />
                  <Form.Check.Label htmlFor="Revenue" className="label-radio">
                    Revenue
                  </Form.Check.Label>
                </div>
                <div className="form-radio">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radioGroup2"
                    id="Occ"
                    checked={selectedType === "occ" ? true : false}
                    onClick={() => {
                      setSelectedType("occ");
                    }}
                  />
                  <Form.Check.Label htmlFor="Occ" className="label-radio">
                    Occ
                  </Form.Check.Label>
                </div>
                <div className="form-radio">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radioGroup2"
                    id="ADR"
                    checked={selectedType === "adr" ? true : false}
                    onClick={() => {
                      setSelectedType("adr");
                    }}
                  />
                  <Form.Check.Label htmlFor="ADR" className="label-radio">
                    ADR
                  </Form.Check.Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <div className="widget-container">
        {api !== 0 && (
          <div className="gap-1 widget-container-menu">
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.seasonAnalysis,
                    "Seasonality Analysis",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.seasonalityAnalysis,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.seasonAnalysis}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.seasonAnalysis,
                    `${para?.propertyCode}-Seasonality Analysis-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable mt-2">
          <div className="stripped__table">
            <Table id={tblids?.seasonAnalysis} responsive>
              <thead>
                <tr>
                  <th className="border_right">Year</th>
                  {month?.map((item, index) => {
                    return (
                      <th
                        className={`border_right ${
                          selectedType === "adr" || selectedType === "rev"
                            ? "text-end"
                            : ""
                        }`}
                      >
                        {item}
                      </th>
                    );
                  })}
                  <th
                    className={`${
                      selectedType === "adr" || selectedType === "rev"
                        ? "text-end"
                        : ""
                    }`}
                  >
                    Yearly Avg
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : analysisData ? (
                  yearsArr?.map((item, yearIndex) => {
                    let totalForYearlyAvg = 0;
                    return (
                      <>
                        <tr className="">
                          <td className="border_right">{item}</td>
                          {month?.map((monthItem) => {
                            let currentMonthData = analysisData?.filter(
                              (x) => x?.month?.trim() === monthItem
                            );

                            let yearWiseCurrentMonth = currentMonthData?.filter(
                              (x) => x?.year === item
                            );

                            let avgTotal = 0;

                            currentMonthData?.forEach((currentMonthItem) => {
                              avgTotal =
                                avgTotal +
                                (currentMonthItem?.[selectedType]
                                  ? currentMonthItem?.[selectedType]
                                  : 0);
                            });
                            let avgOfSelectedFilter = Math.round(
                              avgTotal / currentMonthData?.length
                            );
                            let bgColor = "";
                            if (yearIndex !== 0) {
                              if (yearWiseCurrentMonth?.[0]?.[selectedType]) {
                                bgColor = findBackgroundColor(
                                  // avgTotal,
                                  avgOfSelectedFilter,
                                  yearWiseCurrentMonth?.[0]?.[selectedType] -
                                    avgOfSelectedFilter
                                );
                              }
                              totalForYearlyAvg =
                                totalForYearlyAvg +
                                (yearWiseCurrentMonth?.[0]?.[selectedType]
                                  ? yearWiseCurrentMonth?.[0]?.[selectedType]
                                  : 0);
                            }

                            if (yearIndex !== 0) {
                              if (
                                avgOfSelectedFilter <
                                yearWiseCurrentMonth?.[0]?.[selectedType]
                              ) {
                                negativeCount[monthItem] =
                                  negativeCount[monthItem] + 1;
                              } else {
                                postitiveCount[monthItem] =
                                  postitiveCount[monthItem] + 1;
                              }
                            }
                            let textColor = "";
                            if (
                              bgColor?.bgColor === "#000000" ||
                              bgColor?.bgColor === "#FF0000" ||
                              bgColor?.bgColor === "#3C7D22"
                            ) {
                              textColor = "#ffffff";
                            }
                            return (
                              <>
                                <td
                                  className={`border_right ${
                                    selectedType === "rev" ||
                                    selectedType === "adr"
                                      ? "text-end"
                                      : ""
                                  }`}
                                  style={{
                                    background: yearWiseCurrentMonth?.[0]?.[
                                      selectedType
                                    ]
                                      ? bgColor?.bgColor
                                      : "",
                                    color: yearWiseCurrentMonth?.[0]?.[
                                      selectedType
                                    ]
                                      ? textColor
                                      : "",
                                  }}
                                >
                                  <span>
                                    {yearIndex === 0
                                      ? selectedType === "rev" ||
                                        selectedType === "adr"
                                        ? commonService.formateAmount(
                                            avgOfSelectedFilter
                                          )
                                        : avgOfSelectedFilter
                                        ? `${avgOfSelectedFilter}%`
                                        : ""
                                      : selectedType === "rev" ||
                                        selectedType === "adr"
                                      ? `${commonService.formateAmount(
                                          yearWiseCurrentMonth?.[0]?.[
                                            selectedType
                                          ]
                                        )}`
                                      : yearWiseCurrentMonth?.[0]?.[
                                          selectedType
                                        ]
                                      ? `${yearWiseCurrentMonth?.[0]?.[selectedType]}%`
                                      : ""}
                                  </span>
                                  {yearIndex !== 0 && (
                                    <span className="seasonVarianceSpan">
                                      {bgColor?.percentage
                                        ? `(${bgColor?.percentage}%)`
                                        : ``}
                                    </span>
                                  )}
                                </td>
                              </>
                            );
                          })}
                          <td
                            className={`${
                              selectedType === "rev" || selectedType === "adr"
                                ? "text-end"
                                : ""
                            }`}
                          >
                            {yearIndex === 0
                              ? ""
                              : selectedType === "rev" || selectedType === "adr"
                              ? commonService.formateAmount(
                                  Math.round(totalForYearlyAvg / 12)
                                )
                              : `${Math.round(totalForYearlyAvg / 12)}%`}
                          </td>
                        </tr>
                        {yearsArr?.length - 1 === yearIndex && (
                          <>
                            <tr className="">
                              <td className="border_right boldTd">
                                Positive Count%
                              </td>
                              {month?.map((positiveItem) => {
                                return (
                                  <td className="border_right boldTd text-center">
                                    {Math.round(
                                      (postitiveCount[positiveItem] * 100) / 5
                                    )}
                                    %
                                  </td>
                                );
                              })}
                              <td className="boldTd"></td>
                            </tr>
                            <tr className="">
                              <td className="border_right boldTd">
                                Negative Count%
                              </td>
                              {month?.map((negativeItem) => {
                                return (
                                  <td className="border_right boldTd text-center">
                                    {Math.round(
                                      (negativeCount[negativeItem] * 100) / 5
                                    )}
                                    %
                                  </td>
                                );
                              })}
                              <td className="boldTd"></td>
                            </tr>
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={14} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Col xl={12} className="mt-2">
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#000000" }}></div>
            <p>{`< -30%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#FF0000" }}></div>
            <p>{`>= -30% - <-15%`}</p>
          </div>

          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#F1A983" }}></div>
            <p>{`>= -15% - <-10%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#F7C7AC" }}></div>
            <p>{`>= -10% - <0%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#FBE2D5" }}></div>
            <p>{`>= 0% - <5%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#FFFC9D" }}></div>
            <p>{`>= 5% - <15%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#8ED973" }}></div>
            <p>{`>= 15% - <25%`}</p>
          </div>
          <div className="seasonalityAnalysisCount">
            <div style={{ background: "#3C7D22" }}></div>
            <p>{`>25%`}</p>
          </div>
        </div>
      </Col>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default SeasonalityAnalysis;
