import React, { useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import { checkValidation } from "../../../../utils/helper";
import { validationRule } from "../../../../utils/constant";
import { errorToastMessage } from "../../../../utils/toastMessage";

const DisplacementCalculator = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  let validationObj = {
    checkIn: "",
    checkOut: "",
    requestedRooms: "",
    requestedRate: "",
    sellOutThreshold: "",
    validationRule: [
      {
        fieldName: "checkIn",
        rule: validationRule.required,
        message: "CheckIn is required",
      },
      {
        fieldName: "checkOut",
        rule: validationRule.required,
        message: "CheckOut is required",
      },
      {
        fieldName: "requestedRooms",
        rule: validationRule.required,
        message: "Requested Rooms is required",
      },
      {
        fieldName: "requestedRate",
        rule: validationRule.required,
        message: "Requested Rate is required",
      },
      {
        fieldName: "sellOutThreshold",
        rule: validationRule.required,
        message: "Sell Out Threshold is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [filter, setFilter] = useState(validationObj);
  const [displacementData, setDisplacementData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    checkIn: "",
    checkOut: "",
    room: "",
  });

  // let requestedRoom = 0,
  //   roomBooked = 0,
  //   roomsAva = 0,
  //   forecastRoomsold = 0,
  //   forecastAdr = 0,
  //   roomDisplaced = 0,
  //   revDisplaced = 0,
  //   recMinRate = 0,
  //   occupancy = 0,
  //   barRate = 0;

  let total = {
    proposedRoom: 0,
    proposedRate: 0,
    proposedRev: 0,
    lyRoom: 0,
    lyRate: 0,
    lyRev: 0,
    forecastRoom: 0,
    forecastOcc: 0,
    forecastRate: 0,
    forecastRev: 0,
    displcedRoom: 0,
    displcedRev: 0,
    incrementalRoom: 0,
    incrementalRev: 0,
    newForecastRoom: 0,
    newForecastOcc: 0,
    newForecastRate: 0,
    newForecastRev: 0,
  };

  const getDisplacementData = async () => {
    setLoading(true);
    let displacementDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DISPLACEMENT.GET_LIST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(filter.checkIn),
          endDate: commonService.getDateForNode(filter.checkOut),
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, displacementDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      calculateTableData(
        dataFromApi,
        filter?.requestedRooms ? filter?.requestedRooms : 0
      );
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const calculateTableData = (data, requestedRooms) => {
    let groupingJson = data?.map((item) => {
      let roomDisplaced =
        parseInt(requestedRooms) -
        ((item?.["LEFT TO SELL"] ? parseInt(item?.["LEFT TO SELL"]) : 0) -
          (item?.["Forecasted ROOM"]
            ? parseInt(item?.["Forecasted ROOM"])
            : 0));
      let revDisplaced =
        roomDisplaced *
        (item?.["Forecasted ADR"] ? parseInt(item?.["Forecasted ADR"]) : 0);
      let recMinRate = "";
      if (revDisplaced && requestedRooms) {
        recMinRate = revDisplaced / requestedRooms;
      }

      item.requestedRooms = requestedRooms;
      item.roomDisplaced = roomDisplaced;
      item.revDisplaced = revDisplaced;
      item.recMinRate = recMinRate;
      return item;
    });
    setDisplacementData(groupingJson);
  };

  const getNewDisplacementData = async () => {
    setLoading(true);
    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        body: { propertyCode: propertyDetailFromRTK?.propertycode },
      },
      false
    );

    if (asOfDateResponse?.status_code === 0) {
      setLoading(false);
      return errorToastMessage(asOfDateResponse?.message);
    } else if (asOfDateResponse?.status_code === 1) {
      let displacementDataResponse = await apiCall(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.SnapShot +
            API_URL.DISPLACEMENT.NEW_DISPLACEMENT_LIST,
          body: {
            AsOfDate: asOfDateResponse?.data?.[0]?.last_data_refresh
              ? asOfDateResponse?.data?.[0]?.last_data_refresh
              : `${commonService.getDateForNode(new Date())} 00:00:00`,
            startDate: commonService.getDateForNode(filter.checkIn),
            endDate: commonService.getDateForNode(filter.checkOut),
            propertyCode: propertyDetailFromRTK?.propertycode,
          },
        },
        false
      );

      let response = apiResponse(false, displacementDataResponse);

      if (response?.isValidate) {
        let dataFromApi = response?.data?.data;
        setDisplacementData(dataFromApi);
        setLoading(false);
      }

      if (!response?.isValidate) {
        setDisplacementData(null);
        setLoading(false);
      }
    }
  };

  const handleRequestedRoomChange = (index, e) => {
    let newData = [...displacementData];

    let requestedRooms = e.target.value;
    let roomDisplaced =
      parseInt(requestedRooms) -
      ((newData[index]?.["LEFT TO SELL"]
        ? parseInt(newData[index]?.["LEFT TO SELL"])
        : 0) -
        (newData[index]?.["Forecasted ROOM"]
          ? parseInt(newData[index]?.["Forecasted ROOM"])
          : 0));
    let revDisplaced =
      roomDisplaced *
      (newData[index]?.["Forecasted ADR"]
        ? parseInt(newData[index]?.["Forecasted ADR"])
        : 0);
    let recMinRate = revDisplaced / requestedRooms;

    newData[index].requestedRooms = requestedRooms;
    newData[index].roomDisplaced = roomDisplaced;
    newData[index].revDisplaced = revDisplaced;
    newData[index].recMinRate = recMinRate;

    setDisplacementData(newData);
  };

  const handleCalculate = () => {
    if (api !== 0) {
      let validation = checkValidation(filter);

      if (!validation.isValidate) {
        setFilter(validation);
        return;
      }

      // getDisplacementData();
      getNewDisplacementData();
    }
  };

  return (
    <div className="RMRateShopMain">
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center">
                <h2>
                  Dashboard-{" "}
                  <span className="tab-title-main">
                    Revenue Management - Tools - Displacement Calculator
                  </span>
                </h2>
              </div>
            </Col>
            {/* <Col xl={9} className="mt-3"> */}
            <div className="d-flex align-items-start gap-4 conditional-class mt-3">
              <div className="width150">
                <div className="from-groups no-border-break">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    minDate={new Date()}
                    selected={filter.checkIn}
                    onChange={(date) => {
                      let selectedDate = new Date(date);
                      selectedDate.setDate(selectedDate.getDate() + 1);
                      setFilter({
                        ...filter,
                        checkIn: date,
                        checkOut: selectedDate,
                      });
                    }}
                  />
                  {filter?.errorObj?.checkIn && (
                    <small className="text-danger">
                      {filter?.errorObj?.checkIn}
                    </small>
                  )}
                </div>
              </div>
              <div className="width150">
                <div className="from-groups no-border-break">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    minDate={filter.checkIn}
                    selected={filter.checkOut}
                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        checkOut: date,
                      });
                    }}
                  />
                  {filter?.errorObj?.checkOut && (
                    <small className="text-danger">
                      {filter?.errorObj?.checkOut}
                    </small>
                  )}
                </div>
              </div>
              <div className="width150">
                <div className="from-groups no-border-break">
                  <Form.Label>Requested Rooms</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={16}
                    value={filter.requestedRooms}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      if (e.target.validity.valid) {
                        setFilter({
                          ...filter,
                          requestedRooms: e.target.value,
                        });
                        calculateTableData(
                          displacementData,
                          e.target.value ? e.target.value : 0
                        );
                      }
                    }}
                  />
                  {filter?.errorObj?.requestedRooms && (
                    <small className="text-danger">
                      {filter?.errorObj?.requestedRooms}
                    </small>
                  )}
                </div>
              </div>
              <div className="width150">
                <div className="from-groups no-border-break">
                  <Form.Label>Requested Rate</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={16}
                    value={filter.requestedRate}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      if (e.target.validity.valid) {
                        setFilter({
                          ...filter,
                          requestedRate: e.target.value,
                        });
                      }

                      if (e.target.value === "") {
                        setFilter({
                          ...filter,
                          requestedRate: "",
                        });
                      }
                    }}
                  />
                </div>
                {filter?.errorObj?.requestedRate && (
                  <small className="text-danger">
                    {filter?.errorObj?.requestedRate}
                  </small>
                )}
              </div>
              <div className="width150">
                <div className="from-groups no-border-break">
                  <Form.Label>Sell Out Threshold (%)</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={16}
                    value={filter.sellOutThreshold}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      if (e.target.validity.valid) {
                        setFilter({
                          ...filter,
                          sellOutThreshold: e.target.value,
                        });
                      }

                      if (e.target.value === "") {
                        setFilter({
                          ...filter,
                          sellOutThreshold: "",
                        });
                      }
                    }}
                  />
                </div>
                {filter?.errorObj?.sellOutThreshold && (
                  <small className="text-danger">
                    {filter?.errorObj?.sellOutThreshold}
                  </small>
                )}
              </div>
              <div className="marign-condition-btn">
                <Button
                  className="default-btn primary-btn gobtnwidth"
                  onClick={() => {
                    handleCalculate();
                  }}
                >
                  Calculate
                </Button>
              </div>
            </div>
            {/* </Col> */}
            <Col xl={12}>
              <div className="RateShopTableViewTable mt-3">
                <div className="ProspectDashboardTitleWraper">
                  <h2>
                    <span className="tab-title-main">
                      Displacement Day By Day
                    </span>
                  </h2>
                </div>
                <div className="table__container mt-2">
                  <div className="stripped__table">
                    <div className="table-responsive tblDynamicPickupSticky">
                      <Table>
                        <thead className="tbl-header-sticky">
                          <tr>
                            <th>Date</th>
                            <th className="border_right">DOW</th>
                            <th>Proposed Rooms</th>
                            <th className="text-end">Proposed Rate</th>
                            <th className="border_right text-end">
                              Proposed Revenue
                            </th>
                            <th>LY Rooms</th>
                            <th className="text-end">LY Rate</th>
                            <th className="border_right text-end">
                              LY Revenue
                            </th>
                            <th>Forecasted Rooms</th>
                            <th>Forecasted OCC</th>
                            <th className="text-end">Forecasted Rate</th>
                            <th className="border_right text-end">
                              Forecasted Revenue
                            </th>
                            <th>Displaced Rooms</th>
                            <th className="border_right text-end">
                              Displaced Revenue
                            </th>
                            <th>Incremental Rooms</th>
                            <th className="border_right text-end">
                              Incremental Revenue
                            </th>
                            <th>New Forecasted Rooms</th>
                            <th>New Forecasted Occ</th>
                            <th className="text-end">New Forecasted Rate</th>
                            <th className="text-end">New Forecasted Revenue</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr
                              style={{ height: "80px", position: "relative" }}
                            >
                              <Loader />
                            </tr>
                          ) : displacementData ? (
                            displacementData?.map((item, index) => {
                              let proposedRevenue = Math.round(
                                parseInt(filter?.requestedRooms) *
                                  parseInt(filter?.requestedRate)
                              );

                              let displacedRoom = 0;
                              let proposedPlusForecastRoom =
                                (filter?.requestedRooms
                                  ? parseInt(filter?.requestedRooms)
                                  : 0) +
                                (item?.FORECAST_RMS ? item?.FORECAST_RMS : 0);
                              let selloutMulInventory =
                                (item?.INVENTORY ? item?.INVENTORY : 0) *
                                (filter?.sellOutThreshold / 100);

                              if (
                                proposedPlusForecastRoom <
                                (selloutMulInventory ? selloutMulInventory : 0)
                              ) {
                                displacedRoom = 0;
                              } else {
                                if (
                                  (item?.FORECAST_OCC
                                    ? item?.FORECAST_OCC
                                    : 0) > filter?.sellOutThreshold
                                ) {
                                  let normalForecastOccValue =
                                    item?.FORECAST_OCC
                                      ? item?.FORECAST_OCC / 100
                                      : 0;
                                  let subValue =
                                    (item?.INVENTORY ? item?.INVENTORY : 0) *
                                      normalForecastOccValue -
                                    (item?.FORECAST_RMS
                                      ? item?.FORECAST_RMS
                                      : 0);
                                  displacedRoom =
                                    parseInt(filter?.requestedRooms) - subValue;
                                } else {
                                  let subVale =
                                    selloutMulInventory -
                                    (item?.FORECAST_RMS
                                      ? item?.FORECAST_RMS
                                      : 0);
                                  displacedRoom =
                                    parseInt(filter?.requestedRooms) - subVale;
                                }
                              }

                              let displacedRevenue =
                                (displacedRoom
                                  ? Math.floor(displacedRoom)
                                  : 0) *
                                (item?.FORECAST_Rate ? item?.FORECAST_Rate : 0);

                              let incrementalRoom =
                                parseInt(filter?.requestedRooms) -
                                (displacedRoom ? Math.floor(displacedRoom) : 0);
                              let incrementalRevenue =
                                (proposedRevenue
                                  ? Math.round(proposedRevenue)
                                  : 0) -
                                (displacedRevenue
                                  ? Math.round(displacedRevenue)
                                  : 0);

                              let newForecastRoom =
                                (item?.FORECAST_RMS ? item?.FORECAST_RMS : 0) +
                                incrementalRoom;
                              let newForecastOcc =
                                newForecastRoom > 0 && item?.INVENTORY > 0
                                  ? (newForecastRoom * 100) / item?.INVENTORY
                                  : 0;
                              let newForecastRev =
                                (item?.FORECAST_REV ? item?.FORECAST_REV : 0) +
                                incrementalRevenue;
                              let newForecastRate =
                                newForecastRev > 0 && newForecastRoom > 0
                                  ? newForecastRev / newForecastRoom
                                  : 0;

                              //Total For Every Column
                              total.proposedRoom =
                                total.proposedRoom +
                                parseInt(filter?.requestedRooms);
                              total.proposedRate =
                                total.proposedRate +
                                parseInt(filter?.requestedRate);
                              total.proposedRev =
                                total?.proposedRev +
                                (proposedRevenue ? proposedRevenue : 0);
                              total.lyRoom =
                                total.lyRoom +
                                (item?.LY_RMS ? item?.LY_RMS : 0);
                              total.lyRate =
                                total.lyRate +
                                (item?.LY_Rate ? item?.LY_Rate : 0);
                              total.lyRev =
                                total.lyRev + (item?.LY_REV ? item?.LY_REV : 0);
                              total.forecastRoom =
                                total?.forecastRoom +
                                (item?.FORECAST_RMS ? item?.FORECAST_RMS : 0);
                              total.forecastOcc =
                                total?.forecastOcc +
                                (item?.FORECAST_OCC ? item?.FORECAST_OCC : 0);
                              total.forecastRate =
                                total?.forecastRate +
                                (item?.FORECAST_Rate ? item?.FORECAST_Rate : 0);
                              total.forecastRev =
                                total?.forecastRev +
                                (item?.FORECAST_REV ? item?.FORECAST_REV : 0);
                              total.displcedRoom =
                                total.displcedRoom +
                                (displacedRoom ? Math.floor(displacedRoom) : 0);
                              total.displcedRev =
                                total.displcedRev +
                                (displacedRevenue
                                  ? Math.round(displacedRevenue)
                                  : 0);
                              total.incrementalRoom =
                                total.incrementalRoom +
                                (incrementalRoom ? incrementalRoom : 0);
                              total.incrementalRev =
                                total.incrementalRev +
                                (incrementalRevenue ? incrementalRevenue : 0);
                              total.newForecastRoom =
                                total.newForecastRoom +
                                (newForecastRoom ? newForecastRoom : 0);
                              total.newForecastOcc =
                                total.newForecastOcc +
                                (newForecastOcc ? newForecastOcc : 0);
                              total.newForecastRate =
                                total.newForecastRate +
                                (newForecastRate ? newForecastRate : 0);
                              total.newForecastRev =
                                total.newForecastRev +
                                (newForecastRev ? newForecastRev : 0);

                              return (
                                <>
                                  <tr
                                    key={item?.Dates}
                                    className={`shadowOnHover`}
                                  >
                                    <td>
                                      {item?.to_char
                                        ? commonService.getDateInFormat(
                                            item?.to_char
                                          )
                                        : ""}
                                    </td>
                                    <td className="border_right">
                                      {item?.DOW}
                                      {/* {item?.INVENTORY}*/}
                                    </td>
                                    <td>
                                      {filter?.requestedRooms
                                        ? commonService.formateRoom(
                                            filter?.requestedRooms
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td className="text-end">
                                      {filter?.requestedRate
                                        ? commonService.formateAmount(
                                            filter?.requestedRate
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-end border_right">
                                      {proposedRevenue
                                        ? commonService.formateAmount(
                                            proposedRevenue
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td>
                                      {item?.LY_RMS
                                        ? commonService.formateRoom(
                                            item?.LY_RMS
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td className="text-end">
                                      {item?.LY_Rate
                                        ? commonService.formateAmount(
                                            item?.LY_Rate
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-end border_right">
                                      {item?.LY_REV
                                        ? commonService.formateAmount(
                                            item?.LY_REV
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td>
                                      {item?.FORECAST_RMS
                                        ? commonService.formateRoom(
                                            item?.FORECAST_RMS
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td>
                                      {item?.FORECAST_OCC
                                        ? item?.FORECAST_OCC
                                        : 0}
                                      %
                                    </td>
                                    <td className="text-end">
                                      {item?.FORECAST_Rate
                                        ? commonService.formateAmount(
                                            item?.FORECAST_Rate
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-end border_right">
                                      {item?.FORECAST_REV
                                        ? commonService.formateAmount(
                                            item?.FORECAST_REV
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td>
                                      {displacedRoom
                                        ? commonService.formateRoom(
                                            Math.floor(displacedRoom)
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td className="text-end border_right">
                                      {displacedRevenue
                                        ? commonService.formateAmount(
                                            Math.round(displacedRevenue)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td>
                                      {incrementalRoom
                                        ? commonService.formateRoom(
                                            Math.round(incrementalRoom)
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td className="text-end border_right">
                                      {incrementalRevenue
                                        ? commonService.formateAmount(
                                            Math.round(incrementalRevenue)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td>
                                      {newForecastRoom
                                        ? commonService.formateRoom(
                                            Math.round(newForecastRoom)
                                          )
                                        : commonService.formateRoom(0)}
                                    </td>
                                    <td>
                                      {newForecastOcc
                                        ? Math.round(newForecastOcc)
                                        : 0}
                                      %
                                    </td>
                                    <td className="text-end">
                                      {newForecastRate
                                        ? commonService.formateAmount(
                                            Math.round(newForecastRate)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-end">
                                      {newForecastRev
                                        ? commonService.formateAmount(
                                            Math.round(newForecastRev)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                  </tr>
                                  {index === displacementData?.length - 1 && (
                                    <tr>
                                      <td
                                        colSpan={2}
                                        className="boldTd text-center border_right"
                                      >
                                        Total
                                      </td>
                                      <td className="boldTd">
                                        {total?.proposedRoom
                                          ? total?.proposedRoom
                                          : 0}
                                      </td>
                                      {/* <td className="boldTd text-end">
                                        {total?.proposedRate
                                          ? commonService.formateAmount(
                                              total?.proposedRate
                                            )
                                          : commonService.formateAmount(0)}
                                      </td> */}
                                      <td className="boldTd text-end">
                                        {total?.proposedRev > 0 &&
                                        total?.proposedRoom > 0
                                          ? commonService.formateAmount(
                                              Math.round(
                                                total?.proposedRev /
                                                  total?.proposedRoom
                                              )
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd text-end border_right">
                                        {total?.proposedRev
                                          ? commonService.formateAmount(
                                              total?.proposedRev
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.lyRoom
                                          ? commonService.formateRoom(
                                              total?.lyRoom
                                            )
                                          : commonService.formateRoom(0)}
                                      </td>
                                      {/* <td className="boldTd text-end">
                                        {total?.lyRate
                                          ? commonService.formateAmount(
                                              total?.lyRate
                                            )
                                          : commonService.formateAmount(0)}
                                      </td> */}
                                      <td className="boldTd text-end">
                                        {total?.lyRev > 0 && total?.lyRoom > 0
                                          ? commonService.formateAmount(
                                              Math.round(
                                                total?.lyRev / total?.lyRoom
                                              )
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd text-end border_right">
                                        {total?.lyRev
                                          ? commonService.formateAmount(
                                              total?.lyRev
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.forecastRoom
                                          ? commonService.formateRoom(
                                              total?.forecastRoom
                                            )
                                          : commonService.formateRoom(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.forecastOcc
                                          ? Math.round(
                                              total?.forecastOcc /
                                                displacementData?.length
                                            )
                                          : 0}
                                        %
                                      </td>
                                      {/* <td className="boldTd text-end">
                                        {total?.forecastRate
                                          ? commonService.formateAmount(
                                              Math.round(total?.forecastRate)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td> */}
                                      <td className="boldTd text-end">
                                        {total?.forecastRev > 0 &&
                                        total?.forecastRoom > 0
                                          ? commonService.formateAmount(
                                              Math.round(
                                                total?.forecastRev /
                                                  total?.forecastRoom
                                              )
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd text-end border_right">
                                        {total?.forecastRev
                                          ? commonService.formateAmount(
                                              Math.round(total?.forecastRev)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.displcedRoom
                                          ? commonService.formateRoom(
                                              Math.round(total?.displcedRoom)
                                            )
                                          : commonService.formateRoom(0)}
                                      </td>
                                      <td className="boldTd text-end border_right">
                                        {total?.displcedRev
                                          ? commonService.formateAmount(
                                              Math.round(total?.displcedRev)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.incrementalRoom
                                          ? commonService.formateRoom(
                                              Math.round(total?.incrementalRoom)
                                            )
                                          : commonService.formateRoom(0)}
                                      </td>
                                      <td className="boldTd text-end border_right">
                                        {total?.incrementalRev
                                          ? commonService.formateAmount(
                                              Math.round(total?.incrementalRev)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.newForecastRoom
                                          ? commonService.formateRoom(
                                              Math.round(total?.newForecastRoom)
                                            )
                                          : commonService.formateRoom(0)}
                                      </td>
                                      <td className="boldTd">
                                        {total?.newForecastOcc
                                          ? Math.round(
                                              total?.newForecastOcc /
                                                displacementData?.length
                                            )
                                          : 0}
                                        %
                                      </td>
                                      {/* <td className="boldTd text-end">
                                        {total?.newForecastRate
                                          ? commonService.formateAmount(
                                              Math.round(total?.newForecastRate)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td> */}
                                      <td className="boldTd text-end">
                                        {total?.newForecastRev > 0 &&
                                        total?.newForecastRoom > 0
                                          ? commonService.formateAmount(
                                              Math.round(
                                                total?.newForecastRev /
                                                  total?.newForecastRoom
                                              )
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                      <td className="boldTd text-end">
                                        {total?.newForecastRev
                                          ? commonService.formateAmount(
                                              Math.round(total?.newForecastRev)
                                            )
                                          : commonService.formateAmount(0)}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={20} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                          <tr></tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
    </div>
  );
};

export default DisplacementCalculator;
