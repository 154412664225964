import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Form, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import { FixColumn } from "../../../../utils/dataTable";

const Rates = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startRangeDate, setStartRangeDate] = useState(startDate);
  const [endRangeDate, setEndRangeDate] = useState(endDate);
  const [loading, setLoading] = useState(false);
  const [ratingFrom, setRatingFrom] = useState(null);
  const [selectedRatingFrom, setSelectedRatingFrom] = useState();
  const [chartData, setChartData] = useState(null);
  const [allRatesData, setAllRatesData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tblids, settblids] = useState({
    analyticsRatesTable: commonService.GenerateGUID(),
  });
  const ratingDataObjRef = useRef();
  const datesArrRef = useRef();
  const propertyListarrRef = useRef();
  let dayName = false;

  const getRatesData = async () => {
    setLoading(true);
    let ratesResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANALYTICS.RATES,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startRangeDate),
          endDate: commonService.getDateForNode(endRangeDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, ratesResponse);

    if (response?.isValidate) {
      let ratingfrom = [],
        ratingFromObj = {},
        dataFromApi = response?.data?.data;

      let datesArr = commonService.getDateBetweenTwoDates(
        new Date(startRangeDate),
        new Date(endRangeDate)
      );
      datesArrRef.current = datesArr;

      let labels = [],
        datasets = [],
        propertyObj = {},
        propertyArr = [],
        isPropertyExistsInArr = {};

      dataFromApi?.forEach((item) => {
        if (!ratingFromObj[item?.ratefrom]) {
          ratingfrom.push(item?.ratefrom);
          ratingFromObj[item?.ratefrom] = {};
        }

        if (!propertyObj[item?.propertyname]) {
          propertyObj[item?.propertyname] = [];
          datasets.push({
            type: "line",
            label: item?.propertyname,
            data: [],
            fill: false,
            backgroundColor: commonService.CreateColor(item?.propertyname)
              ?.BGColor,
            borderColor: commonService.CreateColor(item?.propertyname)?.BGColor,
            borderWidth: 2,
            pointRadius: 1,
            pointBorderWidth: 4,
          });
        }

        if (!isPropertyExistsInArr[item?.propertyname]) {
          propertyArr.push(item?.propertyname);
          isPropertyExistsInArr[item?.propertyname] = item?.propertyname;
        }
      });

      // ratingFromObj = {};
      // ratingfrom?.forEach((item) => {
      //   let groupingJson = {};
      //   Object.keys(propertyObj)?.forEach((propertyItem) => {
      //     groupingJson[`${propertyItem}-${item}`] = [];
      //   });
      //   ratingFromObj[item] = groupingJson;
      // });

      // datesArr?.forEach((item) => {
      //   labels.push(moment(item).format("MM/DD"));
      //   let findData = dataFromApi?.filter(
      //     (x) =>
      //       commonService.getDateInFormat(x?.ratedate) ===
      //       commonService.getDateInFormat(item)
      //   );
      //   if (findData?.length > 0) {
      //     let dummyObj = {};
      //     findData?.forEach((findDataItem) => {
      //       let findByPropertyName = findData?.filter(
      //         (x) => x?.propertyname === findDataItem?.propertyname
      //       );
      //       if (findByPropertyName?.length === ratingfrom?.length) {
      //         ratingFromObj[findDataItem?.ratefrom][
      //           `${findDataItem?.propertyname}-${findDataItem?.ratefrom}`
      //         ]?.push(findDataItem?.rates);
      //       } else {
      //         if (!dummyObj[findDataItem?.propertyname]) {
      //           ratingfrom?.forEach((ratingItem) => {
      //             let findValue = findByPropertyName?.filter(
      //               (x) => x?.ratefrom === ratingItem
      //             );
      //             if (findValue?.length > 0) {
      //               ratingFromObj[ratingItem][
      //                 `${findDataItem?.propertyname}-${ratingItem}`
      //               ]?.push(findDataItem?.rates);
      //             } else {
      //               ratingFromObj[ratingItem][
      //                 `${findDataItem?.propertyname}-${ratingItem}`
      //               ]?.push(0);
      //             }
      //           });
      //         }
      //         dummyObj[findDataItem?.propertyname] = findDataItem?.propertyname;
      //       }
      //     });
      //   } else {
      //     Object?.entries(ratingFromObj)?.forEach(([key, value], index) => {
      //       Object.keys(value)?.forEach((valueItem) => {
      //         ratingFromObj[key][`${valueItem}`]?.push(0);
      //       });
      //     });
      //   }
      // });
      // ratingDataObjRef.current = ratingFromObj;
      // setSelectedRatingFrom(ratingfrom[0]);
      // datasets = datasets?.map((dataSetItem) => {
      //   dataSetItem.data =
      //     ratingFromObj[ratingfrom[0]][
      //       `${dataSetItem?.label}-${ratingfrom[0]}`
      //     ];
      //   return dataSetItem;
      // });

      // setChartData({
      //   labels,
      //   datasets,
      // });
      // setSelectedRatingFrom(ratingfrom?.[0]);

      setRatingFrom(ratingfrom);
      setAllRatesData(response?.data?.data);
      propertyListarrRef.current = propertyArr;
      setLoading(false);
    }

    if (!response?.isValidate) {
      datesArrRef.current = [];
      setRatingFrom(null);
      setAllRatesData(null);
      setChartData(null);
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartRangeDate(start);
    setEndRangeDate(end);
  };

  const handleRatingFromOnChange = (selectedValue) => {
    let data = chartData?.datasets;
    let updatedDataSet = data?.map((item) => {
      item.data =
        ratingDataObjRef.current[selectedValue][
          `${item?.label}-${selectedValue}`
        ];

      return item;
    });
    setChartData({
      ...chartData,
      datasets: updatedDataSet,
    });
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: $${value}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (startRangeDate && endRangeDate && para?.asOfDate) {
        getRatesData();
      }
    }
  }, [startRangeDate, endRangeDate, para?.asOfDate]);

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  FixColumn(tblids.analyticsRatesTable, [1]);

  return (
    <div>
      {/* {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div className="width175">
              <DatePicker
                onChange={(event) => handleDateChange(event)}
                startDate={startRangeDate}
                endDate={endRangeDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <div className="from-groups no-border-break width150">
              <Form.Select
                aria-label="Default select example"
                value={selectedRatingFrom}
                onChange={(e) => {
                  setSelectedRatingFrom(e.target.value);
                  handleRatingFromOnChange(e.target.value);
                }}
              >
                {ratingFrom?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>
          {chartData ? (
            <>
              <div style={{ height: "400px" }}>
                <Line data={chartData} options={chartOptions} />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <p>No Data Found</p>
            </div>
          )}
        </>
      )} */}
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div className="width175">
          <DatePicker
            onChange={(event) => handleDateChange(event)}
            startDate={startRangeDate}
            endDate={endRangeDate}
            selectsRange
            monthsShown={2}
          />
        </div>
        {/* <div className="from-groups no-border-break width150">
          <Form.Select
            aria-label="Default select example"
            value={selectedRatingFrom}
            onChange={(e) => {
              setSelectedRatingFrom(e.target.value);
              handleRatingFromOnChange(e.target.value);
            }}
          >
            {ratingFrom?.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </Form.Select>
        </div> */}
      </div>
      <div className="ViewPropertySetupTable mt-2">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids?.analyticsRatesTable}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="width200">Dates</th>
                  {ratingFrom?.map((item) => {
                    return (
                      <th className="text-end width75" key={item} title={item}>
                        {item?.length > 7 ? item?.slice(0, 7) + "..." : item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : allRatesData ? (
                  datesArrRef.current?.map((dateItem) => {
                    let singleDateData = allRatesData?.filter(
                      (x) =>
                        commonService.getDateInFormat(x?.ratedate) ===
                        commonService.getDateInFormat(dateItem)
                    );
                    dayName = false;
                    if (
                      moment(new Date(dateItem), "l").format("ddd") === "Fri" ||
                      moment(new Date(dateItem), "l").format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }
                    return (
                      <>
                        <tr
                          key={dateItem}
                          className={`shadowOnHover ${
                            dayName ? "backgroundTr" : ""
                          } ${singleDateData?.length > 0 ? "" : "d-none"}`}
                        >
                          <td
                            className="cursorPointer"
                            onClick={() => {
                              if (dateItem === selectedRow) {
                                setSelectedRow("");
                              } else {
                                setSelectedRow(dateItem);
                              }
                            }}
                          >
                            <div className="d-flex gap-2">
                              <p>
                                {commonService.getDateInFormat(dateItem)}{" "}
                                {moment(new Date(dateItem), "l").format("ddd")}
                              </p>
                              <img src={downArrow} />
                            </div>
                          </td>
                          {ratingFrom?.map((ratingItem) => {
                            let CurrentPropertyData = singleDateData?.filter(
                              (x) =>
                                x?.propertyname ===
                                  propertyDetailFromRTK?.propertyname &&
                                x?.ratefrom === ratingItem &&
                                commonService.getDateInFormat(x?.ratedate) ===
                                  commonService.getDateInFormat(dateItem)
                            );

                            let competitorPropertyData = singleDateData?.filter(
                              (x) =>
                                x?.rates &&
                                x?.ratefrom === ratingItem &&
                                commonService.getDateInFormat(x?.ratedate) ===
                                  commonService.getDateInFormat(dateItem) &&
                                x?.propertyname !==
                                  propertyDetailFromRTK?.propertyname
                            );
                            let totalRates = 0;
                            competitorPropertyData?.forEach((item) => {
                              totalRates =
                                totalRates +
                                (item?.rates ? parseFloat(item?.rates) : 0);
                            });
                            let avg =
                              totalRates > 0
                                ? Math.round(
                                    totalRates / competitorPropertyData?.length
                                  )
                                : 0;

                            return (
                              <td className="text-end">
                                {CurrentPropertyData?.[0]?.rates
                                  ? commonService.formateAmount(
                                      Math.round(
                                        CurrentPropertyData?.[0]?.rates
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                                {"/"}
                                {commonService.formateAmount(avg)}
                              </td>
                            );
                          })}
                        </tr>
                        {propertyListarrRef.current?.map((propertyItem) => {
                          let findPropertyDataExists = allRatesData?.filter(
                            (x) =>
                              x?.propertyname === propertyItem &&
                              commonService.getDateInFormat(x?.ratedate) ===
                                commonService.getDateInFormat(dateItem)
                          );
                          if (
                            propertyItem !==
                              propertyDetailFromRTK?.propertyname &&
                            findPropertyDataExists?.length > 0
                          ) {
                            return (
                              <tr
                                className={`shadowOnHover childTrColor ${
                                  selectedRow === dateItem ? "" : "displayNone"
                                }`}
                              >
                                <td>{propertyItem}</td>
                                {ratingFrom?.map((rateItem) => {
                                  let propertyWiseRates = allRatesData?.filter(
                                    (x) =>
                                      x?.propertyname === propertyItem &&
                                      x?.ratefrom === rateItem &&
                                      commonService.getDateInFormat(
                                        x?.ratedate
                                      ) ===
                                        commonService.getDateInFormat(dateItem)
                                  );
                                  return (
                                    <td className="text-end">
                                      {propertyWiseRates?.[0]?.rates
                                        ? commonService.formateAmount(
                                            Math.round(
                                              propertyWiseRates?.[0]?.rates
                                            )
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          }
                        })}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center"
                      colSpan={ratingFrom?.length + 1}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rates;
