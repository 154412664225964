import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import plusIcon from "../../Assets/Images/FormCheck/plusIcon.png";
import deleteIcon from "../../Assets/Images/Action Icons/deleteaction.svg";

const SingleDayGroupModal = ({
  showModal,
  closeModal,
  asOfDate,
  selectedDate,
  pickupDate,
  propertyCode,
}) => {
  const userDetail = commonService.getLoginUserData();
  const [groupData, setGroupData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupWiseList, setGroupWiseList] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tableError, setTableError] = useState(null);
  const groupWiseListRef = useRef();
  groupWiseListRef.current = groupWiseList;

  const getGroupData = async () => {
    setLoading(true);
    let groupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.SINGLE_DAY_GROUP_SUMMARY,
        body: {
          AsOfDate: asOfDate,
          selectedDate: selectedDate,
          propertyCode: propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupResponse);

    if (response?.isValidate) {
      setLoading(false);
      setGroupData(response?.data?.data);
      setGroupWiseList(response?.data?.data?.[0]?.GroupDetails);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setGroupData(null);
      setGroupWiseList(null);
    }
  };

  const handleGroupListChange = (index, field, value) => {
    groupWiseListRef.current[index][field] = value;
    let data = [...groupWiseListRef.current]
    setGroupWiseList(data);
  };

  const handleSave = async () => {
    setSaveLoading(true);
    let errorArr = [];
    groupWiseList?.forEach((item, index) => {
      if (!item?.Rooms) {
        errorArr.push(`${index}room`);
      }
      if (!item?.Revenue) {
        errorArr.push(`${index}rev`);
      }
      if (!item?.ADR) {
        errorArr.push(`${index}adr`);
      }
      if (!item?.GroupName) {
        errorArr.push(`${index}name`);
      }
    });

    if (errorArr?.length > 0) {
      setTableError(errorArr);
      setSaveLoading(false);
      return;
    }

    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET.SAVE_GROUP_DATA,
        body: {
          propertyCode: propertyCode,
          AsOfDate: asOfDate,
          selectedDate: selectedDate,
          clientid: userDetail?.clientid ? userDetail?.clientid : "",
          data: groupWiseList,
        },
      },
      false
    );

    let response = apiResponse(true, saveResponse);

    if (response?.isValidate) {
      closeModal();
    }

    if (!response?.isValidate) {
      setSaveLoading(false);
    }
  };

  const isErrorExists = (field, index) => {
    let error = false;

    let errorExists = tableError?.filter((x) => x === `${index}${field}`);

    if (errorExists?.length > 0) {
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getGroupData();
  }, [asOfDate, selectedDate]);

  return (
    <div className="GroupNameModalMain ToolsSingleTicketPopUpMain singleDateSummaryModalMain">
      <div className="GroupNameModalHeader">
        <h2>Single Group Summary</h2>
        <button className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Group Block</th>
                    <th className="border_right">7 Day Block Pickup</th>
                    <th className="border_right">Group Otb</th>
                    <th>7 Day OTB Pickup</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    <tr>
                      <td className="border_right">
                        {groupData?.[0]?.GroupBlock
                          ? groupData?.[0]?.GroupBlock
                          : 0}
                      </td>
                      <td className="border_right">
                        {(groupData?.[0]?.GroupBlock
                          ? groupData?.[0]?.GroupBlock
                          : 0) -
                          (groupData?.[0]?.["7 day block pickup"]
                            ? groupData?.[0]?.["7 day block pickup"]
                            : 0)}
                      </td>
                      <td className="border_right">
                        {groupData?.[0]?.GroupOTB
                          ? groupData?.[0]?.GroupOTB
                          : 0}
                      </td>
                      <td>
                        {(groupData?.[0]?.GroupOTB
                          ? groupData?.[0]?.GroupOTB
                          : 0) -
                          (groupData?.[0]?.["7 day OTB pickup"]
                            ? groupData?.[0]?.["7 day OTB pickup"]
                            : 0)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end", gap: "5px" }}
        >
          {saveLoading ? (
            <button
              className="default-btn primary-btn width75"
              type="button"
              style={{ padding: "5px 10px" }}
              disabled
            >
              <span
                className="spinner-border spinner-border-sm "
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : (
            <button
              className="default-btn primary-btn width75"
              style={{ padding: "5px 10px" }}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          )}

          <Button
            className="add-btn-plus"
            style={{ padding: "0 0 0 5px" }}
            type="button"
            title="Add"
            onClick={(e) => {
              let obj = {
                Rooms: null,
                Revenue: null,
                ADR: null,
                GroupName: null,
              };
              let updatedArr = [];

              if (groupWiseList) {
                updatedArr = [...groupWiseList, obj];
              } else {
                updatedArr = [obj];
              }
              setGroupWiseList(updatedArr);
            }}
          >
            <img src={plusIcon} alt="plusIcon" />
          </Button>
        </div>

        <div className="ViewPropertySetupTable mt-2">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Group Name</th>
                    <th className="border_right">Rooms</th>
                    <th className="text-end border_right">Adr</th>
                    <th className="text-end border_right">Revenue</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : groupWiseList?.length > 0 ? (
                      groupWiseList?.map((item, index) => {
                        let borderStyle = "";

                        return (
                          <tr key={index}>
                            <td className="border_right">
                              <Form.Control
                                style={{
                                  border: `${
                                    isErrorExists("name", index)
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                }}
                                type="text"
                                className="small-padding"
                                value={item?.GroupName}
                                onChange={(e) => {
                                  handleGroupListChange(
                                    index,
                                    "GroupName",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="border_right">
                              <Form.Control
                                type="text"
                                style={{
                                  border: `${
                                    isErrorExists("room", index)
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                }}
                                className="small-padding width50"
                                value={item?.Rooms}
                                onChange={(e) => {
                                  handleGroupListChange(
                                    index,
                                    "Rooms",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="text-end border_right">
                              <Form.Control
                                type="text"
                                style={{
                                  border: `${
                                    isErrorExists("adr", index)
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                }}
                                className="small-padding width50"
                                value={item?.ADR}
                                onChange={(e) => {
                                  handleGroupListChange(
                                    index,
                                    "ADR",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="text-end border_right">
                              <Form.Control
                                type="text"
                                style={{
                                  border: `${
                                    isErrorExists("rev", index)
                                      ? "1px solid red"
                                      : ""
                                  }`,
                                }}
                                className="small-padding width50"
                                value={item?.Revenue}
                                onChange={(e) => {
                                  handleGroupListChange(
                                    index,
                                    "Revenue",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td>
                            <img
                                src={deleteIcon}
                                alt=""
                                className="cursorPointer"
                                onClick={() => {
                                  let updatedData = groupWiseList?.filter(
                                    (x, i) => i !== index
                                  );
                                  setGroupWiseList(updatedData);
                                }}
                              />
                              {/* <p
                                onClick={(e) => {
                                  let updatedData = groupWiseList?.filter(
                                    (x, i) => i !== index
                                  );
                                  setGroupWiseList(updatedData);
                                }}
                              >
                                D
                              </p> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={4}>No Data Found</td>
                      </tr>
                    )

                    // <tr>
                    //   <td className="border_right">0</td>
                    //   <td className="border_right">0</td>
                    //   <td className="text-end border_right">
                    //     {groupData?.[0]?.ADR
                    //       ? `${commonService.formateAmount(
                    //           groupData?.[0]?.ADR
                    //         )}`
                    //       : 0}
                    //   </td>
                    //   <td className="text-end">
                    //     {groupData?.[0]?.TotalRevenue
                    //       ? `${commonService.formateAmount(
                    //           groupData?.[0]?.TotalRevenue
                    //         )}`
                    //       : 0}
                    //   </td>
                    // </tr>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDayGroupModal;
