import React, { useEffect, useRef, useState } from "react";
import { Button, Overlay, Popover, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDateRMSModal from "../../SingleDateRMSModal";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const DayByDayBookingWindow = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );
  const [dbdData, setDbdData] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otbLoading, setOtbLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectedType, setSelectedType] = useState({
    netStly: true,
    pickup: false,
  });
  const [selectPickupDate, setSelectPickupDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const [rms, setRms] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const rmsSingleDateRef = useRef();
  const rmsSelectedTdIndexRef = useRef();
  const rmsSelectedMarketSegmentRef = useRef();
  const dateRangeObj = useRef();
  const maxDateRef = useRef();
  const marketSegmentData = useRef();
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    zeroDayRms = 0,
    zeroDayLyRms = 0,
    zeroDayRev = 0,
    zeroDayLyRev = 0,
    oneDayRms = 0,
    oneDayLyRms = 0,
    oneDayRev = 0,
    oneDayLyRev = 0,
    two_7DayRms = 0,
    two_7DayLyRms = 0,
    two_7DayRev = 0,
    two_7DayLyRev = 0,
    eight_15DayRms = 0,
    eight_15DayLyRms = 0,
    eight_15DayRev = 0,
    eight_15DayLyRev = 0,
    sixteen_30DayRms = 0,
    sixteen_30DayLyRms = 0,
    sixteen_30DayRev = 0,
    sixteen_30DayLyRev = 0,
    thirtyOne_60DayRms = 0,
    thirtyOne_60DayLyRms = 0,
    thirtyOne_60DayRev = 0,
    thirtyOne_60DayLyRev = 0,
    sixtyOne_90DayRms = 0,
    sixtyOne_90DayLyRms = 0,
    sixtyOne_90DayRev = 0,
    sixtyOne_90DayLyRev = 0,
    ninentyPlusDayRms = 0,
    ninentyPlusDayLyRms = 0,
    ninentyPlusDayRev = 0,
    ninentyPlusDayLyRev = 0;

  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
    let date1 = new Date(maxDateForPickupFrom);
    singleDatePickupDateRef.current = date1.setDate(date1.getDate() - 7);
  }

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );

  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getDbdStlyData = async () => {
    setLoading(true);
    let dbdStlyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.BOOKING_WINDOW.DAY_BY_DAY_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dbdStlyResponse);

    if (response?.isValidate) {
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setDbdData(null);
      setLoading(false);
    }
  };

  const getDbdPickupData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 7);
    let pickupDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.BOOKING_WINDOW.DAY_BY_DAY_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          PickUpDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, pickupDataResponse);

    if (response?.isValidate) {
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setDbdData(null);
      setLoading(false);
    }
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setOtbLoading(true);
    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOtbLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
    }
  };

  const [tblids, settblids] = useState({
    tblDbdBookingWindow: commonService.GenerateGUID(),
  });

  useEffect(() => {
    if (api === 0) {
      dateRangeObj.current = commonService.datesBetweenTwoDate(
        startRangeDateRef.current
          ? commonService.convertDateInTimezone(startRangeDateRef.current)
          : commonService.convertDateInTimezone(startDate),
        endRangeDateRef.current
          ? commonService.convertDateInTimezone(endRangeDateRef.current)
          : commonService.convertDateInTimezone(endDate)
      );
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
      setOtbData([]);
      setDbdData([]);
    } else {
      if (para?.asOfDate) {
        dateRangeObj.current = commonService.datesBetweenTwoDate(
          startRangeDateRef.current
            ? commonService.convertDateInTimezone(startRangeDateRef.current)
            : commonService.convertDateInTimezone(startDate),
          endRangeDateRef.current
            ? commonService.convertDateInTimezone(endRangeDateRef.current)
            : commonService.convertDateInTimezone(endDate)
        );
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 1);
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        if (selectedType?.netStly) {
          getDbdStlyData();
        } else {
          getDbdPickupData();
        }
      }
    }
  }, [para?.asOfDate, selectedType, selectPickupDate]);

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.tblDbdBookingWindow} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblDbdBookingWindow} .perbackground-adr`
  );
  FixColumn(tblids.tblDbdBookingWindow, [1]);

  // useEffect(() => {
  //   let findTbl = document.getElementById(`${tblids.tblDbdBookingWindow}`);
  //   if (findTbl && dbdData && otbData) {
  //     const findTblHeader = findTbl?.querySelectorAll("thead tr");
  //     const findTblBody = findTbl?.querySelectorAll("tbody tr");

  //     const lastRow = findTblBody[findTblBody?.length - 1];

  //     let lastRowTds = lastRow?.querySelectorAll("td");
  //     const removeSymbol=(value)=>{
  //       let removeComma = value?.replace(",","")
  //       return removeComma
  //     }
  //     let totalRowObj = {
  //       totalRooms: removeSymbol(lastRowTds?.[3]?.innerText),
  //       zeroDayRmsTotal: removeSymbol(lastRowTds?.[8]?.innerText),
  //       oneDayRmsTotal: removeSymbol(lastRowTds?.[14]?.innerText),
  //       two_7DayRmsTotal: removeSymbol(lastRowTds?.[20]?.innerText),
  //       eight_15DayRmsTotal: removeSymbol(lastRowTds?.[26]?.innerText),
  //       sixteen_30DayRmsTotal: removeSymbol(lastRowTds?.[32]?.innerText),
  //       thirtyone_60DayRmsTotal: removeSymbol(lastRowTds?.[38]?.innerText),
  //       sixtyone_90DayRmsTotal: removeSymbol(lastRowTds?.[44]?.innerText),
  //       ninentyPlusDayRmsTotal: removeSymbol(lastRowTds?.[50]?.innerText),
  //     };

  //     const secondRowOfHeader = findTblHeader[1];
  //     const secondRowHeaderTds = secondRowOfHeader?.querySelectorAll("th");
  //     const totalPercentageObj = {
  //       zeroDay: 0,
  //       oneDay: 0,
  //       two_7Day: 0,
  //       eight_15Day: 0,
  //       sixteen_30Day: 0,
  //       thirtyone_60Day: 0,
  //       sixtyone_90Day: 0,
  //       ninentyPlusDay: 0,
  //     };

  //     totalPercentageObj.zeroDay =
  //       totalRowObj?.zeroDayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round((parseInt(totalRowObj?.zeroDayRmsTotal) * 100) /
  //           parseInt(totalRowObj?.totalRooms))
  //         : 0;

  //     totalPercentageObj.oneDay =
  //       totalRowObj?.oneDayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.oneDayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.two_7Day =
  //       totalRowObj?.two_7DayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.two_7DayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.eight_15Day =
  //       totalRowObj?.eight_15DayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.eight_15DayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.sixteen_30Day =
  //       totalRowObj?.sixteen_30DayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.sixteen_30DayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.thirtyone_60Day =
  //       totalRowObj?.thirtyone_60DayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.thirtyone_60DayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.sixtyone_90Day =
  //       totalRowObj?.sixtyone_90DayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.sixtyone_90DayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     totalPercentageObj.ninentyPlusDay =
  //       totalRowObj?.ninentyPlusDayRmsTotal && totalRowObj?.totalRooms
  //         ? Math.round(
  //             (parseInt(totalRowObj?.ninentyPlusDayRmsTotal) * 100) /
  //               parseInt(totalRowObj?.totalRooms)
  //           )
  //         : 0;

  //     secondRowHeaderTds[3].innerText = `0 DAY (${totalPercentageObj.zeroDay}%)`;
  //     secondRowHeaderTds[4].innerText = `1 DAY (${totalPercentageObj.oneDay}%)`;
  //     secondRowHeaderTds[5].innerText = `2-7 DAY (${totalPercentageObj.two_7Day}%)`;
  //     secondRowHeaderTds[6].innerText = `8-15 DAY (${totalPercentageObj.eight_15Day}%)`;
  //     secondRowHeaderTds[7].innerText = `16-30 DAY (${totalPercentageObj.sixteen_30Day}%)`;
  //     secondRowHeaderTds[8].innerText = `31-60 DAY (${totalPercentageObj.thirtyone_60Day}%)`;
  //     secondRowHeaderTds[9].innerText = `61-90 DAY (${totalPercentageObj.sixtyone_90Day}%)`;
  //     secondRowHeaderTds[10].innerText = `90+ DAY (${totalPercentageObj.ninentyPlusDay}%)`;

  //     const rmsWiseArr = [
  //       {
  //         key: "zeroDay",
  //         value: totalRowObj?.zeroDayRmsTotal
  //           ? parseInt(totalRowObj?.zeroDayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "oneDay",
  //         value: totalRowObj?.oneDayRmsTotal
  //           ? parseInt(totalRowObj?.oneDayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "two_7Day",
  //         value: totalRowObj?.two_7DayRmsTotal
  //           ? parseInt(totalRowObj?.two_7DayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "eight_15Day",
  //         value: totalRowObj?.eight_15DayRmsTotal
  //           ? parseInt(totalRowObj?.eight_15DayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "sixteen_30Day",
  //         value: totalRowObj?.sixteen_30DayRmsTotal
  //           ? parseInt(totalRowObj?.sixteen_30DayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "thirtyone_60Day",
  //         value: totalRowObj?.thirtyone_60DayRmsTotal
  //           ? parseInt(totalRowObj?.thirtyone_60DayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "sixtyone_90Day",
  //         value: totalRowObj?.sixtyone_90DayRmsTotal
  //           ? parseInt(totalRowObj?.sixtyone_90DayRmsTotal)
  //           : 0,
  //       },
  //       {
  //         key: "ninentyPlusDay",
  //         value: totalRowObj?.ninentyPlusDayRmsTotal
  //           ? parseInt(totalRowObj?.ninentyPlusDayRmsTotal)
  //           : 0,
  //       },
  //     ];

  //     rmsWiseArr.sort((a, b) => a.value - b.value);
  //     let colorObj = {
  //       0: "zeroDay",
  //       1: "oneDay",
  //       2: "two_7Day",
  //       3: "eight_15Day",
  //       4: "sixteen_30Day",
  //       5: "thirtyone_60Day",
  //       6: "sixtyone_90Day",
  //       7: "ninentyPlusDay",
  //     };

  //     findTblBody?.forEach((item) => {
  //       let currentRowTds = item?.querySelectorAll("td");

  //       let dayWiseIndex = 0,
  //         count = 0;
  //       for (let i = 8; i < currentRowTds?.length; i++) {
  //         let findIndex = rmsWiseArr?.findIndex(
  //           (x) => x?.key === colorObj[dayWiseIndex]
  //         );
  //         let finalValueForRgba = `0.${findIndex}`;
  //         let total = 0.3 + parseFloat(finalValueForRgba);
  //         currentRowTds[
  //           i
  //         ].style.backgroundColor = `rgba(132, 210, 246, ${total})`;

  //         count = count + 1;
  //         if (count >= 6) {
  //           dayWiseIndex = dayWiseIndex + 1;
  //           count = 0;
  //         }
  //       }
  //     });
  //   }
  // }, [dbdData, otbData]);

  useEffect(() => {
    let findTbl = document.getElementById(`${tblids.tblDbdBookingWindow}`);
    if (findTbl?.querySelector(".loding-animation")) {
      return;
    }
    if (findTbl && dbdData && otbData) {
      const findTblHeader = findTbl?.querySelectorAll("thead tr");
      const findTblBody = findTbl?.querySelectorAll("tbody tr");

      const lastRow = findTblBody[findTblBody?.length - 1];

      let lastRowTds = lastRow?.querySelectorAll("td");
      const removeSymbol = (value) => {
        let removeComma = value?.replaceAll(",", "");
        return removeComma;
      };
      let totalRowObj = {
        totalRooms: removeSymbol(lastRowTds?.[3]?.innerText),
        zeroDayRmsTotal: removeSymbol(lastRowTds?.[8]?.innerText),
        oneDayRmsTotal: removeSymbol(lastRowTds?.[14]?.innerText),
        two_7DayRmsTotal: removeSymbol(lastRowTds?.[20]?.innerText),
        eight_15DayRmsTotal: removeSymbol(lastRowTds?.[26]?.innerText),
        sixteen_30DayRmsTotal: removeSymbol(lastRowTds?.[32]?.innerText),
        thirtyone_60DayRmsTotal: removeSymbol(lastRowTds?.[38]?.innerText),
        sixtyone_90DayRmsTotal: removeSymbol(lastRowTds?.[44]?.innerText),
        ninentyPlusDayRmsTotal: removeSymbol(lastRowTds?.[50]?.innerText),
      };

      const secondRowOfHeader = findTblHeader[1];
      const secondRowHeaderTds = secondRowOfHeader?.querySelectorAll("th");
      const totalPercentageObj = {
        zeroDay: 0,
        oneDay: 0,
        two_7Day: 0,
        eight_15Day: 0,
        sixteen_30Day: 0,
        thirtyone_60Day: 0,
        sixtyone_90Day: 0,
        ninentyPlusDay: 0,
      };

      totalPercentageObj.zeroDay =
        totalRowObj?.zeroDayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.zeroDayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.oneDay =
        totalRowObj?.oneDayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.oneDayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.two_7Day =
        totalRowObj?.two_7DayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.two_7DayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.eight_15Day =
        totalRowObj?.eight_15DayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.eight_15DayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.sixteen_30Day =
        totalRowObj?.sixteen_30DayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.sixteen_30DayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.thirtyone_60Day =
        totalRowObj?.thirtyone_60DayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.thirtyone_60DayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.sixtyone_90Day =
        totalRowObj?.sixtyone_90DayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.sixtyone_90DayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      totalPercentageObj.ninentyPlusDay =
        totalRowObj?.ninentyPlusDayRmsTotal && totalRowObj?.totalRooms
          ? Math.round(
              (parseInt(totalRowObj?.ninentyPlusDayRmsTotal) * 100) /
                parseInt(totalRowObj?.totalRooms)
            )
          : 0;

      secondRowHeaderTds[3].innerText = `0 DAY (${totalPercentageObj.zeroDay}%)`;
      secondRowHeaderTds[4].innerText = `1 DAY (${totalPercentageObj.oneDay}%)`;
      secondRowHeaderTds[5].innerText = `2-7 DAY (${totalPercentageObj.two_7Day}%)`;
      secondRowHeaderTds[6].innerText = `8-15 DAY (${totalPercentageObj.eight_15Day}%)`;
      secondRowHeaderTds[7].innerText = `16-30 DAY (${totalPercentageObj.sixteen_30Day}%)`;
      secondRowHeaderTds[8].innerText = `31-60 DAY (${totalPercentageObj.thirtyone_60Day}%)`;
      secondRowHeaderTds[9].innerText = `61-90 DAY (${totalPercentageObj.sixtyone_90Day}%)`;
      secondRowHeaderTds[10].innerText = `90+ DAY (${totalPercentageObj.ninentyPlusDay}%)`;

      let rmsArr = {},
        stlyRmsArr = {},
        adrArr = {},
        stlyAdrArr = {},
        revArr = {},
        stlyRevArr = {};

      findTblBody?.forEach((item, index) => {
        let allTd = item?.querySelectorAll("td");
        let valueIndex = 0;

        allTd?.forEach((tdItem, tdIndex) => {
          if (tdIndex > 7) {
            if (valueIndex === 0) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (rmsArr[index]) {
                rmsArr[index] = [...rmsArr[index], parseInt(finalValue)];
              } else {
                rmsArr[index] = [parseInt(finalValue)];
              }
            } else if (valueIndex === 1) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (stlyRmsArr[index]) {
                stlyRmsArr[index] = [
                  ...stlyRmsArr[index],
                  parseInt(finalValue),
                ];
              } else {
                stlyRmsArr[index] = [parseInt(finalValue)];
              }
            } else if (valueIndex === 2) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (adrArr[index]) {
                adrArr[index] = [...adrArr[index], parseInt(finalValue)];
              } else {
                adrArr[index] = [parseInt(finalValue)];
              }
            } else if (valueIndex === 3) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (stlyAdrArr[index]) {
                stlyAdrArr[index] = [
                  ...stlyAdrArr[index],
                  parseInt(finalValue),
                ];
              } else {
                stlyAdrArr[index] = [parseInt(finalValue)];
              }
            } else if (valueIndex === 4) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (revArr[index]) {
                revArr[index] = [...revArr[index], parseInt(finalValue)];
              } else {
                revArr[index] = [parseInt(finalValue)];
              }
            } else if (valueIndex === 5) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              if (stlyRevArr[index]) {
                stlyRevArr[index] = [
                  ...stlyRevArr[index],
                  parseInt(finalValue),
                ];
              } else {
                stlyRevArr[index] = [parseInt(finalValue)];
              }
            }

            valueIndex = valueIndex + 1;
            if (valueIndex > 5) {
              valueIndex = 0;
            }
          }
        });
      });

      findTblBody?.forEach((item, index) => {
        let allTd = item?.querySelectorAll("td");
        let valueIndex = 0;

        let rmsArrValue = [...rmsArr[index]];
        let adrArrValue = [...adrArr[index]];
        let revArrValue = [...revArr[index]];

        rmsArrValue.sort(function (a, b) {
          return a - b;
        });
        adrArrValue.sort(function (a, b) {
          return a - b;
        });
        revArrValue.sort(function (a, b) {
          return a - b;
        });

        allTd?.forEach((tdItem, tdIndex) => {
          if (tdIndex > 7) {
            if (valueIndex === 0) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              let findIndex = rmsArrValue?.findIndex(
                (x) => x == parseInt(finalValue)
              );
              let finalValueForRgba = `0.${findIndex}`;
              let total = 0.3 + parseFloat(finalValueForRgba);
              tdItem.style.backgroundColor = `rgba(132, 210, 246, ${total})`;
            } else if (valueIndex === 1) {
            } else if (valueIndex === 2) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              let findIndex = adrArrValue?.findIndex(
                (x) => x == parseInt(finalValue)
              );
              let finalValueForRgba = `0.${findIndex}`;
              let total = 0.3 + parseFloat(finalValueForRgba);
              tdItem.style.backgroundColor = `rgba(255, 136, 0, ${total})`;
            } else if (valueIndex === 3) {
            } else if (valueIndex === 4) {
              let value = tdItem?.innerText?.replaceAll("$", "");
              let finalValue = value?.replaceAll(",", "");
              let findIndex = revArrValue?.findIndex(
                (x) => x == parseInt(finalValue)
              );
              let finalValueForRgba = `0.${findIndex}`;
              let total = 0.3 + parseFloat(finalValueForRgba);
              tdItem.style.backgroundColor = `rgba(117, 182, 118, ${total})`;
            } else if (valueIndex === 5) {
            }

            valueIndex = valueIndex + 1;
            if (valueIndex > 5) {
              valueIndex = 0;
            }
          }
        });
      });
    }
  }, [dbdData, otbData, loading, otbLoading]);

  const calculateValue = (monthData, value) => {
    let max = 0,
      keyName = `BW0_${value}`;
    if (
      (monthData?.[0]?.[`BW0_${value}`]
        ? parseInt(monthData?.[0]?.[`BW0_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW0_${value}`]);
      keyName = `BW0_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW1_${value}`]
        ? parseInt(monthData?.[0]?.[`BW1_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW1_${value}`]);
      keyName = `BW1_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW2TO7_${value}`]
        ? parseInt(monthData?.[0]?.[`BW2TO7_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW2TO7_${value}`]);
      keyName = `BW2TO7_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW8TO15_${value}`]
        ? parseInt(monthData?.[0]?.[`BW8TO15_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW8TO15_${value}`]);
      keyName = `BW8TO15_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW16TO30_${value}`]
        ? parseInt(monthData?.[0]?.[`BW16TO30_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW16TO30_${value}`]);
      keyName = `BW16TO30_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW31TO60_${value}`]
        ? parseInt(monthData?.[0]?.[`BW31TO60_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW31TO60_${value}`]);
      keyName = `BW31TO60_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW61TO90_${value}`]
        ? parseInt(monthData?.[0]?.[`BW61TO90_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW61TO90_${value}`]);
      keyName = `BW61TO90_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW91TOUP_${value}`]
        ? parseInt(monthData?.[0]?.[`BW91TOUP_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW91TOUP_${value}`]);
      keyName = `BW91TOUP_${value}`;
    }
    return keyName;
  };

  const calculateHighestValue = (monthData) => {
    let obj = {
      rms: "",
      adr: "",
      rev: "",
    };
    obj.rms = calculateValue(monthData, "RMS");
    obj.adr = calculateValue(monthData, "ADR");
    obj.rev = calculateValue(monthData, "REV");
    return obj;
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDbdBookingWindow,
                  "Day By Day Booking Window",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.dayByDayBookingWindow,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDbdBookingWindow}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDbdBookingWindow,
                  `${para?.propertyCode}-Day By Day Booking Window-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblDbdBookingWindow}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right">Date</th>
                  <th className="text-center border_right" colSpan={5}>
                    On The Books
                  </th>
                  <th className="text-center border_right" colSpan={2}>
                    Revenue
                  </th>
                  <th className="text-center" colSpan={48}>
                    <div className="form-groups form-groups-radio-btn d-flex justify-content-center">
                      <div className="d-flex heatmap-main align-item-center">
                        <div className="form-radio d-flex align-items-center">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="radioGroup"
                            id="Option2"
                            value="Inactive"
                            checked={selectedType?.netStly}
                            onChange={() => {
                              marketSegmentData.current = [];
                              setSelectedType({
                                pickup: false,
                                netStly: true,
                              });
                            }}
                          />
                          <label
                            htmlFor="Option2"
                            className="label-radio form-check-label m-0"
                          >
                            Net Stly
                          </label>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                          <div className="form-radio d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="radioGroup"
                              id="Option1"
                              value="Active"
                              checked={selectedType?.isPickupFrom}
                              onChange={() => {
                                marketSegmentData.current = [];
                                setSelectedType({
                                  isPickupFrom: true,
                                  isOnTheBook: false,
                                });
                              }}
                            />
                            <label
                              htmlFor="Option1"
                              className="label-radio form-check-label m-0"
                            >
                              Pickup From
                            </label>
                          </div>
                          <div>
                            <DatePicker
                              id="asOfDate"
                              disabled={
                                selectedType?.isPickupFrom ? false : true
                              }
                              maxDate={new Date(maxDateRef.current)}
                              selected={
                                selectPickupDate
                                  ? commonService.convertDateInTimezone(
                                      selectPickupDate
                                    )
                                  : commonService.convertDateInTimezone(
                                    singleDatePickupDateRef.current
                                    )
                              }
                              onChange={(date) => {
                                let month =
                                  date.getMonth() + 1 > 9
                                    ? date.getMonth() + 1
                                    : `0${date.getMonth() + 1}`;
                                let dateFormat =
                                  date?.getDate() > 9
                                    ? date.getDate()
                                    : `0${date.getDate()}`;
                                let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                                setSelectPickupDate(selectedDate);
                              }}
                            />
                            <span style={{ display: "none" }}>
                              {selectedType?.isPickupFrom && selectPickupDate
                                ? commonService.getDateInFormat(
                                    new Date(selectPickupDate)
                                  )
                                : commonService.getDateInFormat(
                                    new Date(singleDatePickupDateRef.current)
                                  )}
                            </span>
                          </div>
                        </div>
                        <span style={{ display: "none" }}>
                          {selectedType?.isPickupFrom
                            ? "PickupFrom"
                            : "On The Book"}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th className="border_right"></th>
                  <th className="border_right" colSpan={5}></th>
                  <th className="border_right" colSpan={2}></th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    0 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    1 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    2-7 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    8-15 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    16-30 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    31-60 Day
                  </th>
                  <th className="width200 text-center border_right" colSpan={6}>
                    61-90 Day
                  </th>
                  <th className="width200 text-center" colSpan={6}>
                    90+ Day
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width200 border_right"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  setDbdData(null);
                                  setOtbData(null);
                                  dateRangeObj.current =
                                    commonService.datesBetweenTwoDate(
                                      startRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            startRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            startDate
                                          ),
                                      endRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            endRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            endDate
                                          )
                                    );
                                  if (selectedType?.netStly) {
                                    getDbdStlyData();
                                  } else {
                                    getDbdPickupData();
                                  }
                                  getORGDetail();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="width100">OOO/Rooms Available</th>
                  <th className="width100 text-center">Left To sell</th>
                  <th className="width100 text-center">On The Books</th>
                  <th>Total OCC%</th>
                  <th className="text-right border_right">ADR</th>
                  <th className="text-right">Rev</th>
                  <th className="border_right">RevPAr</th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === true || otbLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  dateRangeObj.current &&
                  Object.keys(dateRangeObj.current)?.map((item, index) => {
                    let singleOTBData = otbData?.filter(
                      (x) => x?.Date === item
                    );
                    let dbdDataStore = dbdData?.filter(
                      (x) => x.StayDate === item
                    );

                    let highestValueOfDay = "";
                    if (dbdDataStore?.length > 0) {
                      highestValueOfDay = calculateHighestValue(dbdDataStore);
                    }

                    let dayName = false;

                    ooo = ooo + singleOTBData?.[0]?.OOO;
                    RmsAvailable =
                      RmsAvailable + singleOTBData?.[0]?.RoomAvailable;
                    leftToSell = leftToSell + singleOTBData?.[0]?.LeftToSell;
                    otb = otb + singleOTBData?.[0]?.OnTheBook;
                    occPercentage =
                      occPercentage + singleOTBData?.[0]?.TotalOCCPercentage;
                    adr = adr + singleOTBData?.[0]?.ADR;
                    rev = rev + singleOTBData?.[0]?.REV;

                    revPar = revPar + singleOTBData?.[0]?.RevPAR;

                    zeroDayRms =
                      zeroDayRms +
                      (dbdDataStore?.[0]?.BW0_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW0_RMS)
                        : 0);
                    zeroDayRev =
                      zeroDayRev +
                      (dbdDataStore?.[0]?.BW0_REV
                        ? parseInt(dbdDataStore?.[0]?.BW0_REV)
                        : 0);

                    oneDayRms =
                      oneDayRms +
                      (dbdDataStore?.[0]?.BW1_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW1_RMS)
                        : 0);
                    oneDayRev =
                      oneDayRev +
                      (dbdDataStore?.[0]?.BW1_REV
                        ? parseInt(dbdDataStore?.[0]?.BW1_REV)
                        : 0);

                    two_7DayRms =
                      two_7DayRms +
                      (dbdDataStore?.[0]?.BW2TO7_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW2TO7_RMS)
                        : 0);
                    two_7DayRev =
                      two_7DayRev +
                      (dbdDataStore?.[0]?.BW2TO7_REV
                        ? parseInt(dbdDataStore?.[0]?.BW2TO7_REV)
                        : 0);

                    eight_15DayRms =
                      eight_15DayRms +
                      (dbdDataStore?.[0]?.BW8TO15_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW8TO15_RMS)
                        : 0);
                    eight_15DayRev =
                      eight_15DayRev +
                      (dbdDataStore?.[0]?.BW8TO15_REV
                        ? parseInt(dbdDataStore?.[0]?.BW8TO15_REV)
                        : 0);

                    sixteen_30DayRms =
                      sixteen_30DayRms +
                      (dbdDataStore?.[0]?.BW16TO30_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW16TO30_RMS)
                        : 0);
                    sixteen_30DayRev =
                      sixteen_30DayRev +
                      (dbdDataStore?.[0]?.BW16TO30_REV
                        ? parseInt(dbdDataStore?.[0]?.BW16TO30_REV)
                        : 0);

                    thirtyOne_60DayRms =
                      thirtyOne_60DayRms +
                      (dbdDataStore?.[0]?.BW31TO60_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW31TO60_RMS)
                        : 0);
                    thirtyOne_60DayRev =
                      thirtyOne_60DayRev +
                      (dbdDataStore?.[0]?.BW31TO60_REV
                        ? parseInt(dbdDataStore?.[0]?.BW31TO60_REV)
                        : 0);

                    sixtyOne_90DayRms =
                      sixtyOne_90DayRms +
                      (dbdDataStore?.[0]?.BW61TO90_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW61TO90_RMS)
                        : 0);
                    sixtyOne_90DayRev =
                      sixtyOne_90DayRev +
                      (dbdDataStore?.[0]?.BW61TO90_REV
                        ? parseInt(dbdDataStore?.[0]?.BW61TO90_REV)
                        : 0);

                    ninentyPlusDayRms =
                      ninentyPlusDayRms +
                      (dbdDataStore?.[0]?.BW91TOUP_RMS
                        ? parseInt(dbdDataStore?.[0]?.BW91TOUP_RMS)
                        : 0);
                    ninentyPlusDayRev =
                      ninentyPlusDayRev +
                      (dbdDataStore?.[0]?.BW91TOUP_REV
                        ? parseInt(dbdDataStore?.[0]?.BW91TOUP_REV)
                        : 0);

                    if (selectedType?.netStly) {
                      zeroDayLyRms =
                        zeroDayLyRms +
                        (dbdDataStore?.[0]?.BW0_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW0_LYRMS)
                          : 0);
                      zeroDayLyRev =
                        zeroDayLyRev +
                        (dbdDataStore?.[0]?.BW0_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW0_LYREV)
                          : 0);

                      oneDayLyRms =
                        oneDayLyRms +
                        (dbdDataStore?.[0]?.BW1_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW1_LYRMS)
                          : 0);
                      oneDayLyRev =
                        oneDayLyRev +
                        (dbdDataStore?.[0]?.BW1_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW1_LYREV)
                          : 0);

                      two_7DayLyRms =
                        two_7DayLyRms +
                        (dbdDataStore?.[0]?.BW2TO7_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW2TO7_LYRMS)
                          : 0);
                      two_7DayLyRev =
                        two_7DayLyRev +
                        (dbdDataStore?.[0]?.BW2TO7_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW2TO7_LYREV)
                          : 0);

                      eight_15DayLyRms =
                        eight_15DayLyRms +
                        (dbdDataStore?.[0]?.BW8TO15_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW8TO15_LYRMS)
                          : 0);
                      eight_15DayLyRev =
                        eight_15DayLyRev +
                        (dbdDataStore?.[0]?.BW8TO15_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW8TO15_LYREV)
                          : 0);

                      sixteen_30DayLyRms =
                        sixteen_30DayLyRms +
                        (dbdDataStore?.[0]?.BW16TO30_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW16TO30_LYRMS)
                          : 0);
                      sixteen_30DayLyRev =
                        sixteen_30DayLyRev +
                        (dbdDataStore?.[0]?.BW16TO30_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW16TO30_LYREV)
                          : 0);

                      thirtyOne_60DayLyRms =
                        thirtyOne_60DayLyRms +
                        (dbdDataStore?.[0]?.BW31TO60_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW31TO60_LYRMS)
                          : 0);
                      thirtyOne_60DayLyRev =
                        thirtyOne_60DayLyRev +
                        (dbdDataStore?.[0]?.BW31TO60_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW31TO60_LYREV)
                          : 0);

                      sixtyOne_90DayLyRms =
                        sixtyOne_90DayLyRms +
                        (dbdDataStore?.[0]?.BW61TO90_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW61TO90_LYRMS)
                          : 0);
                      sixtyOne_90DayLyRev =
                        sixtyOne_90DayLyRev +
                        (dbdDataStore?.[0]?.BW61TO90_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW61TO90_LYREV)
                          : 0);

                      ninentyPlusDayLyRms =
                        ninentyPlusDayLyRms +
                        (dbdDataStore?.[0]?.BW91TOUP_LYRMS
                          ? parseInt(dbdDataStore?.[0]?.BW91TOUP_LYRMS)
                          : 0);
                      ninentyPlusDayLyRev =
                        ninentyPlusDayLyRev +
                        (dbdDataStore?.[0]?.BW91TOUP_LYREV
                          ? parseInt(dbdDataStore?.[0]?.BW91TOUP_LYREV)
                          : 0);
                    } else {
                      zeroDayLyRms =
                        zeroDayLyRms +
                        (dbdDataStore?.[0]?.BW0_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW0_PURMS)
                          : 0);
                      zeroDayLyRev =
                        zeroDayLyRev +
                        (dbdDataStore?.[0]?.BW0_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW0_PUREV)
                          : 0);

                      oneDayLyRms =
                        oneDayLyRms +
                        (dbdDataStore?.[0]?.BW1_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW1_PURMS)
                          : 0);
                      oneDayLyRev =
                        oneDayLyRev +
                        (dbdDataStore?.[0]?.BW1_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW1_PUREV)
                          : 0);

                      two_7DayLyRms =
                        two_7DayLyRms +
                        (dbdDataStore?.[0]?.BW2TO7_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW2TO7_PURMS)
                          : 0);
                      two_7DayLyRev =
                        two_7DayLyRev +
                        (dbdDataStore?.[0]?.BW2TO7_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW2TO7_PUREV)
                          : 0);

                      eight_15DayLyRms =
                        eight_15DayLyRms +
                        (dbdDataStore?.[0]?.BW8TO15_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW8TO15_PURMS)
                          : 0);
                      eight_15DayLyRev =
                        eight_15DayLyRev +
                        (dbdDataStore?.[0]?.BW8TO15_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW8TO15_PUREV)
                          : 0);

                      sixteen_30DayLyRms =
                        sixteen_30DayLyRms +
                        (dbdDataStore?.[0]?.BW16TO30_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW16TO30_PURMS)
                          : 0);
                      sixteen_30DayLyRev =
                        sixteen_30DayLyRev +
                        (dbdDataStore?.[0]?.BW16TO30_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW16TO30_PUREV)
                          : 0);

                      thirtyOne_60DayLyRms =
                        thirtyOne_60DayLyRms +
                        (dbdDataStore?.[0]?.BW31TO60_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW31TO60_PURMS)
                          : 0);
                      thirtyOne_60DayLyRev =
                        thirtyOne_60DayLyRev +
                        (dbdDataStore?.[0]?.BW31TO60_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW31TO60_PUREV)
                          : 0);

                      sixtyOne_90DayLyRms =
                        sixtyOne_90DayLyRms +
                        (dbdDataStore?.[0]?.BW61TO90_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW61TO90_PURMS)
                          : 0);
                      sixtyOne_90DayLyRev =
                        sixtyOne_90DayLyRev +
                        (dbdDataStore?.[0]?.BW61TO90_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW61TO90_PUREV)
                          : 0);

                      ninentyPlusDayLyRms =
                        ninentyPlusDayLyRms +
                        (dbdDataStore?.[0]?.BW91TOUP_PURMS
                          ? parseInt(dbdDataStore?.[0]?.BW91TOUP_PURMS)
                          : 0);
                      ninentyPlusDayLyRev =
                        ninentyPlusDayLyRev +
                        (dbdDataStore?.[0]?.BW91TOUP_PUREV
                          ? parseInt(dbdDataStore?.[0]?.BW91TOUP_PUREV)
                          : 0);
                    }

                    if (
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    return (
                      <React.Fragment key={item}>
                        <tr
                          key={item}
                          className={`bookingWindowHover`}
                          // className={`${
                          //   dayName ? "backgroundTr" : ""
                          // } shadowOnHover`}
                        >
                          <td
                            className={`border_right ${
                              dayName ? "backgroundTd" : ""
                            }`}
                          >
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td
                            className={`width100 ${
                              dayName ? "backgroundTd" : ""
                            }`}
                          >
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.OOO
                                )
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.RoomAvailable
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center ${
                              dayName ? "backgroundTd" : ""
                            }`}
                          >
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-primary text-center perbackground perbackground-otb ${
                              dayName ? "backgroundTd" : ""
                            }`}
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                if (api !== 0) {
                                  singleDateSelectedDateRef.current = item;
                                  handleFilterBtnClick(e);
                                  selectedTdIndexRef.current = index;
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.OnTheBook
                                  )
                                : 0}
                            </span>
                          </td>
                          <td className={`${dayName ? "backgroundTd" : ""}`}>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? `${singleOTBData?.[0]?.TotalOCCPercentage}%`
                              : `0%`}
                          </td>
                          <td
                            className={`text-end perbackground perbackground-adr border_right ${
                              dayName ? "backgroundTd" : ""
                            }`}
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td
                            className={`text-start ${
                              dayName ? "backgroundTd" : ""
                            }`}
                          >
                            {singleOTBData?.[0]?.REV
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td
                            className={`text-end border_right ${
                              dayName ? "backgroundTd" : ""
                            }`}
                          >
                            {singleOTBData?.[0]?.RevPAR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          {/* Second API start */}
                          {/* 0 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW0_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW0_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW0_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW0_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW0_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW0_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW0_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW0_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW0_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW0_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW0_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW0_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW0_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW0_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW0_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW0_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW0_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW0_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW0_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW0_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW0_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          {/* 1 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW1_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW1_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW1_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW1_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW1_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW1_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW1_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW1_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW1_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW1_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW1_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW1_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW1_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW1_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW1_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW1_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW1_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW1_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW1_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW1_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW1_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          {/* 2-7 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW2TO7_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW2TO7_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW2TO7_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW2TO7_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW2TO7_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW2TO7_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW2TO7_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW2TO7_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW2TO7_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW2TO7_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW2TO7_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW2TO7_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW2TO7_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW2TO7_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW2TO7_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW2TO7_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW2TO7_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW2TO7_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW2TO7_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW2TO7_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW2TO7_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          {/* 8-15 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW8TO15_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW8TO15_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW8TO15_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW8TO15_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW8TO15_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW8TO15_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW8TO15_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW8TO15_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW8TO15_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW8TO15_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW8TO15_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW8TO15_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW8TO15_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW8TO15_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW8TO15_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW8TO15_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW8TO15_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW8TO15_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW8TO15_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW8TO15_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW8TO15_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          {/* 16-30 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW16TO30_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW16TO30_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW16TO30_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW16TO30_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW16TO30_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW16TO30_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW16TO30_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW16TO30_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW16TO30_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW16TO30_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW16TO30_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW16TO30_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW16TO30_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW16TO30_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW16TO30_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW16TO30_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW16TO30_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW16TO30_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW16TO30_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW16TO30_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW16TO30_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          {/* 31-60 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW31TO60_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW31TO60_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW31TO60_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW31TO60_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW31TO60_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW31TO60_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW31TO60_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW31TO60_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW31TO60_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW31TO60_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW31TO60_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW31TO60_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW31TO60_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW31TO60_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW31TO60_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW31TO60_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW31TO60_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW31TO60_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW31TO60_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW31TO60_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW31TO60_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          {/* 61-90 */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW61TO90_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW61TO90_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW61TO90_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW61TO90_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW61TO90_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW61TO90_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW61TO90_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW61TO90_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW61TO90_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW61TO90_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW61TO90_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW61TO90_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW61TO90_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW61TO90_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW61TO90_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW61TO90_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW61TO90_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW61TO90_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW61TO90_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW61TO90_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW61TO90_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          {/* 91+ */}
                          <td
                            className={`text-center ${
                              highestValueOfDay?.rms === "BW91TOUP_RMS"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW91TOUP_RMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW91TOUP_RMS
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW91TOUP_LYRMS
                                ? commonService.formateRoom(
                                    dbdDataStore?.[0]?.BW91TOUP_LYRMS
                                  )
                                : 0
                              : dbdDataStore?.[0]?.BW91TOUP_PURMS
                              ? commonService.formateRoom(
                                  dbdDataStore?.[0]?.BW91TOUP_PURMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.adr === "BW91TOUP_ADR"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW91TOUP_ADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW91TOUP_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW91TOUP_LYADR
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW91TOUP_LYADR
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW91TOUP_PUADR
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW91TOUP_PUADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end ${
                              highestValueOfDay?.rev === "BW91TOUP_REV"
                                ? "tdBorder"
                                : ""
                            }`}
                          >
                            {dbdDataStore?.[0]?.BW91TOUP_REV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW91TOUP_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.BW91TOUP_LYREV
                                ? commonService.formateAmount(
                                    dbdDataStore?.[0]?.BW91TOUP_LYREV
                                  )
                                : commonService.formateAmount(0)
                              : dbdDataStore?.[0]?.BW91TOUP_PUREV
                              ? commonService.formateAmount(
                                  dbdDataStore?.[0]?.BW91TOUP_PUREV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                        </tr>
                        {index ===
                          Object.keys(dateRangeObj.current)?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd border_right">Total</td>
                            <td className="boldTd">
                              {commonService.formateRoom(ooo)}/
                              {commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(leftToSell)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb)}
                            </td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end border_right">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    Math.round(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">
                              {zeroDayRms
                                ? commonService.formateRoom(zeroDayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {zeroDayLyRms
                                ? commonService.formateRoom(zeroDayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {zeroDayRms > 0 && zeroDayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(zeroDayRev / zeroDayRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {zeroDayLyRms > 0 && zeroDayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(zeroDayLyRev / zeroDayLyRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {zeroDayRev
                                ? commonService.formateAmount(zeroDayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {zeroDayLyRev
                                ? commonService.formateAmount(zeroDayLyRev)
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {oneDayRms
                                ? commonService.formateRoom(oneDayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {oneDayLyRms
                                ? commonService.formateRoom(oneDayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {oneDayRms > 0 && oneDayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(oneDayRev / oneDayRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {oneDayLyRms > 0 && oneDayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(oneDayLyRev / oneDayLyRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {oneDayRev
                                ? commonService.formateAmount(oneDayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {oneDayLyRev
                                ? commonService.formateAmount(oneDayLyRev)
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {two_7DayRms
                                ? commonService.formateRoom(two_7DayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {two_7DayLyRms
                                ? commonService.formateRoom(two_7DayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {two_7DayRms > 0 && two_7DayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(two_7DayRev / two_7DayRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {two_7DayLyRms > 0 && two_7DayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(two_7DayLyRev / two_7DayLyRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {two_7DayRev
                                ? commonService.formateAmount(two_7DayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {two_7DayLyRev
                                ? commonService.formateAmount(two_7DayLyRev)
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {eight_15DayRms
                                ? commonService.formateRoom(eight_15DayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {eight_15DayLyRms
                                ? commonService.formateRoom(eight_15DayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {eight_15DayRms > 0 && eight_15DayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(eight_15DayRev / eight_15DayRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {eight_15DayLyRms > 0 && eight_15DayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      eight_15DayLyRev / eight_15DayLyRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {eight_15DayRev
                                ? commonService.formateAmount(eight_15DayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {eight_15DayLyRev
                                ? commonService.formateAmount(eight_15DayLyRev)
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {sixteen_30DayRms
                                ? commonService.formateRoom(sixteen_30DayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {sixteen_30DayLyRms
                                ? commonService.formateRoom(sixteen_30DayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {sixteen_30DayRms > 0 && sixteen_30DayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      sixteen_30DayRev / sixteen_30DayRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {sixteen_30DayLyRms > 0 && sixteen_30DayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      sixteen_30DayLyRev / sixteen_30DayLyRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {sixteen_30DayRev
                                ? commonService.formateAmount(sixteen_30DayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {sixteen_30DayLyRev
                                ? commonService.formateAmount(
                                    sixteen_30DayLyRev
                                  )
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {thirtyOne_60DayRms
                                ? commonService.formateRoom(thirtyOne_60DayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {thirtyOne_60DayLyRms
                                ? commonService.formateRoom(
                                    thirtyOne_60DayLyRms
                                  )
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {thirtyOne_60DayRms > 0 && thirtyOne_60DayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      thirtyOne_60DayRev / thirtyOne_60DayRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {thirtyOne_60DayLyRms > 0 &&
                              thirtyOne_60DayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      thirtyOne_60DayLyRev /
                                        thirtyOne_60DayLyRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {thirtyOne_60DayRev
                                ? commonService.formateAmount(
                                    thirtyOne_60DayRev
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {thirtyOne_60DayLyRev
                                ? commonService.formateAmount(
                                    thirtyOne_60DayLyRev
                                  )
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {sixtyOne_90DayRms
                                ? commonService.formateRoom(sixtyOne_90DayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {sixtyOne_90DayLyRms
                                ? commonService.formateRoom(sixtyOne_90DayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {sixtyOne_90DayRms > 0 && sixtyOne_90DayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      sixtyOne_90DayRev / sixtyOne_90DayRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {sixtyOne_90DayLyRms > 0 &&
                              sixtyOne_90DayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      sixtyOne_90DayLyRev / sixtyOne_90DayLyRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {sixtyOne_90DayRev
                                ? commonService.formateAmount(sixtyOne_90DayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {sixtyOne_90DayLyRev
                                ? commonService.formateAmount(
                                    sixtyOne_90DayLyRev
                                  )
                                : commonService.formateAmount(0)}
                            </td>

                            <td className="boldTd text-center">
                              {ninentyPlusDayRms
                                ? commonService.formateRoom(ninentyPlusDayRms)
                                : 0}
                            </td>
                            <td className="boldTd text-center">
                              {ninentyPlusDayLyRms
                                ? commonService.formateRoom(ninentyPlusDayLyRms)
                                : 0}
                            </td>
                            <td className="boldTd text-end">
                              {ninentyPlusDayRms > 0 && ninentyPlusDayRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      ninentyPlusDayRev / ninentyPlusDayRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {ninentyPlusDayLyRms > 0 &&
                              ninentyPlusDayLyRev > 0
                                ? commonService.formateAmount(
                                    Math.round(
                                      ninentyPlusDayLyRev / ninentyPlusDayLyRms
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {ninentyPlusDayRev
                                ? commonService.formateAmount(ninentyPlusDayRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {ninentyPlusDayLyRev
                                ? commonService.formateAmount(
                                    ninentyPlusDayLyRev
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <Overlay
          show={ONTHEBOOKS.IsDisplay}
          target={ONTHEBOOKS.Target}
          placement={ONTHEBOOKS.Position}
        >
          <Popover
            id="popover-contained"
            className="SingleDateSummaryModalPopoverMain"
          >
            <div className="horizontal-menu-option active">
              <SingleDateSummaryModal
                closeModal={closeSingleDate}
                asOfDate={para?.asOfDate}
                selectedDate={singleDateSelectedDateRef.current}
                pickupDate={
                  selectPickupDate
                    ? selectPickupDate
                    : singleDatePickupDateRef.current
                }
                propertyDetail={{ propertyCode: para?.propertyCode }}
              />
            </div>
          </Popover>
        </Overlay>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DayByDayBookingWindow;
