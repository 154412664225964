import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Overlay, Popover, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { errorToastMessage } from "../../../../utils/toastMessage";
import OtbModal from "./OtbModal";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const HeatMap = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const yearsArr = [
    ...commonService.getPastYearForDropDown(5),
    ...commonService.getFutureYearForDropDown(2),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState("Default");
  let userPreferenceData = JSON.parse(userDetail?.userpreferences);

  let tableData = [
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-20",
      RMS: 1,
      ADR: 55,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-21",
      RMS: 3,
      ADR: 98,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-22",
      RMS: 3,
      ADR: 105,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-24",
      RMS: 2,
      ADR: 55,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-25",
      RMS: 2,
      ADR: 100,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-08-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-09",
      RMS: 2,
      ADR: 50,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-09-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-10-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-11-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2023-12-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-01-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-02-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-03-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-04-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-05-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-06-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-07-31",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-02",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-03",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-04",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-05",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-06",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-07",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-08",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-09",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-10",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-11",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-12",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-13",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-14",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-15",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-16",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-17",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-18",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-19",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-20",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-21",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-22",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-23",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-24",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-25",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-26",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-27",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-28",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-29",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-30",
      RMS: 0,
      ADR: 0,
    },
    {
      AsOfDate: "2023-08-20",
      Dates: "2024-08-31",
      RMS: 0,
      ADR: 0,
    },
  ];

  let monthNumberFromName = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const [heatMapDetail, setHeatMapDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState({
    // isPickupFrom: false,
    // isOnTheBook: true,
    isPickupFrom:
      userPreferenceData?.Heatmap?.DefaultDataView === "Total OTB"
        ? false
        : true,
    isOnTheBook:
      userPreferenceData?.Heatmap?.DefaultDataView === "Total OTB"
        ? true
        : false,
  });
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const maxDateRef = useRef();
  const defaultPickupDateRef = useRef();
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
    defaultPickupDateRef.current = date.setDate(date.getDate() - 6);
  }
  const monthNameDetail = useRef([]);
  const date = new Date();
  let startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );

  let endDate = commonService.getDateInDBFormat(
    date.setFullYear(date.getFullYear() + 1, date.getMonth() + 1, 0)
  );

  const [payloadStartDate, setPayloadStartDate] = useState(startDate);
  const [payloadEndDate, setPayloadEndDate] = useState(endDate);
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [selectGroupType, setSelectGroupType] = useState("All");
  const [totalData, setTotalData] = useState(null);
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const pickupDateForTotalOTB = useRef();
  const [maxRoom, setMaxRoom] = useState(0);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const selectedEventDateRef = useRef();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const handleFilterBtnClick = (event) => {
    const targetRect = event.target.getBoundingClientRect();
    const topSpace = targetRect.top;
    let Position = "bottom";
    // let Top = event.nativeEvent.layerY - 2;
    // if (Top + 400 > window.innerHeight) {
    //   Position = "top";
    // }
    if (topSpace + 300 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getMonthNameFromMonthNumber = (dateFromResponse) => {
    // let date = new Date(dateFromResponse);
    let date = moment(dateFromResponse).toDate();
    return date.toLocaleString("en-US", { month: "long" });
  };

  const setDateForApi = (value) => {
    if (value === "Default") {
      setPayloadStartDate(startDate);
      setPayloadEndDate(endDate);
    } else {
      setPayloadStartDate(`${value}-01-01`);
      setPayloadEndDate(`${value}-12-31`);
    }
    setSelectedYear(value);
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getHeatMapDetail = async () => {
    let pickupDate1 = new Date(para?.defaultAsOfDate);
    pickupDate1.setDate(pickupDate1.getDate() - 6);
    let pickupDate = commonService.convertDateInTimezone(pickupDate1);
    setLoading(true);
    await getEventList();
    let heatMapResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.HEAT_MAP,
        body: {
          AsOfDate: para?.asOfDate,
          // startDate: startDate,
          // endDate: endDate,
          startDate: payloadStartDate,
          endDate: payloadEndDate,
          propertyCode: para?.propertyCode,
          heatMapType: selectedType?.isPickupFrom ? "PICKUPFROM" : "ONTHEBOOK",
          pickupDate: selectPickupDate ? selectPickupDate : pickupDate,
          // pickupDate: "2023-08-23",
          hm_tg_type: selectGroupType,
        },
      },
      false
    );

    let response = apiResponse(false, heatMapResponse);

    if (response?.isValidate) {
      monthNameDetail.current = [];
      if (selectedYear === "Default") {
        let currentDate = new Date();
        let dateForMonth = moment(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        ).toDate();
        // let dateForMonth = moment(new Date()).toDate();
        for (let i = 0; i <= 12; i++) {
          let monthName = getMonthNameFromMonthNumber(dateForMonth);
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateForMonth?.getFullYear()}`,
          ];

          dateForMonth.setMonth(dateForMonth.getMonth() + 1);
        }
      } else {
        let dateForMonth = moment(new Date(selectedYear, 0, 1)).toDate();
        for (let i = 0; i <= 11; i++) {
          let monthName = getMonthNameFromMonthNumber(dateForMonth);
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateForMonth?.getFullYear()}`,
          ];

          dateForMonth.setMonth(dateForMonth.getMonth() + 1);
        }
      }

      let responseData = response?.data?.data?.result;

      let groupingJson = {
          1: {},
          2: {},
          3: {},
          4: {},
          5: {},
          6: {},
          7: {},
          8: {},
          9: {},
          10: {},
          11: {},
          12: {},
          13: {},
          14: {},
          15: {},
          16: {},
          17: {},
          18: {},
          19: {},
          20: {},
          21: {},
          22: {},
          23: {},
          24: {},
          25: {},
          26: {},
          27: {},
          28: {},
          29: {},
          30: {},
          31: {},
        },
        totalData = {};
      responseData?.sort(function (a, b) {
        return (
          moment(new Date(a.Dates)).toDate() -
          moment(new Date(b.Dates)).toDate()
        );
      });

      for (let i = 0; i < responseData?.length; i++) {
        // let dateKey = moment(new Date(responseData?.[i]?.Dates)).toDate();
        let dateKey = moment(responseData?.[i]?.Dates).toDate();
        let monthName = getMonthNameFromMonthNumber(responseData?.[i]?.Dates);
        let monthIndex = monthNameDetail.current?.findIndex(
          (x) => x === `${monthName}/${dateKey?.getFullYear()}`
        );
        let percentage = 0;
        if (responseData?.[i]?.RMS === 0) {
          percentage = 0;
        } else {
          percentage = Math.round(
            (100 * responseData?.[i]?.RMS) / response?.data?.data?.maxRoom
          );
        }
        if (monthIndex < 0) {
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateKey?.getFullYear()}`,
          ];
        }
        if (groupingJson?.hasOwnProperty(dateKey?.getDate())) {
          groupingJson[dateKey.getDate()] = {
            ...groupingJson?.[dateKey?.getDate()],
            [`${monthName}/${dateKey?.getFullYear()}`]: {
              ...responseData?.[i],
              percentage,
            },
          };
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey?.getDate()]: {
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                ...responseData?.[i],
                percentage,
              },
            },
          };
        }
        if (
          totalData?.rms?.hasOwnProperty(
            `${monthName}/${dateKey?.getFullYear()}`
          )
        ) {
          totalData.rms[`${monthName}/${dateKey?.getFullYear()}`].rms =
            totalData.rms[`${monthName}/${dateKey?.getFullYear()}`].rms +
            (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0);

          totalData.rev[`${monthName}/${dateKey?.getFullYear()}`].rev =
            totalData.rev[`${monthName}/${dateKey?.getFullYear()}`].rev +
            (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0) *
              (responseData?.[i]?.ADR ? responseData?.[i]?.ADR : 0);

          totalData.adr[`${monthName}/${dateKey?.getFullYear()}`].adr = 0;
        } else {
          totalData = {
            rms: {
              ...totalData.rms,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                rms: responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0,
              },
            },
            rev: {
              ...totalData.rev,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                rev:
                  (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0) *
                  (responseData?.[i]?.ADR ? responseData?.[i]?.ADR : 0),
              },
            },
            adr: {
              ...totalData.adr,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                adr: 0,
              },
            },
          };
        }
      }
      setMaxRoom(response?.data?.data?.maxRoom);
      setHeatMapDetail(groupingJson);
      setTotalData(totalData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setHeatMapDetail(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      let responseData = tableData;

      let groupingJson = {};
      responseData.sort(function (a, b) {
        return new Date(a.Dates) - new Date(b.Dates);
      });

      for (let i = 0; i < responseData?.length; i++) {
        let dateKey = new Date(responseData?.[i]?.Dates);
        let monthName = getMonthNameFromMonthNumber(responseData?.[i]?.Dates);
        let monthIndex = monthNameDetail.current?.findIndex(
          (x) => x === `${monthName}/${dateKey?.getFullYear()}`
        );
        let percentage = 0;
        if (responseData?.[i]?.RMS === 0) {
          percentage = 0;
        } else {
          percentage = Math.round((100 * responseData?.[i]?.RMS) / 3);
        }
        if (monthIndex < 0) {
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateKey?.getFullYear()}`,
          ];
        }
        if (groupingJson?.hasOwnProperty(dateKey?.getDate())) {
          groupingJson[dateKey.getDate()] = {
            ...groupingJson?.[dateKey?.getDate()],
            [`${monthName}/${dateKey?.getFullYear()}`]: {
              ...responseData?.[i],
              percentage,
            },
          };
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey?.getDate()]: {
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                ...responseData?.[i],
                percentage,
              },
            },
          };
        }
      }
      setHeatMapDetail(groupingJson);
    } else {
      if (para?.asOfDate) {
        let d = moment(new Date(para?.asOfDate)).toDate();
        d.setDate(d.getDate() - 1);
        pickupDateForTotalOTB.current = moment(d).format("YYYY-MM-DD");
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getHeatMapDetail();
      }
    }
  }, [
    para?.asOfDate,
    selectedType,
    selectedYear,
    selectGroupType,
    selectPickupDate,
  ]);

  const [tblids, settblids] = useState({
    tblRoomsPercentiles: commonService.GenerateGUID(),
  });

  const handleExport = async (type) => {
    let tableId = document.querySelector(".heatMapTbl");

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-pickup-${moment(new Date()).format(
        "MMDDYYYY"
      )}`,
      max: "",
    };

    let groupingTable = [
      {
        widgetname: "HeatMap",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [],
      },
    ];

    groupingTable[0].rowdata.push({
      data: [para?.rowTitle ? para?.rowTitle : ""],
    });
    groupingTable[0].rowdata.push({
      data: [],
    });

    const getTable = document.querySelector(".heatMapTbl");
    groupingJson.max = getTable?.attributes?.value?.nodeValue
      ? parseInt(getTable?.attributes?.value?.nodeValue)
      : 0;

    const findTblHeader = getTable?.querySelectorAll("thead tr");
    const findTblTbody = getTable?.querySelectorAll("tbody tr");

    findTblHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
      let allTh = tblHeaderItem.querySelectorAll("th");
      let allThValue = [];
      if (tblHeaderIndex === 2) {
        allThValue.push("");
      }
      allTh.forEach((thItem, thIndex) => {
        let thText = thItem?.innerText;
        if (tblHeaderIndex === 0) {
          let thSpan = thItem?.querySelector("span");
          thText = thSpan?.innerText ? thSpan?.innerText : "";
          if (thIndex === 2) {
            let thSpan = thItem?.querySelector("input");
            thText = thSpan?.checked ? "Pickup From" : "Total OTB";
          }
        }

        allThValue.push(thText ? thText : "");
      });

      groupingTable[0].rowdata.push({ data: allThValue });
    });

    findTblTbody.forEach((tblBodyItem) => {
      let allTd = tblBodyItem.querySelectorAll("td");
      let allTdValue = [],
        totalTdValue = [];

      allTd.forEach((tdItem, tdIndex) => {
        let tdText = tdItem?.innerText;
        if (
          allTd[0].innerText === "RMS" ||
          allTd[0].innerText === "REV" ||
          allTd[0].innerText === "ADR"
        ) {
          totalTdValue.push(tdText ? tdText : "");
          totalTdValue.push("");
        } else {
          allTdValue.push(tdText ? tdText : "");
        }
      });
      if (allTdValue?.length > 0) {
        groupingTable[0].rowdata.push({ data: allTdValue });
      }
      if (totalTdValue?.length > 0) {
        totalJson[0].rowdata.push({ data: totalTdValue });
      }
    });
    groupingJson.data = [...groupingJson.data, ...groupingTable];
    let staticTotal = [];
    totalJson?.[0]?.rowdata?.[0]?.data?.forEach((item, index) => {
      if (index === 0) {
        staticTotal.push("Total");
      } else {
        staticTotal.push("");
      }
    });
    totalJson?.[0]?.rowdata?.push({ data: staticTotal });
    groupingJson.data = [...groupingJson.data, ...totalJson];
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes("PickUp") ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.tblRoomsPercentiles,
                    "Rooms Percentiles",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.heatMap,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              import={tblids.tblRoomsPercentiles}
              alt="menuIcon"
              onClick={() => {
                if (
                  window.location.href.includes("PickUp") ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.tblRoomsPercentiles,
                    // "Rooms Percentiles"
                    `${para?.propertyCode}-Rooms Percentiles-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}

      <div className="ViewPropertySetupTable ">
        <div>
          <div className="RoomsPercentilesData">
            <p>Rooms Percentiles</p>
            <div>
              <p className="px-3 color0to19">0 - 19%</p>
            </div>
            <div>
              <p className="px-3 color20to34">20 - 34%</p>
            </div>
            <div>
              <p className="px-3 color35to49">35 - 49%</p>
            </div>
            <div>
              <p className="px-3 color50to64">50 - 64%</p>
            </div>
            <div>
              <p className="px-3 color65to74">65 - 74%</p>
            </div>
            <div>
              <p className="px-3 color75to84">75 - 84%</p>
            </div>
            <div>
              <p className="px-3 color85to89">85 - 89%</p>
            </div>
            <div>
              <p className="px-3 color90to94">90 - 94%</p>
            </div>
            <div>
              <p className="px-3 color95to100">95 - 100%</p>
            </div>
            <div>
              <p className="px-3 color100above">{`>100%`}</p>
            </div>
          </div>
        </div>
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.tblRoomsPercentiles}
              className="positionStaticTable heatMapTbl"
              value={maxRoom}
            >
              <thead className="tbl-header-sticky">
                <tr>
                  <th colSpan={2}>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setDateForApi(e.target.value);
                      }}
                    >
                      <option value="Default">Default</option>
                      {yearsArr?.map((item, index) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </th>

                  <th colSpan={2}>
                    <Form.Select
                      value={selectGroupType}
                      onChange={(e) => {
                        setSelectGroupType(e.target.value);
                      }}
                    >
                      <option value="All">All</option>
                      <option value="Transient">Transient</option>
                      <option value="Group">Group</option>
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectGroupType}</span>
                  </th>
                  <th
                    className="text-center"
                    colSpan={monthNameDetail.current?.length * 2 + 1 - 4}
                  >
                    <div className="form-groups form-groups-radio-btn d-flex justify-content-center">
                      <div className="d-flex heatmap-main align-item-center">
                        <div className="d-flex align-items-center gap-3">
                          <div className="form-radio d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="radioGroup"
                              id="Option1"
                              value="Active"
                              checked={selectedType?.isPickupFrom}
                              onChange={() => {
                                setSelectedType({
                                  isPickupFrom: true,
                                  isOnTheBook: false,
                                });
                              }}
                              // disabled={selectedType?.isPickupFrom ? false : true}
                            />
                            <label
                              htmlFor="Option1"
                              className="label-radio form-check-label m-0"
                            >
                              7 Day Pickup From
                            </label>
                          </div>
                          <div>
                            <DatePicker
                              id="asOfDate"
                              disabled={
                                selectedType?.isPickupFrom ? false : true
                              }
                              maxDate={new Date(maxDateRef.current)}
                              selected={
                                selectPickupDate
                                  ? commonService.convertDateInTimezone(
                                      selectPickupDate
                                    )
                                  : defaultPickupDateRef.current
                              }
                              onChange={(date) => {
                                let month =
                                  date.getMonth() + 1 > 9
                                    ? date.getMonth() + 1
                                    : `0${date.getMonth() + 1}`;
                                let dateFormat =
                                  date?.getDate() > 9
                                    ? date.getDate()
                                    : `0${date.getDate()}`;
                                let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                                setSelectPickupDate(selectedDate);
                              }}
                            />
                            <span style={{ display: "none" }}>
                              {selectedType?.isPickupFrom
                                ? selectPickupDate
                                  ? commonService.getDateInFormat(
                                      new Date(selectPickupDate)
                                    )
                                  : commonService.getDateInFormat(
                                      new Date(defaultPickupDateRef.current)
                                    )
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-radio d-flex align-items-center">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="radioGroup"
                            id="Option2"
                            value="Inactive"
                            checked={selectedType?.isOnTheBook}
                            onChange={() => {
                              setSelectedType({
                                isPickupFrom: false,
                                isOnTheBook: true,
                              });
                            }}
                            // disabled={selectedType?.isOnTheBook ? false : true}
                          />
                          <label
                            htmlFor="Option2"
                            className="label-radio form-check-label m-0"
                          >
                            Total OTB
                          </label>
                        </div>
                        <span style={{ display: "none" }}>
                          {selectedType?.isOnTheBook
                            ? "Total OTB"
                            : "Pickup From"}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th rowSpan={2}>Date</th>
                  {monthNameDetail.current?.map((item, index) => (
                    <th key={index} colSpan={2}>
                      {item}
                    </th>
                  ))}
                </tr>
                <tr>
                  {monthNameDetail.current?.map((item, index) => (
                    <React.Fragment key={index}>
                      <th>RMS</th>
                      <th>ADR</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : heatMapDetail ? (
                  <>
                    {Object?.entries(heatMapDetail)?.map(
                      ([key, value], rowIndex) => {
                        // let classname = "orangeColor";
                        return (
                          <tr key={key}>
                            <td>{key}</td>
                            {monthNameDetail.current?.map((item, index) => {
                              let className = "color0to19";
                              let dayName = false;
                              let monthYearSplit = item?.split("/");

                              const year = monthYearSplit[1];
                              const month =
                                monthNumberFromName[monthYearSplit[0]] - 1;
                              const day = key;

                              const selectedDate = new Date(year, month, day);

                              let currentDate = moment(
                                commonService.convertDateInTimezone(
                                  selectedDate
                                  // `${monthYearSplit[1]}-${
                                  //   monthNumberFromName[monthYearSplit[0]]
                                  // }-${key}`
                                )
                              ).toDate();
                              if (
                                moment(new Date(currentDate), "l").format(
                                  "ddd"
                                ) === "Fri" ||
                                moment(new Date(currentDate), "l").format(
                                  "ddd"
                                ) === "Sat"
                              ) {
                                dayName = true;
                              }
                              let checkEvent = eventListRef.current?.filter(
                                (x) => {
                                  let checkingDate = moment(currentDate);
                                  let startingDate = moment(x?.startDate);
                                  let endingDate = moment(x?.endDate);
                                  if (
                                    checkingDate.isSameOrAfter(startingDate) &&
                                    checkingDate.isSameOrBefore(endingDate)
                                  ) {
                                    return x;
                                  }
                                }
                              );
                              if (
                                value?.[item]?.percentage >= 0 &&
                                value?.[item]?.percentage <= 19
                              ) {
                                className = "color0to19";
                              } else if (
                                value?.[item]?.percentage >= 20 &&
                                value?.[item]?.percentage <= 34
                              ) {
                                className = "color20to34";
                              } else if (
                                value?.[item]?.percentage >= 35 &&
                                value?.[item]?.percentage <= 49
                              ) {
                                className = "color35to49";
                              } else if (
                                value?.[item]?.percentage >= 50 &&
                                value?.[item]?.percentage <= 64
                              ) {
                                className = "color50to64";
                              } else if (
                                value?.[item]?.percentage >= 65 &&
                                value?.[item]?.percentage <= 74
                              ) {
                                className = "color65to74";
                              } else if (
                                value?.[item]?.percentage >= 75 &&
                                value?.[item]?.percentage <= 84
                              ) {
                                className = "color75to84";
                              } else if (
                                value?.[item]?.percentage >= 85 &&
                                value?.[item]?.percentage <= 89
                              ) {
                                className = "color85to89";
                              } else if (
                                value?.[item]?.percentage >= 90 &&
                                value?.[item]?.percentage <= 94
                              ) {
                                className = "color90to94";
                              } else if (
                                value?.[item]?.percentage >= 95 &&
                                value?.[item]?.percentage <= 100
                              ) {
                                className = "color95to100";
                              } else if (value?.[item]?.percentage > 100) {
                                className = "color100above";
                              }
                              return (
                                <React.Fragment key={index}>
                                  <td
                                    className={`${className} ${
                                      dayName ? "borderTd" : ""
                                    }`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      style={{ display: "flex", gap: "5px" }}
                                    >
                                      <p
                                        onClick={(e) => {
                                          singleDateSelectedDateRef.current =
                                            moment(
                                              new Date(currentDate)
                                            ).format("YYYY-MM-DD");
                                          handleFilterBtnClick(e);
                                          selectedTdIndexRef.current = rowIndex;
                                        }}
                                      >
                                        {commonService.formateRoom(
                                          value?.[item]?.RMS
                                        )}
                                      </p>
                                      {checkEvent?.length > 0 && (
                                        <img
                                          style={{
                                            height: "11px",
                                            width: "11px",
                                          }}
                                          className="iconcolor"
                                          src={ticket}
                                          alt="ticket"
                                          onClick={() => {
                                            selectedEventDateRef.current =
                                              moment(
                                                new Date(currentDate)
                                              ).format("YYYY-MM-DD");
                                            eventListPropsRef.current =
                                              checkEvent;
                                            setShowTicketModal(true);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    className={`${className} ${
                                      dayName ? "borderTdLeft" : ""
                                    } `}
                                  >
                                    {value?.[item]?.ADR
                                      ? `${commonService.formateAmount(
                                          value?.[item]?.ADR
                                        )}`
                                      : ""}
                                  </td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}

                    {totalData &&
                      Object?.entries(totalData)?.map(([key, value]) => (
                        <tr>
                          <td>{key?.toUpperCase()}</td>
                          {monthNameDetail.current?.map((item, index) => (
                            <React.Fragment key={index}>
                              <td colSpan={2}>
                                {key === "adr"
                                  ? totalData?.rev?.[item]?.rev > 0 &&
                                    totalData?.rms?.[item]?.rms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          totalData?.rev?.[item]?.rev /
                                            totalData?.rms?.[item]?.rms,
                                          2
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`
                                  : key === "rev"
                                  ? `${
                                      value?.[item]?.[key]
                                        ? commonService.formateAmount(
                                            value?.[item]?.[key]
                                          )
                                        : commonService.formateAmount(0)
                                    }`
                                  : value?.[item]?.[key]
                                  ? value?.[item]?.[key]
                                  : 0}
                              </td>
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>No Data Found</tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"HeatMap"} />}

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain width500"
        >
          <div className="horizontal-menu-option active">
            {/* <OtbModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectedType?.isPickupFrom
                  ? selectPickupDate
                  : pickupDateForTotalOTB.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
              fromHeatMap={true}
            /> */}
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectedType?.isPickupFrom
                  ? selectPickupDate
                    ? selectPickupDate
                    : defaultPickupDateRef.current
                  : defaultPickupDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
    </div>
  );
};

export default HeatMap;
