import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import { Chart } from "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Button, Form, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { errorToastMessage } from "../../../../utils/toastMessage";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ReservationAnalyticsChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(false);
  const maxRateRef = useRef();

  const date = new Date();
  // const startDate = commonService.getDateInDBFormat(
  //     new Date(date.getFullYear(), date.getMonth(), 1)
  // );
  const startDate = commonService.getDateInDBFormat(
    date.setDate(date.getDate() - 6)
  );
  const endDate = commonService.getDateInDBFormat(new Date());

  const [stayStartDate, setStayStartDate] = useState(new Date(startDate));
  const [stayEndDate, setStayEndDate] = useState(new Date(endDate));
  const [analyticData, setAnalyticData] = useState(null);
  const lineChartDataRef = useRef([]);
  const [filter, setFilter] = useState({
    occ: 40,
    rate: 60,
  });

  const [chart_y_axis_value, setchart_y_axis_value] = useState(100);
  const [chart_x_axis_value, setchart_x_axis_value] = useState(200);
  const maxYAxisRef = useRef();
  const maxRateAxisRef = useRef();
  const [lineChartData, setLineChartData] = useState([]);

  const getAnalyticsChartData = async () => {
    setLoading(true);
    let chartDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.RESERVATION_ANAYLTICS_CHART_NEW,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(stayStartDate),
          endDate: commonService.getDateForNode(stayEndDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJsonForChartDataSet = [],
        max = 0,
        maxYAxis = 0,
        maxRateAxis = 0;

      dataFromApi?.marketsegment_wise?.forEach((item) => {
        let obj = {},
          data = [];
        item.id = commonService.GenerateGUID();
        obj.type = "bubble";
        obj.label = item?.MarketSegment;
        obj.backgroundColor = commonService.CreateColor(
          item?.MarketSegment
        )?.BGColor;

        //data.push({
        //  x: item?.Rate,
        //  y: item?.Occperc,
        //  r:
        //    item?.OccpercByMarketSegmentByTotalOccupancy * 2 < 5
        //      ? 8
        //      : item?.OccpercByMarketSegmentByTotalOccupancy * 2,
        //  d: item?.MarketSegment,
        //  id: item.id,
        //});
        max =
          max < (item?.Rate ? parseFloat(item?.Rate) : 0)
            ? max
            : parseFloat(item?.Rate);
        let findMarketData = dataFromApi?.datewise?.filter(
          (x) => x?.MarketSegment === item?.MarketSegment
        );
        findMarketData?.forEach((dateWiseItem) => {
          dateWiseItem.id = commonService.GenerateGUID();
          maxYAxis =
            maxYAxis <
            (dateWiseItem?.staydateoccper
              ? parseFloat(dateWiseItem?.staydateoccper)
              : 0)
              ? parseFloat(dateWiseItem?.staydateoccper)
              : maxYAxis;

          maxRateAxis =
            maxRateAxis <
            (dateWiseItem?.Rate ? parseFloat(dateWiseItem?.Rate) : 0)
              ? parseFloat(dateWiseItem?.Rate)
              : maxRateAxis;
          data.push({
            x: dateWiseItem?.Rate ? parseFloat(dateWiseItem?.Rate) : 0,
            y: dateWiseItem?.staydateoccper
              ? parseFloat(dateWiseItem?.staydateoccper)
              : 0,
            //r: dateWiseItem?.OccpercByMarketSegmentByTotalOccupancy/1.5,
            r: 4,
            d: commonService.getDateInFormat(dateWiseItem.StayDate),
            bd: commonService.getDateInFormat(dateWiseItem.BookingDate),
            id: dateWiseItem.id,
          });
          max =
            max < (item?.Rate ? parseFloat(item?.Rate) : 0)
              ? max
              : parseFloat(item?.Rate);
        });
        if (maxYAxis > chart_y_axis_value) setchart_y_axis_value(maxYAxis);

        if (maxRateAxis > chart_x_axis_value)
          setchart_x_axis_value(maxRateAxis);

        obj.data = data;
        groupingJsonForChartDataSet.push(obj);
      });
      maxYAxisRef.current = maxYAxis;
      maxRateAxisRef.current = maxRateAxis;
      // let firstLineChartData = {
      //     type: "line",
      //     label: "",
      //     data: [],
      //     borderColor: "rgb(210, 210, 210)",
      //     backgroundColor: "rgb(210, 210, 210)",
      //     fill: false,
      //     borderWidth: 3,
      //     pointRadius: 0,
      //     pointHoverRadius: 0,
      // },
      //     secondLineChartData = {
      //         type: "line",
      //         label: "",
      //         data: [],
      //         borderColor: "rgb(210, 210, 210)",
      //         backgroundColor: "rgb(210, 210, 210)",
      //         fill: false,
      //         borderWidth: 3,
      //         pointRadius: 0,
      //         pointHoverRadius: 0,
      //     };
      // if (maxYAxis <= 100) {
      //     firstLineChartData = {
      //         type: "line",
      //         label: "",
      //         data: [
      //             { x: 0, y: 40 },
      //             { x: 10, y: 50 },
      //             { x: 20, y: 60 },
      //             { x: 30, y: 70 },
      //             { x: 40, y: 80 },
      //             { x: 50, y: 90 },
      //             { x: 60, y: 100 },
      //         ],
      //         borderColor: "rgb(210, 210, 210)",
      //         backgroundColor: "rgb(210, 210, 210)",
      //         fill: false,
      //         borderWidth: 3,
      //         pointRadius: 0,
      //         pointHoverRadius: 0,
      //     };
      //     secondLineChartData = {
      //         type: "line",
      //         label: "",
      //         data: [
      //             { x: 60, y: 0 },
      //             { x: 70, y: 10 },
      //             { x: 80, y: 20 },
      //             { x: 90, y: 30 },
      //             { x: 100, y: 40 },
      //             { x: 110, y: 50 },
      //             { x: 120, y: 60 },
      //             { x: 130, y: 70 },
      //             { x: 140, y: 80 },
      //             { x: 150, y: 90 },
      //             { x: 160, y: 100 },
      //         ],
      //         borderColor: "rgb(210, 210, 210)",
      //         backgroundColor: "rgb(210, 210, 210)",
      //         fill: false,
      //         borderWidth: 3,
      //         pointRadius: 0,
      //         pointHoverRadius: 0,
      //     };
      //     occArrRef.current = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      // } else {
      //     let occArr = [];
      //     let firstLine = 40;
      //     for (let i = 0; firstLine < maxYAxis; i++) {
      //         firstLineChartData.data.push({ x: 10 * i, y: firstLine });
      //         firstLine = firstLine + 10;
      //     }

      //     firstLine = firstLine - 10;
      //     if (maxYAxis > firstLine) {
      //         firstLineChartData.data.push({
      //             x:
      //                 firstLineChartData.data[firstLineChartData.data?.length - 1].x +
      //                 10,
      //             y: firstLine + 10,
      //         });
      //     }

      //     let secondLine = 0;
      //     for (let i = 0; secondLine < maxYAxis; i++) {
      //         secondLineChartData.data.push({ x: 10 * i + 60, y: secondLine });
      //         occArr.push(secondLine);
      //         secondLine = secondLine + 10;
      //     }

      //     secondLine = secondLine - 10;
      //     if (maxYAxis > secondLine) {
      //         secondLineChartData.data.push({
      //             x:
      //                 secondLineChartData.data[secondLineChartData.data?.length - 1].x +
      //                 10,
      //             y: secondLine + 10,
      //         });
      //         occArr.push(secondLine + 10);
      //     }

      //     occArrRef.current = occArr;
      // }

      // if (maxRateAxis <= 200) {
      //     rateArrRef.current = [
      //         0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
      //         160, 170, 180, 190, 200,
      //     ];
      // } else {
      //     let rateAxisData = 0,
      //         rateArr = [];
      //     for (let i = 0; rateAxisData < maxRateAxis; i++) {
      //         rateArr.push(10 * i);
      //         rateAxisData = rateAxisData + 10;
      //     }

      //     rateAxisData = rateAxisData - 10;
      //     if (maxRateAxis > rateAxisData) {
      //         rateArr.push(rateArr[rateArr?.length - 1] + 10);
      //     }
      //     rateArrRef.current = rateArr;
      // }
      // lineChartDataRef.current = [firstLineChartData, secondLineChartData];
      maxRateRef.current = max;
      setAnalyticData(dataFromApi);
      setChartData(groupingJsonForChartDataSet);
      setLoading(false);
    }

    if (!response?.isValidate) {
      lineChartDataRef.current = [];
      setAnalyticData(null);
      setLoading(false);
      setChartData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getAnalyticsChartData();
      }
    }
  }, [para?.asOfDate]);

  const quadrants = {
    id: "quadrants",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.font = "bold 86px Arial";
      ctx.fillStyle = "rgb(227, 225, 225)";
      //ctx.fillStyle = "rgb(200, 200, 200)";
      ctx.fillText(
        "S",
        left + (chart.width * filter.rate) / chart_x_axis_value / 2.5,
        top +
          (chart.height * (chart_y_axis_value - filter.occ)) /
            chart_y_axis_value /
            2.5
      );

      ctx.fillText(
        "O",
        right -
          (chart.width * (chart_x_axis_value - filter.rate)) /
            chart_x_axis_value /
            2.5,
        top +
          (chart.height * (chart_y_axis_value - filter.occ)) /
            chart_y_axis_value /
            2.5
      );

      ctx.fillText(
        "W",
        left + (chart.width * filter.rate) / chart_x_axis_value / 2.5,
        bottom - (chart.height * filter.occ) / chart_y_axis_value / 2.5
      );

      ctx.fillText(
        "T",
        right -
          (chart.width * (chart_x_axis_value - filter.rate)) /
            chart_x_axis_value /
            2.5,
        bottom - (chart.height * filter.occ) / chart_y_axis_value / 2.5
      );

      ctx.restore();
    },
  };

  const drawCrossLine = async () => {
    let firstLineChartData = {
        type: "line",
        label: "",
        data: [],
        borderColor: "rgb(210, 210, 210)",
        backgroundColor: "rgb(210, 210, 210)",
        fill: false,
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      secondLineChartData = {
        type: "line",
        label: "",
        data: [],
        borderColor: "rgb(210, 210, 210)",
        backgroundColor: "rgb(210, 210, 210)",
        fill: false,
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 0,
      };
    if (maxYAxisRef.current <= 100) {
      let newObj = { ...firstLineChartData };
      let occValue = filter?.occ;
      for (let i = 0; occValue <= 100; i++) {
        newObj.data.push({ x: 10 * i, y: occValue });
        occValue = occValue + 10;
      }

      occArrRef.current = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      firstLineChartData = { ...newObj };
    } else {
      let occArr = [],
        occValue = 0;
      for (let i = 0; occValue < maxYAxisRef.current; i++) {
        occArr.push(occValue);
        occValue = occValue + 10;
      }

      occValue = occValue - 10;
      if (maxYAxisRef.current > occValue) {
        occArr.push(occValue + 10);
      }
      occArrRef.current = occArr;

      let occFilterValue = filter?.occ;
      let newObj = { ...firstLineChartData };
      for (let i = 0; occFilterValue < maxYAxisRef.current; i++) {
        newObj.data.push({ x: 10 * i, y: occFilterValue });
        occFilterValue = occFilterValue + 10;
      }

      occFilterValue = occFilterValue - 10;
      if (maxYAxisRef.current > occFilterValue) {
        newObj.data.push({
          x: newObj.data[newObj.data?.length - 1]?.x + 10,
          y: occFilterValue + 10,
        });
      }
      firstLineChartData = { ...newObj };
    }

    if (maxRateAxisRef.current <= 200) {
      rateArrRef.current = [
        0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
        160, 170, 180, 190, 200,
      ];
      let rateValue = filter?.rate;
      let newObj = { ...secondLineChartData };
      let findRemainingPoint = rateArrRef.current?.filter(
        (x) => x > filter?.rate
      );
      let findGap = Math.round(
        firstLineChartData?.data[firstLineChartData?.data?.length - 1]?.y /
          findRemainingPoint?.length
      );
      let gapValue = findGap;
      for (let i = 0; rateValue <= 200; i++) {
        if (i === 0) {
          newObj.data.push({ x: rateValue, y: 0 });
        } else {
          if (
            firstLineChartData?.data[firstLineChartData?.data?.length - 1]?.y >=
            gapValue
          ) {
            newObj.data.push({ x: rateValue, y: gapValue });
          } else {
            newObj.data.push({
              x: rateValue,
              y: firstLineChartData?.data[firstLineChartData?.data?.length - 1]
                ?.y,
            });
          }

          gapValue = gapValue + findGap;
        }
        rateValue = rateValue + 10;
      }
      secondLineChartData = { ...newObj };
    } else {
      let rateAxisData = 0,
        rateArr = [];
      for (let i = 0; rateAxisData < maxRateAxisRef.current; i++) {
        rateArr.push(10 * i);
        rateAxisData = rateAxisData + 10;
      }

      rateAxisData = rateAxisData - 10;
      if (maxRateAxisRef.current > rateAxisData) {
        rateArr.push(rateArr[rateArr?.length - 1] + 10);
      }
      rateArrRef.current = rateArr;

      let rateValue = filter?.rate;
      let newObj = { ...secondLineChartData };
      let findRemainingPoint = rateArrRef.current?.filter(
        (x) => x > filter?.rate
      );
      let findGap = Math.round(
        firstLineChartData?.data[firstLineChartData?.data?.length - 1]?.y /
          findRemainingPoint?.length
      );
      let gapValue = findGap;
      for (
        let i = 0;
        rateValue <= rateArrRef.current[rateArrRef.current?.length - 1];
        i++
      ) {
        if (i === 0) {
          newObj.data.push({ x: rateValue, y: 0 });
        } else {
          if (
            firstLineChartData?.data[firstLineChartData?.data?.length - 1]?.y >=
            gapValue
          ) {
            newObj.data.push({ x: rateValue, y: gapValue });
          } else {
            newObj.data.push({
              x: rateValue,
              y: firstLineChartData?.data[firstLineChartData?.data?.length - 1]
                ?.y,
            });
          }

          gapValue = gapValue + findGap;
        }
        rateValue = rateValue + 10;
      }
      secondLineChartData = { ...newObj };
    }

    lineChartDataRef.current = [firstLineChartData, secondLineChartData];
    setLineChartData(lineChartDataRef.current);
  };

  useEffect(() => {
    if (chartData && lineChartData?.length > 0) {
      var ctx = document?.getElementById("marketSegmentWiseReservation");
      var myChart = new Chart(ctx, {
        type: "bubble",
        data: {
          datasets: [...chartData, ...lineChartDataRef.current],
        },
        plugins: [quadrants],
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: "RATE",
              },
              border: {
                display: false,
              },
              grid: {
                color: function (context) {
                  if (context.tick.value === filter.rate) {
                    return "#000000";
                  } else {
                    return "";
                  }
                },
              },
              suggestedMin: 0,
              suggestedMax: 200, //Math.round(maxRateRef.current),
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                callback: function (value) {
                  // return `$${value}`;
                  return `${
                    propertyDetailFromRTK?.currency_term
                      ? propertyDetailFromRTK?.currency_term
                      : "$"
                  }${value}`;
                },
              },
            },
            y: {
              title: {
                display: true,
                text: "OCCUPANCY %",
              },

              border: {
                display: false,
              },
              grid: {
                color: function (context) {
                  if (context.tick.value === filter.occ) {
                    return "#000000";
                  } else {
                    return "";
                  }
                },
              },
              suggestedMin: 0,
              suggestedMax: 100,
              ticks: {
                stepSize: 10,
                callback: function (value) {
                  return `${value}%`;
                },
              },
            },
          },
          onClick: function (e) {
            var element = myChart.getElementsAtEventForMode(
              e,
              "nearest",
              { intersect: true },
              true
            );
            if (element.length > 0) {
              myChart.data.datasets.forEach((dataset, index) => {
                if (
                  index !== element[0].datasetIndex &&
                  dataset.type !== "line"
                ) {
                  dataset.hidden = true;
                  let list = document.querySelectorAll(
                    '[data-ms="' + dataset.label + '"]'
                  );
                  if (
                    list !== null &&
                    list !== undefined &&
                    list.length !== 0
                  ) {
                    for (let i = 0; i < list.length; i++) {
                      list[i].style.display = "none";
                    }
                  }
                } else {
                  dataset.hidden = false;
                  let list = document.querySelectorAll(
                    '[data-ms="' + dataset.label + '"]'
                  );
                  if (
                    list !== null &&
                    list !== undefined &&
                    list.length !== 0
                  ) {
                    for (let i = 0; i < list.length; i++) {
                      list[i].style.display = "";
                    }
                  }
                }
              });
              myChart.update();
            } else {
              myChart.data.datasets.forEach((dataset) => {
                dataset.hidden = false;
              });
              myChart.update();
              let list = document.querySelectorAll("[data-ms]");
              if (list !== null && list !== undefined && list.length !== 0) {
                for (let i = 0; i < list.length; i++) {
                  list[i].style.display = "";
                }
              }
            }
          },
          onHover: function (e) {
            let list = document.getElementsByClassName(
              "bubblechart_highlight_data"
            );
            if (list !== null && list !== undefined && list.length !== 0) {
              for (let i = 0; i < list.length; i++) {
                list[i].classList.remove("bubblechart_highlight_data");
              }
            }
            var element = myChart.getElementsAtEventForMode(
              e,
              "nearest",
              { intersect: true },
              true
            );
            if (element.length > 0) {
              let data =
                myChart.data.datasets[element[0].datasetIndex].data[
                  element[0].index
                ];
              if (data?.id) {
                document
                  .getElementById(data?.id)
                  .classList.add("bubblechart_highlight_data");
              }
            }
          },
          responsive: true,

          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  var occValue = context.raw.y;
                  var rateValue = context.raw.x;
                  var radiousValue = context.raw.r;
                  var bookindDate = context.raw.bd;

                  var dates = context.raw.d;
                  return `Occupancy: ${occValue}% on at booking date ${bookindDate} | Rate: ${
                    propertyDetailFromRTK?.currency_term
                      ? propertyDetailFromRTK?.currency_term
                      : "$"
                  }${rateValue} For ${dates}`;
                },
              },
            },
            legend: {
              onClick: null,
              position: "bottom",
            },
          },
        },
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [chartData, filter, lineChartData]);

  useEffect(() => {
    if (chartData) {
      drawCrossLine();
    }
  }, [filter, chartData]);

  const handleStayDateChange = (dates) => {
    const [start, end] = dates;
    setStayStartDate(start);
    setStayEndDate(end ? end : "");
  };

  const [tblids, settblids] = useState({
    reservationanalytics: commonService.GenerateGUID(),
  });

  const occArrRef = useRef();
  const rateArrRef = useRef();

  //   const occArr = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  //   const rateArr = [
  //     0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
  //     170, 180, 190, 200,
  //   ];

  const handleSearch = () => {
    let rateCodeSearchInput = document.getElementById("tblRateCode");
    let companySearchInput = document.getElementById("tblCompany");
    let guestNameSearchInput = document.getElementById("tblGuestName");
    let confirmationNumberSearchInput = document.getElementById(
      "tblConfirmationNumber"
    );
    let roomTypeSearchInput = document.getElementById("tblRoomType");
    let channelSearchInput = document.getElementById("tblChannel");
    let sourceSearchInput = document.getElementById("tblSource");

    let table = document.getElementById(`${tblids.reservationanalytics}`);
    let tr = table?.getElementsByTagName("tr");

    for (let i = 0; i < tr?.length; i++) {
      if (tr[i]?.classList.contains("swotAnalysisMainTr")) {
        tr[i].style.display = "";
      } else {
        let td = tr[i]?.getElementsByTagName("td");

        if (td?.length > 0) {
          let rateCodeTxtValue = td?.[1]?.innerText || "";
          let companyTxtValue = td?.[2]?.innerText || "";
          let guestNameTxtValue = td?.[3]?.innerText || "";
          let confirmationNumberTxtValue = td?.[4]?.innerText || "";
          let roomTypeTxtValue = td?.[8]?.innerText || "";
          let channelTxtValue = td?.[9]?.innerText || "";
          let sourceTxtValue = td?.[10]?.innerText || "";

          if (
            rateCodeTxtValue
              .toLowerCase()
              .includes(rateCodeSearchInput?.value?.toLowerCase()) &&
            companyTxtValue
              .toLowerCase()
              .includes(companySearchInput?.value?.toLowerCase()) &&
            guestNameTxtValue
              .toLowerCase()
              .includes(guestNameSearchInput?.value?.toLowerCase()) &&
            confirmationNumberTxtValue
              .toLowerCase()
              .includes(confirmationNumberSearchInput?.value?.toLowerCase()) &&
            roomTypeTxtValue
              .toLowerCase()
              .includes(roomTypeSearchInput?.value?.toLowerCase()) &&
            channelTxtValue
              .toLowerCase()
              .includes(channelSearchInput?.value?.toLowerCase()) &&
            sourceTxtValue
              .toLowerCase()
              .includes(sourceSearchInput?.value?.toLowerCase())
          ) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }
  };

  return (
    <div>
      <Row>
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="form-groups from-groups no-border-break meghaDatePicker width225">
            <Form.Label>Stay Date</Form.Label>
            <DatePicker
              onChange={handleStayDateChange}
              startDate={stayStartDate}
              endDate={stayEndDate}
              selectsRange
              monthsShown={2}
            />
          </div>
          <div className="marign-condition-btn">
            <button
              className="default-btn primary-btn"
              onClick={() => {
                if (!stayStartDate || !stayEndDate) {
                  return errorToastMessage("Please select start & end date");
                }
                getAnalyticsChartData();
              }}
            >
              Search
            </button>
          </div>
          <div className="from-groups no-border-break width75">
            <Form.Label>OCC %</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={filter.occ}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  occ: parseInt(e.target.value),
                });
              }}
            >
              {occArrRef.current?.map((item, index) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="from-groups no-border-break width75">
            <Form.Label>Rate</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={filter.rate}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  rate: parseInt(e.target.value),
                });
              }}
            >
              {rateArrRef.current?.map((item, index) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </Row>
      {loading ? (
        <Loader />
      ) : chartData ? (
        <canvas id="marketSegmentWiseReservation"></canvas>
      ) : (
        <div>
          <p>No Data Found</p>
        </div>
      )}

      <div class="col-xl-12 mt-3">
        <div class="generalTermSetup-heading">
          <h2>Market Segment Wise</h2>
        </div>
      </div>
      <div className="ViewPropertySetupTable ">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table className="table-with-spacing positionStaticTable dailySummaryTbl">
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right">Market Segment</th>
                  <th className="border_right">Occ%</th>
                  <th className="border_right">Weighted Occ%</th>
                  <th className="border_right">RMS</th>
                  <th className="border_right">ADR</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : analyticData ? (
                  analyticData?.marketsegment_wise?.map((item) => {
                    return (
                      <>
                        <tr
                          data-ms={item?.MarketSegment}
                          id={item?.id}
                          className="shadowOnHover"
                        >
                          <td className="border_right">
                            {item?.MarketSegment}
                          </td>
                          <td className="border_right width100">
                            {Math.round(item?.Occperc ? item?.Occperc : 0)}%
                          </td>
                          <td className="border_right width100">
                            {item?.OccpercByMarketSegmentByTotalOccupancy
                              ? item?.OccpercByMarketSegmentByTotalOccupancy
                              : 0}
                            %
                          </td>
                          <td className="border_right width100">
                            {Math.round(
                              item?.TotalBooking ? item?.TotalBooking : 0
                            )}
                          </td>
                          <td className="border_right  text-end width100">
                            {commonService.formateAmount(
                              Math.round(item?.Rate ? item?.Rate : 0)
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-3">
        <div class="generalTermSetup-heading">
          <h2>Date Wise</h2>
        </div>
      </div>
      <div className="ViewPropertySetupTable mt-3">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.reservationanalytics}
              className="table-with-spacing positionStaticTable dailySummaryTbl"
            >
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right">Booking Date</th>
                  <th className="border_right">Rate Code</th>
                  <th className="border_right">Company</th>
                  <th className="border_right">Guest Name</th>
                  <th className="border_right">CONFIRMATION NUMBER</th>
                  <th className="border_right">ARRIVAL DATE</th>
                  <th className="border_right">Departure Date</th>
                  <th className="border_right">LOS</th>
                  <th className="border_right">Room Type</th>
                  <th className="border_right">Channel</th>
                  <th className="border_right">Source/User</th>
                  <th className="border_right">Stay Date</th>
                  <th className="border_right">Occ%</th>
                  <th className="border_right text-end">Rate</th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblRateCode"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblCompany"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblGuestName"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblConfirmationNumber"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblRoomType"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblChannel"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div class="search-group-for-table input-group">
                      <input
                        aria-label="Search"
                        class="border-0 form-control"
                        id="tblSource"
                        onChange={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : analyticData ? (
                  analyticData?.marketsegment_wise?.map((item) => {
                    let marketSegmentData = analyticData?.datewise?.filter(
                      (x) => x?.MarketSegment === item?.MarketSegment
                    );
                    return (
                      <>
                        <tr
                          data-ms={item?.MarketSegment}
                          id={item?.id}
                          className="shadowOnHover swotAnalysisMainTr"
                        >
                          <td
                            className="border_right swotAnalysis"
                            style={{ backgroundColor: "#f0f8ff" }}
                            colSpan={14}
                          >
                            <b>{item?.MarketSegment}</b>
                          </td>
                        </tr>
                        {marketSegmentData?.map((dateWiseMsItem) => {
                          return (
                            <tr
                              data-ms={item?.MarketSegment}
                              id={dateWiseMsItem?.id}
                              className="shadowOnHover"
                            >
                              <td className="border_right">
                                {dateWiseMsItem?.BookingDate
                                  ? commonService.getDateInFormat(
                                      dateWiseMsItem?.BookingDate
                                    )
                                  : ""}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.RateCode}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.Company}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.GuestName}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.Account}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.ArrivalDate
                                  ? commonService.getDateInFormat(
                                      dateWiseMsItem?.ArrivalDate
                                    )
                                  : ""}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.DepartureDate
                                  ? commonService.getDateInFormat(
                                      dateWiseMsItem?.DepartureDate
                                    )
                                  : ""}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.LOS}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.RoomType}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.CombinedChannel}
                              </td>
                              <td className="border_right">
                                {dateWiseMsItem?.User}
                              </td>

                              <td className="border_right">
                                {commonService.getDateInFormat(
                                  dateWiseMsItem?.StayDate
                                )}
                              </td>
                              <td className="border_right">
                                {Math.round(
                                  dateWiseMsItem?.staydateoccper
                                    ? dateWiseMsItem?.staydateoccper
                                    : 0
                                )}
                                %
                              </td>
                              {/*<td className="border_right">*/}
                              {/*    {Math.round(*/}
                              {/*        dateWiseMsItem?.OccpercByMarketSegmentByTotalOccupancy*/}
                              {/*            ? dateWiseMsItem?.OccpercByMarketSegmentByTotalOccupancy*/}
                              {/*            : 0*/}
                              {/*    )}*/}
                              {/*    %*/}
                              {/*</td>*/}
                              {/*<td className="border_right">*/}
                              {/*    {dateWiseMsItem?.TotalBooking}*/}
                              {/*</td>*/}
                              <td className="border_right text-end">
                                {commonService.formateAmount(
                                  Math.round(
                                    dateWiseMsItem?.Rate
                                      ? dateWiseMsItem?.Rate
                                      : 0
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationAnalyticsChart;
