import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Table } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";
import { errorToastMessage } from "../../../../utils/toastMessage";

const PickupCommon = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const OTBTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const OneDayTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const OneDayChangeTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const SevenDayTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const SevenDayChangeTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [OTBData, setOTBData] = useState(null);
  const [oneDayData, setOneDayData] = useState(null);
  const [oneDayForecastChangeData, setOneDayForecastChangeData] =
    useState(null);
  const [sevenDayData, setSevenDayData] = useState(null);
  const [sevenDayForecastChangeData, setSevenDayForecastChangeData] =
    useState(null);

  const [otbLoading, setOtbLoading] = useState(false);
  const [oneDayLoading, setOneDayLoading] = useState(false);
  const [oneDayChangeLoading, setOneDayChangeLoading] = useState(false);
  const [sevenDayLoading, setSevenDayLoading] = useState(false);
  const [sevenDayChangeLoading, setSevenDayChangeLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let otbocc = 0,
    otbrms = 0,
    otbadr = 0,
    otbrev = 0,
    oneDayOcc = 0,
    oneDayRms = 0,
    oneDayAdr = 0,
    oneDayRev = 0,
    oneDayChangeOcc = 0,
    oneDayChangeRms = 0,
    oneDayChangeAdr = 0,
    oneDayChangeRev = 0,
    sevenDayOcc = 0,
    sevenDayRms = 0,
    sevenDayAdr = 0,
    sevenDayRev = 0,
    sevenDayChangeOcc = 0,
    sevenDayChangeRms = 0,
    sevenDayChangeAdr = 0,
    sevenDayChangeRev = 0;

  const getOnTheBookDetail = async () => {
    setOtbLoading(true);
    let onTheBookResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.ON_THE_BOOK,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, onTheBookResponse);
    if (response?.isValidate) {
      setOTBData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOTBData(OTBTableData);
      setOtbLoading(false);
    }
  };
  const getOneDayDetail = async () => {
    setOneDayLoading(true);
    let oneDayResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.ONE_DAY,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, oneDayResponse);

    if (response?.isValidate) {
      setOneDayData(response?.data?.data);
      setOneDayLoading(false);
    }

    if (!response?.isValidate) {
      setOneDayData(OneDayTableData);
      setOneDayLoading(false);
    }
  };
  const getOneDayForecastChangeDetail = async () => {
    setOneDayChangeLoading(true);
    let oneDayForecastChangeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.ONE_DAY_FORECAST_CHANGE,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, oneDayForecastChangeResponse);

    if (response?.isValidate) {
      setOneDayForecastChangeData(response?.data?.data);
      setOneDayChangeLoading(false);
    }

    if (!response?.isValidate) {
      setOneDayForecastChangeData(OneDayChangeTableData);
      setOneDayChangeLoading(false);
    }
  };
  const getSevenDayDetail = async () => {
    setSevenDayLoading(true);
    let sevenDayResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.SEVEN_DAY,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, sevenDayResponse);

    if (response?.isValidate) {
      setSevenDayData(response?.data?.data);
      setSevenDayLoading(false);
    }

    if (!response?.isValidate) {
      setSevenDayData(SevenDayTableData);
      setSevenDayLoading(false);
    }
  };
  const getSevenDayForecastChangeDetail = async () => {
    setSevenDayChangeLoading(true);
    let sevenDayForecastChangeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.SEVEN_DAY_FORECAST_CHANGE,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, sevenDayForecastChangeResponse);

    if (response?.isValidate) {
      setSevenDayForecastChangeData(response?.data?.data);
      setSevenDayChangeLoading(false);
    }

    if (!response?.isValidate) {
      setSevenDayForecastChangeData(SevenDayChangeTableData);
      setSevenDayChangeLoading(false);
    }
  };

  const [tblids, settblids] = useState({
    pickupMonthTable: commonService.GenerateGUID(),
    onThebookWidgetPickup: commonService.GenerateGUID(),
    tblOneDayPickup: commonService.GenerateGUID(),
    tblOneDayForecastChangePickup: commonService.GenerateGUID(),
    tblSevenDayPickup: commonService.GenerateGUID(),
    tblSevenDayForecastChangePickup: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.onThebookWidgetPickup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblOneDayPickup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblOneDayForecastChangePickup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblSevenDayPickup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblSevenDayForecastChangePickup} .perbackground-rev`
  );

  useEffect(() => {
    if (api === 0) {
      setOTBData(OTBTableData);
      setOneDayData(OneDayTableData);
      setOneDayForecastChangeData(OneDayChangeTableData);
      setSevenDayData(SevenDayTableData);
      setSevenDayForecastChangeData(SevenDayChangeTableData);
    } else {
      if (para?.asOfDate) {
        getOnTheBookDetail();
        getOneDayDetail();
        getOneDayForecastChangeDetail();
        getSevenDayDetail();
        getSevenDayForecastChangeDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  let AllWidgetContainer = document.getElementById("PickupAllWidgetInSingle");
  if (AllWidgetContainer !== null) {
    let CW = AllWidgetContainer.clientWidth;
    CW = CW - 120;
    CW = Math.round(CW / 5);
    if (CW < 250) {
      CW = 250;
    }
    let Widget = AllWidgetContainer.getElementsByClassName(
      "allTableTogather-widget"
    );
    if (Widget !== null && Widget.length !== 0) {
      for (let i = 0; i < Widget.length; i++) {
        Widget[i].style.width = CW + "px";
      }
    }
  }

  const handlePickupChat = () => {
    let tableId = document?.querySelector(`#PickupAllWidgetInSingle`);

    if (tableId?.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }
    let groupingJson = {
      propertyid: para?.propertyDetail?.propertyid,
      clientid: userDetail?.clientid,
      userid: userDetail?.userid,
      prompt: "Please generate summary",
      json: {},
    };

    let getAllTable = tableId?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget .ViewPropertySetupTable .stripped__table table"
    );

    getAllTable?.forEach((tableItem, tableIndex) => {
      let getTbody = tableItem?.querySelectorAll("tbody tr");
      let tableName = "On The Books";
      if (tableIndex === 1) {
        tableName = "One Day";
      } else if (tableIndex === 2) {
        tableName = "One Day Forecast Change";
      } else if (tableIndex === 3) {
        tableName = "Seven Day";
      } else if (tableIndex === 4) {
        tableName = "Seven Day Forecast Change";
      }
      let arr = [];
      const removeSymbol = (value) => {
        let data = value?.replaceAll("$", "");
        let removePercentage = data?.replaceAll("%", "");
        let finalValue = removePercentage?.replaceAll(",", "");
        return finalValue;
      };

      getTbody?.forEach((tbodyItem, tbodyIndex) => {
        if (tbodyIndex !== getTbody?.length - 1) {
          let getAllTds = tbodyItem?.querySelectorAll("td");
          let obj = {
            propertyCode: para?.propertyCode,
            AsOfDate: para?.asOfDate,
            year: selectedYear,
            month: month[tbodyIndex],
          };

          getAllTds?.forEach((tdItem, tdIndex) => {
            if (tbodyIndex !== getTbody?.length - 1) {
              if (tdIndex == 0) {
                obj.occ = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 1) {
                obj.rms = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 2) {
                obj.adr = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 3) {
                obj.rev = removeSymbol(tdItem?.innerText);
              }
            }
          });
          arr.push(obj);
        }
      });
      groupingJson.json[tableName] = arr;
    });
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.pickupMonthTable +
                    "," +
                    tblids.onThebookWidgetPickup +
                    "," +
                    tblids.tblOneDayPickup +
                    "," +
                    tblids.tblOneDayForecastChangePickup +
                    "," +
                    tblids.tblSevenDayPickup +
                    "," +
                    tblids.tblSevenDayForecastChangePickup,
                  "Pickup",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.pickupCommon,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={
                tblids.pickupMonthTable +
                "," +
                tblids.onThebookWidgetPickup +
                "," +
                tblids.tblOneDayPickup +
                "," +
                tblids.tblOneDayForecastChangePickup +
                "," +
                tblids.tblSevenDayPickup +
                "," +
                tblids.tblSevenDayForecastChangePickup
              }
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.pickupMonthTable +
                    "," +
                    tblids.onThebookWidgetPickup +
                    "," +
                    tblids.tblOneDayPickup +
                    "," +
                    tblids.tblOneDayForecastChangePickup +
                    "," +
                    tblids.tblSevenDayPickup +
                    "," +
                    tblids.tblSevenDayForecastChangePickup,
                  // "Pickup"
                  `${para?.propertyCode}-Pickup-${moment(new Date()).format(
                    "MMDDYYYY"
                  )}`
                )
              }
            />
          </Button>
        </div>
      )}
      <Row>
        <div
          className="d-flex allTableTogatherWrapper"
          id="PickupAllWidgetInSingle"
        >
          <div style={{ width: "110px" }} className="pe-0">
            <div className="ViewPropertySetupTable monthSelectTable">
              <div className="stripped__table">
                <Table id={tblids.pickupMonthTable} responsive>
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            style={{ padding: 0 }}
                          >
                            <option value="">Select</option>
                            {yearsArr?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedYear}
                          </span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {month?.map((item, index) => (
                      <tr key={item}>
                        <td>{item}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="boldTd">Total</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="allTableTogather">
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.onThebookWidgetPickup} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          On The Books
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        OTBData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === OTBData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : otbLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        OTBData &&
                        month?.map((item, index) => {
                          let findMonthData = OTBData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.occ) {
                            otbocc = otbocc + findMonthData?.[0]?.occ;
                          } else {
                            otbocc = otbocc + 0;
                          }
                          if (findMonthData?.[0]?.rms) {
                            otbrms = otbrms + findMonthData?.[0]?.rms;
                          } else {
                            otbrms = otbrms + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            otbadr = otbadr + findMonthData?.[0]?.adr;
                          } else {
                            otbadr = otbadr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            otbrev = otbrev + findMonthData?.[0]?.rev;
                          } else {
                            otbrev = otbrev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(findMonthData?.[0]?.rms)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={
                                    findMonthData?.[0]?.rev
                                      ? findMonthData?.[0]?.rev
                                      : 0
                                  }
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(otbocc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(otbrms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {otbrev !== 0 && otbrms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(otbrev / otbrms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(otbrev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table responsive id={tblids.tblOneDayPickup}>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          One Day
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        oneDayData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === oneDayData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : oneDayLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        oneDayData &&
                        month?.map((item, index) => {
                          let findMonthData = oneDayData?.filter(
                            (x) => x.month === item
                          );

                          oneDayOcc =
                            oneDayOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          oneDayRms =
                            oneDayRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          oneDayAdr =
                            oneDayAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          oneDayRev =
                            oneDayRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(findMonthData?.[0]?.rms)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {oneDayOcc > 0
                                      ? parseInt(oneDayOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(oneDayRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {oneDayRev !== 0 && oneDayRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(oneDayRev / oneDayRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(oneDayRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table responsive id={tblids.tblOneDayForecastChangePickup}>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          One Day Forecast Change
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        oneDayForecastChangeData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === oneDayForecastChangeData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : oneDayChangeLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        oneDayForecastChangeData &&
                        month?.map((item, index) => {
                          let findMonthData = oneDayForecastChangeData?.filter(
                            (x) => x.month?.trim() === item
                          );

                          oneDayChangeOcc =
                            oneDayChangeOcc +
                            (findMonthData?.[0]?.occ
                              ? parseFloat(findMonthData?.[0]?.occ)
                              : 0);
                          oneDayChangeRms =
                            oneDayChangeRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          oneDayChangeAdr =
                            oneDayChangeAdr +
                            (findMonthData?.[0]?.adr
                              ? parseFloat(findMonthData?.[0]?.adr)
                              : 0);
                          oneDayChangeRev =
                            oneDayChangeRev +
                            (findMonthData?.[0]?.rev
                              ? parseFloat(findMonthData?.[0]?.rev)
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? Math.round(findMonthData?.[0]?.occ)
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(findMonthData?.[0]?.rms)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        Math.round(findMonthData?.[0]?.adr)
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        Math.round(findMonthData?.[0]?.rev)
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {oneDayChangeOcc !== 0
                                      ? Math.round(oneDayChangeOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(oneDayChangeRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {oneDayChangeRev !== 0 &&
                                    oneDayChangeRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(
                                            oneDayChangeRev / oneDayChangeRms
                                          )
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      Math.round(oneDayChangeRev)
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table responsive id={tblids.tblSevenDayPickup}>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          Seven Day
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        sevenDayData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === sevenDayData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : sevenDayLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        sevenDayData &&
                        month?.map((item, index) => {
                          let findMonthData = sevenDayData?.filter(
                            (x) => x.month === item
                          );

                          sevenDayOcc =
                            sevenDayOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          sevenDayRms =
                            sevenDayRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          sevenDayAdr =
                            sevenDayAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          sevenDayRev =
                            sevenDayRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(findMonthData?.[0]?.rms)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {sevenDayOcc > 0
                                      ? parseInt(sevenDayOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(sevenDayRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {sevenDayRev !== 0 && sevenDayRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(sevenDayRev / sevenDayRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(sevenDayRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table responsive id={tblids.tblSevenDayForecastChangePickup}>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          Seven Day Forecast Change
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        sevenDayForecastChangeData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === 11 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : sevenDayChangeLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        sevenDayForecastChangeData &&
                        month?.map((item, index) => {
                          let findMonthData =
                            sevenDayForecastChangeData?.filter(
                              (x) => x.month?.trim() === item
                            );

                          sevenDayChangeOcc =
                            sevenDayChangeOcc +
                            (findMonthData?.[0]?.occ
                              ? parseFloat(findMonthData?.[0]?.occ)
                              : 0);
                          sevenDayChangeRms =
                            sevenDayChangeRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          sevenDayChangeAdr =
                            sevenDayChangeAdr +
                            (findMonthData?.[0]?.adr
                              ? parseFloat(findMonthData?.[0]?.adr)
                              : 0);
                          sevenDayChangeRev =
                            sevenDayChangeRev +
                            (findMonthData?.[0]?.rev
                              ? parseFloat(findMonthData?.[0]?.rev)
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {Math.round(
                                    findMonthData?.[0]?.occ
                                      ? findMonthData?.[0]?.occ
                                      : 0
                                  )}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(findMonthData?.[0]?.rms)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {commonService.formateAmount(
                                    Math.round(
                                      findMonthData?.[0]?.adr
                                        ? findMonthData?.[0]?.adr
                                        : 0
                                    )
                                  )}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {commonService.formateAmount(
                                    Math.round(
                                      findMonthData?.[0]?.rev
                                        ? findMonthData?.[0]?.rev
                                        : 0
                                    )
                                  )}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(sevenDayChangeOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(sevenDayChangeRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {sevenDayChangeRev !== 0 &&
                                    sevenDayChangeRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(
                                            sevenDayChangeRev /
                                              sevenDayChangeRms
                                          )
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      Math.round(sevenDayChangeRev)
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {api !== 0 && <Comments widgetDetails={para} editorId={"PickupCommon"} />}

      {/* {showNoteSidebar && (
                <WidgetNote
                    show={showNoteSidebar}
                    handleClose={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default PickupCommon;
