import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import downArrow from "../../Assets/Images/Header/downArrow.svg";
import moment from "moment";
import closeBtn from "../../Assets/Images/Action Icons/closeIcon.svg";
import { commonService } from "../../utils/commonService";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

const SingleDateSummaryModal = ({
  showModal,
  closeModal,
  asOfDate,
  selectedDate,
  pickupDate,
  propertyDetail,
  fromHeatMap,
}) => {
  const [singleDateData, setSingleDateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const totalRef = useRef();
  const countForPickupAdrDivision = useRef();
  const [selectedPickupDateType, setSelectedPickupDateType] = useState("7Day");
  let date = moment(asOfDate).subtract(7, "d");
  const pickupDateRef = useRef(date);
  const [changeDate, setChangeDate] = useState(false);
  let maxDate = new Date();
  if (asOfDate) {
    let date = new Date(asOfDate);
    maxDate = date.setDate(date.getDate() - 1);
  }

  let totalRoom = 0,
    totalRoomLy = 0,
    totalLOS = 0,
    totalPickupFrom = 0,
    totalAdrPickup = 0,
    totalAdr = 0,
    totalAdrLy = 0,
    totalRevenue = 0,
    totalRevenueLy = 0;

  const getSingleDateData = async () => {
    setLoading(true);
    let singleDateResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.SINGLE_DATE_SUMMARY,
        body: {
          AsOfDate: moment(asOfDate).format("YYYY-MM-DD"),
          SelectedDate: moment(selectedDate).format("YYYY-MM-DD"),
          propertyCode: propertyDetail?.propertyCode,
          PickUpDate: moment(pickupDateRef.current).format("YYYY-MM-DD"),
          // PickUpDate: moment(pickupDate).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, singleDateResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = [];
      totalRef.current = {
        room: 0,
        lyRoom: 0,
        LOS: 0,
        pickupFrom: 0,
        pickupAdr: 0,
        adr: 0,
        lyAdr: 0,
        revenue: 0,
        lyRevenue: 0,
      };
      countForPickupAdrDivision.current = 0;

      const checkMarketSegmentExist = (marketSegmentName) => {
        let filterData = groupingJson?.findIndex(
          (x) => x.name === marketSegmentName
        );

        return filterData;
      };

      const checkRateCodeExist = (indexOfMarketSegment, rateCodeName) => {
        let filterData = groupingJson[indexOfMarketSegment]?.items?.findIndex(
          (x) => x.name === rateCodeName
        );
        return filterData;
      };

      const checkCompanyExists = (
        indexOfMarketSegment,
        indexofRateCode,
        companyName
      ) => {
        let filterData = groupingJson[indexOfMarketSegment].items[
          indexofRateCode
        ]?.items?.findIndex((x) => x.name === companyName);

        return filterData;
      };

      const marketSegmentWiseTotal = (marketSegmentName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          LOS: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
        };
        dataFromApi?.map((item, index) => {
          if (item.MarketSegment === marketSegmentName) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue +
              (item?.LY_Revenue ? parseInt(item?.LY_Revenue) : 0);
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        if (obj.pickupAdr > 0) {
          countForPickupAdrDivision.current =
            countForPickupAdrDivision.current + 1;
        }
        totalRef.current.room = totalRef.current.room + obj.room;
        totalRef.current.lyRoom = totalRef.current.lyRoom + obj.lyRoom;
        totalRef.current.LOS = totalRef.current.LOS + obj.LOS;
        totalRef.current.pickupFrom =
          totalRef.current.pickupFrom + obj.pickupFrom;
        totalRef.current.pickupAdr = totalRef.current.pickupAdr + obj.pickupAdr;
        totalRef.current.adr = totalRef.current.adr + obj.adr;
        totalRef.current.lyAdr = totalRef.current.lyAdr + obj.lyAdr;
        totalRef.current.revenue = totalRef.current.revenue + obj.revenue;
        totalRef.current.lyRevenue = totalRef.current.lyRevenue + obj.lyRevenue;
        return obj;
      };

      const rateCodeWiseTotal = (marketSegmentName, rateCodeName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
          LOS: 0,
        };
        dataFromApi?.map((item, index) => {
          if (
            item.MarketSegment === marketSegmentName &&
            item.RateCode === rateCodeName
          ) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue +
              (item?.LY_Revenue ? parseInt(item?.LY_Revenue) : 0);
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        return obj;
      };

      const companyWiseTotal = (
        marketSegmentName,
        rateCodeName,
        companyName
      ) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
          LOS: 0,
        };
        dataFromApi?.map((item, index) => {
          if (
            item.MarketSegment === marketSegmentName &&
            item.RateCode === rateCodeName &&
            item.Company === companyName
          ) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue +
              (item?.LY_Revenue ? parseInt(item?.LY_Revenue) : 0);
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        return obj;
      };

      for (let i = 0; i < dataFromApi?.length; i++) {
        let indexOfMarketSegment = checkMarketSegmentExist(
          dataFromApi[i].MarketSegment
        );
        if (indexOfMarketSegment >= 0) {
          let indexofRateCode = checkRateCodeExist(
            indexOfMarketSegment,
            `${dataFromApi[i].RateCode}${
              dataFromApi[i]?.RateCodeDescript
                ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                : ""
            }`
          );
          if (indexofRateCode >= 0) {
            let indexOfCompany = checkCompanyExists(
              indexOfMarketSegment,
              indexofRateCode,
              dataFromApi[i].Company
            );
            if (indexOfCompany >= 0) {
              groupingJson[indexOfMarketSegment].items[indexofRateCode].items[
                indexOfCompany
              ].items = [
                ...groupingJson[indexOfMarketSegment].items[indexofRateCode]
                  ?.items[indexOfCompany]?.items,
                {
                  name: dataFromApi[i].GuestName,
                  guid: commonService.GenerateGUID(),
                  items: "",
                  room: dataFromApi[i].CURRENT_ROOM,
                  lyRoom: dataFromApi[i].LY_ROOM,
                  arrDate: dataFromApi[i]?.ArrDate,
                  LOS: dataFromApi[i]?.LOS,
                  pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                  pickupAdr: dataFromApi[i].ADR_PICKUP,
                  // adr: dataFromApi[i].CURRENT_ADR,
                  adr: dataFromApi[i].CURRENT_REVENUE
                    ? Math.round(
                        dataFromApi[i].CURRENT_REVENUE /
                          dataFromApi[i].CURRENT_ROOM
                      )
                    : 0,
                  //   lyAdr: dataFromApi[i].LY_ADR,
                  lyAdr: dataFromApi[i].LY_Revenue
                    ? Math.round(
                        dataFromApi[i].LY_Revenue / dataFromApi[i].LY_ROOM
                      )
                    : 0,
                  revenue: dataFromApi[i].CURRENT_REVENUE,
                  lyRevenue: dataFromApi[i].LY_Revenue,
                },
              ];
            } else {
              let companyTotal = companyWiseTotal(
                dataFromApi[i].MarketSegment,
                dataFromApi[i].RateCode,
                dataFromApi[i].Company
              );
              groupingJson[indexOfMarketSegment].items[indexofRateCode].items =
                [
                  ...groupingJson[indexOfMarketSegment].items[indexofRateCode]
                    .items,
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    // adr: companyTotal?.adr,
                    adr: companyTotal?.revenue
                      ? Math.round(companyTotal?.revenue / companyTotal?.room)
                      : 0,
                    // lyAdr: companyTotal?.lyAdr,
                    lyAdr: companyTotal?.lyRevenue
                      ? Math.round(
                          companyTotal?.lyRevenue / companyTotal?.lyRoom
                        )
                      : 0,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        // adr: dataFromApi[i].CURRENT_ADR,
                        adr: dataFromApi[i].CURRENT_REVENUE
                          ? Math.round(
                              dataFromApi[i].CURRENT_REVENUE /
                                dataFromApi[i].CURRENT_ROOM
                            )
                          : 0,
                        // lyAdr: dataFromApi[i].LY_ADR,
                        lyAdr: dataFromApi[i].LY_Revenue
                          ? Math.round(
                              dataFromApi[i].LY_Revenue / dataFromApi[i].LY_ROOM
                            )
                          : 0,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_Revenue,
                      },
                    ],
                  },
                ];
            }
          } else {
            let rateCodeTotal = rateCodeWiseTotal(
              dataFromApi[i].MarketSegment,
              dataFromApi[i].RateCode
            );
            let companyTotal = companyWiseTotal(
              dataFromApi[i].MarketSegment,
              dataFromApi[i].RateCode,
              dataFromApi[i].Company
            );
            groupingJson[indexOfMarketSegment].items = [
              ...groupingJson[indexOfMarketSegment].items,
              {
                name: `${dataFromApi[i].RateCode}${
                  dataFromApi[i]?.RateCodeDescript
                    ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                    : ""
                }`,
                guid: commonService.GenerateGUID(),
                room: rateCodeTotal?.room,
                lyRoom: rateCodeTotal?.lyRoom,
                arrDate: "",
                LOS: rateCodeTotal?.LOS,
                pickupFrom: rateCodeTotal?.pickupFrom,
                pickupAdr: rateCodeTotal?.pickupAdr,
                // adr: rateCodeTotal?.adr,
                adr: rateCodeTotal?.revenue
                  ? Math.round(rateCodeTotal?.revenue / rateCodeTotal?.room)
                  : 0,
                // lyAdr: rateCodeTotal?.lyAdr,
                lyAdr: rateCodeTotal?.lyRevenue
                  ? Math.round(rateCodeTotal?.lyRevenue / rateCodeTotal?.lyRoom)
                  : 0,
                revenue: rateCodeTotal?.revenue,
                lyRevenue: rateCodeTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    // adr: companyTotal?.adr,
                    adr: companyTotal?.revenue
                      ? Math.round(companyTotal?.revenue / companyTotal?.room)
                      : 0,
                    // lyAdr: companyTotal?.lyAdr,
                    lyAdr: companyTotal?.lyRevenue
                      ? Math.round(
                          companyTotal?.lyRevenue / companyTotal?.lyRoom
                        )
                      : 0,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        // adr: dataFromApi[i].CURRENT_ADR,
                        adr: dataFromApi[i].CURRENT_REVENUE
                          ? Math.round(
                              dataFromApi[i].CURRENT_REVENUE /
                                dataFromApi[i].CURRENT_ROOM
                            )
                          : 0,
                        // lyAdr: dataFromApi[i].LY_ADR,
                        lyAdr: dataFromApi[i].LY_Revenue
                          ? Math.round(
                              dataFromApi[i].LY_Revenue / dataFromApi[i].LY_ROOM
                            )
                          : 0,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_Revenue,
                      },
                    ],
                  },
                ],
              },
            ];
          }
        } else {
          let marketSegmentTotal = marketSegmentWiseTotal(
            dataFromApi[i].MarketSegment
          );
          let rateCodeTotal = rateCodeWiseTotal(
            dataFromApi[i].MarketSegment,
            dataFromApi[i].RateCode
          );
          let companyTotal = companyWiseTotal(
            dataFromApi[i].MarketSegment,
            dataFromApi[i].RateCode,
            dataFromApi[i].Company
          );
          let newObj = {
            name: dataFromApi[i].MarketSegment,
            guid: commonService.GenerateGUID(),
            room: marketSegmentTotal?.room,
            lyRoom: marketSegmentTotal?.lyRoom,
            arrDate: "",
            LOS: marketSegmentTotal?.LOS,
            pickupFrom: marketSegmentTotal?.pickupFrom,
            pickupAdr: marketSegmentTotal?.pickupAdr,
            // adr: marketSegmentTotal?.adr,
            adr: marketSegmentTotal?.revenue
              ? Math.round(
                  (marketSegmentTotal?.revenue
                    ? parseFloat(marketSegmentTotal?.revenue)
                    : 0) /
                    (marketSegmentTotal?.room
                      ? parseInt(marketSegmentTotal?.room)
                      : 0)
                )
              : 0,
            // lyAdr: marketSegmentTotal?.lyAdr,
            lyAdr: marketSegmentTotal?.lyRevenue
              ? Math.round(
                  marketSegmentTotal?.lyRevenue / marketSegmentTotal?.lyRoom
                )
              : 0,
            revenue: marketSegmentTotal?.revenue,
            lyRevenue: marketSegmentTotal?.lyRevenue,
            items: [
              {
                name: `${dataFromApi[i].RateCode}${
                  dataFromApi[i]?.RateCodeDescript
                    ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                    : ""
                }`,
                guid: commonService.GenerateGUID(),
                room: rateCodeTotal?.room,
                lyRoom: rateCodeTotal?.lyRoom,
                arrDate: "",
                LOS: rateCodeTotal?.LOS,
                pickupFrom: rateCodeTotal?.pickupFrom,
                pickupAdr: rateCodeTotal?.pickupAdr,
                // adr: rateCodeTotal?.adr,
                adr: rateCodeTotal?.revenue
                  ? Math.round(rateCodeTotal?.revenue / rateCodeTotal?.room)
                  : 0,
                // lyAdr: rateCodeTotal?.lyAdr,
                lyAdr: rateCodeTotal?.lyRevenue
                  ? Math.round(rateCodeTotal?.lyRevenue / rateCodeTotal?.lyRoom)
                  : 0,
                revenue: rateCodeTotal?.revenue,
                lyRevenue: rateCodeTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    // adr: companyTotal?.adr,
                    adr: companyTotal?.revenue
                      ? Math.round(companyTotal?.revenue / companyTotal?.room)
                      : 0,
                    // lyAdr: companyTotal?.lyAdr,
                    lyAdr: companyTotal?.lyRevenue
                      ? Math.round(
                          companyTotal?.lyRevenue / companyTotal?.lyRoom
                        )
                      : 0,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        // adr: dataFromApi[i].CURRENT_ADR,
                        adr: dataFromApi[i].CURRENT_REVENUE
                          ? Math.round(
                              dataFromApi[i].CURRENT_REVENUE /
                                dataFromApi[i].CURRENT_ROOM
                            )
                          : 0,
                        // lyAdr: dataFromApi[i].LY_ADR,
                        lyAdr: dataFromApi[i].LY_Revenue
                          ? Math.round(
                              dataFromApi[i].LY_Revenue / dataFromApi[i].LY_ROOM
                            )
                          : 0,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_Revenue,
                      },
                    ],
                  },
                ],
              },
            ],
          };

          groupingJson = [...groupingJson, newObj];
        }
      }
      let jsonForPickupAdr = [...groupingJson];
      let totalMarketSegmentPickupAdr = 0,
        totalMarketSegment = 0;

      const marketSegmentPickupAdr = () => {
        let pickupAdrValue = 0,
          totalRateCode = 0;
        for (let i = 0; i < jsonForPickupAdr?.length; i++) {
          for (let j = 0; j < jsonForPickupAdr?.[i]?.items?.length; j++) {
            pickupAdrValue =
              pickupAdrValue +
              (jsonForPickupAdr?.[i]?.items?.[j]?.pickupAdr
                ? parseInt(jsonForPickupAdr?.[i]?.items?.[j]?.pickupAdr)
                : 0);
            if (
              jsonForPickupAdr?.[i]?.items?.[j]?.pickupAdr &&
              jsonForPickupAdr?.[i]?.items?.[j]?.pickupAdr !== "0"
            ) {
              totalRateCode = totalRateCode + 1;
            }
          }
          jsonForPickupAdr[i].pickupAdr = pickupAdrValue
            ? Math.round(pickupAdrValue / totalRateCode)
            : 0;
          if (pickupAdrValue) {
            totalMarketSegmentPickupAdr =
              totalMarketSegmentPickupAdr +
              Math.round(pickupAdrValue / totalRateCode);
            totalMarketSegment = totalMarketSegment + 1;
          }
          pickupAdrValue = 0;
          totalRateCode = 0;
        }
        totalRef.current.pickupAdr = totalMarketSegmentPickupAdr
          ? Math.round(totalMarketSegmentPickupAdr / totalMarketSegment)
          : 0;
      };

      const rateCodePickupAdr = () => {
        let pickupAdrValue = 0,
          totalCompany = 0;
        for (let i = 0; i < jsonForPickupAdr?.length; i++) {
          for (let j = 0; j < jsonForPickupAdr?.[i]?.items?.length; j++) {
            for (
              let k = 0;
              k < jsonForPickupAdr?.[i]?.items?.[j]?.items?.length;
              k++
            ) {
              pickupAdrValue =
                pickupAdrValue +
                (jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.pickupAdr
                  ? parseInt(
                      jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.pickupAdr
                    )
                  : 0);
              if (
                jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.pickupAdr &&
                jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.pickupAdr !== "0"
              ) {
                totalCompany = totalCompany + 1;
              }
            }
            jsonForPickupAdr[i].items[j].pickupAdr = pickupAdrValue
              ? Math.round(pickupAdrValue / totalCompany)
              : 0;
            pickupAdrValue = 0;
            totalCompany = 0;
          }
        }
      };

      const companyPickupAdr = () => {
        let pickupAdrValue = 0,
          totalGuest = 0;
        for (let i = 0; i < jsonForPickupAdr?.length; i++) {
          for (let j = 0; j < jsonForPickupAdr?.[i]?.items?.length; j++) {
            for (
              let k = 0;
              k < jsonForPickupAdr?.[i]?.items?.[j]?.items?.length;
              k++
            ) {
              for (
                let l = 0;
                l <
                jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.items?.length;
                l++
              ) {
                pickupAdrValue =
                  pickupAdrValue +
                  (jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.items?.[l]
                    ?.pickupAdr
                    ? parseInt(
                        jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.items?.[
                          l
                        ]?.pickupAdr
                      )
                    : 0);
                if (
                  jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.items?.[l]
                    ?.pickupAdr !== "0" &&
                  jsonForPickupAdr?.[i]?.items?.[j]?.items?.[k]?.items?.[l]
                    ?.pickupAdr
                ) {
                  totalGuest = totalGuest + 1;
                }
              }
              jsonForPickupAdr[i].items[j].items[k].pickupAdr = pickupAdrValue
                ? Math.round(pickupAdrValue / totalGuest)
                : 0;
              pickupAdrValue = 0;
              totalGuest = 0;
            }
          }
        }
      };

      companyPickupAdr();
      rateCodePickupAdr();
      marketSegmentPickupAdr();

      // setSingleDateData(groupingJson);
      setSingleDateData(jsonForPickupAdr);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSingleDateData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleDateData();
  }, [selectedDate, pickupDateRef.current]);

  const handleShowHideRow = (className, itemDetail) => {
    if (document.getElementById(className) !== null) {
      if (document.getElementById(className).classList.contains("active")) {
        document.getElementById(className).classList.remove("active");
      } else {
        document.getElementById(className).classList.add("active");
      }
    }

    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        } else {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };

  const checkActiveRow = (e, guid, itemDetail) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      e.currentTarget.classList.remove("singleDateSummmaryTr");
    } else {
      e.currentTarget.classList.add("active");
      e.currentTarget.classList.add("singleDateSummmaryTr");
    }

    // if (guid) {
    //   const classList = document.getElementsByClassName(guid);
    //   for (let i = 0; i < classList?.length; i++) {
    //     if (
    //       classList[i].classList.contains("active") &&
    //       classList[i].classList.contains("dropDownTrColor")
    //     ) {
    //       classList[i].classList.remove("active");
    //       classList[i].classList.remove("dropDownTrColor");
    //     }
    //   }
    // }
    // const removeFromGuid = (guid) => {
    //   const classList = document.getElementsByClassName(guid);
    //   for (let i = 0; i < classList?.length; i++) {
    //     if (
    //       classList[i].classList.contains("active") &&
    //       classList[i].classList.contains("dropDownTrColor")
    //     ) {
    //       classList[i].classList.remove("active");
    //       classList[i].classList.remove("dropDownTrColor");
    //     }
    //   }
    // };
    // const removeClass = (childItems) => {
    //   removeFromGuid(childItems?.[0]?.guid);
    //   if (
    //     Array.isArray(childItems?.[0]?.items) &&
    //     childItems?.[0]?.items?.length > 0
    //   ) {
    //     removeClass(childItems?.[0]?.items);
    //   }
    // };

    // if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
    //   removeClass(itemDetail?.items);
    // }
  };
  return (
    <div
      className={`GroupNameModalMain singleDateSummaryModalMain ToolsSingleTicketPopUpMain`}
    >
      <div className="GroupNameModalHeader">
        <div className="singleDateSummaryModalHeader">
          <h2>{`Single Date Summary (${
            selectedDate ? commonService.getDateInFormat(selectedDate) : ""
          })`}</h2>
          <div>
            <Form.Select
              className="singleDateSummaryDropdown"
              value={selectedPickupDateType}
              onChange={(e) => {
                if (e.target.value === "1Day") {
                  pickupDateRef.current = moment(asOfDate).subtract(1, "d");
                } else if (e.target.value === "7Day") {
                  pickupDateRef.current = moment(asOfDate).subtract(7, "d");
                } else if (e.target.value == "14Day") {
                  pickupDateRef.current = moment(asOfDate).subtract(14, "d");
                }
                setSelectedPickupDateType(e.target.value);
              }}
            >
              <option value="">Select Pickup</option>
              <option value="1Day">1 Day Pickup</option>
              <option value="7Day">7 Day Pickup</option>
              <option value="14Day">14 Day Pickup</option>
            </Form.Select>
          </div>
          <div className="width100 single-date-summary-date-picker">
            <DatePicker
              maxDate={new Date(maxDate)}
              selected={new Date(pickupDateRef.current)}
              onChange={(date) => {
                pickupDateRef.current = date;
                setSelectedPickupDateType("");
                setChangeDate(!changeDate);
              }}
            />
          </div>
        </div>
        <button className="btn-close" onClick={closeModal}></button>
      </div>

      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Market Segment</th>
                    <th className="text-center">Room</th>
                    <th className="border_right text-center">LY</th>
                    <th>Arr Date</th>
                    <th className="text-center">LOS</th>
                    <th className="text-center">Pickup From</th>
                    <th className="border_right text-end">ADR Pickup</th>
                    <th className="text-end">ADR</th>
                    <th className="border_right text-end">LY</th>
                    <th className="text-end">Revenue</th>
                    <th className="text-end">LY</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    singleDateData &&
                    singleDateData?.map((item, index) => {
                      return (
                        <>
                          <tr id={item?.guid}>
                            <td className="border_right">
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  const classList =
                                    document.getElementsByClassName(item?.guid);

                                  if (
                                    classList[0].classList.contains(
                                      "nestedTableRowNone"
                                    )
                                  ) {
                                    handleShowHideRow(item?.guid);
                                  } else {
                                    handleShowHideRow(item?.guid, item);
                                  }
                                }}
                              >
                                <p>{item?.name}</p>
                                <img src={downArrow} />
                              </div>
                            </td>
                            <td className="text-center">{item?.room}</td>
                            <td className="border_right text-center">
                              {item?.lyRoom}
                            </td>
                            <td>
                              {item?.arrDate
                                ? commonService.getDateInFormat(item?.arrDate)
                                : ""}
                            </td>
                            <td></td>
                            <td className="text-center">{item?.pickupFrom}</td>
                            <td className="border_right text-end">
                              {commonService.formateAmount(item?.pickupAdr)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.adr)}
                            </td>
                            <td className="text-end border_right">
                              {commonService.formateAmount(item?.lyAdr)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.revenue)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.lyRevenue)}
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => (
                              <>
                                <tr
                                  className={`${item?.guid} nestedTableRowNone `}
                                  id={rateCodeItem?.guid}
                                >
                                  <td className="border_right">
                                    <div
                                      style={{ paddingLeft: "10px" }}
                                      className="d-flex gap-2"
                                      onClick={() => {
                                        const classList =
                                          document.getElementsByClassName(
                                            rateCodeItem?.guid
                                          );

                                        if (
                                          classList[0].classList.contains(
                                            "nestedTableRowNone"
                                          )
                                        ) {
                                          handleShowHideRow(rateCodeItem?.guid);
                                        } else {
                                          handleShowHideRow(
                                            rateCodeItem?.guid,
                                            rateCodeItem
                                          );
                                        }
                                      }}
                                    >
                                      <p>{rateCodeItem?.name}</p>
                                      <img src={downArrow} />
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    {rateCodeItem?.room}
                                  </td>
                                  <td className="border_right text-center">
                                    {rateCodeItem?.lyRoom}
                                  </td>
                                  <td>
                                    {rateCodeItem?.arrDate
                                      ? commonService.getDateInFormat(
                                          rateCodeItem?.arrDate
                                        )
                                      : ""}
                                  </td>
                                  <td></td>
                                  <td className="text-center">
                                    {rateCodeItem?.pickupFrom}
                                  </td>
                                  <td className="border_right text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.pickupAdr
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.adr
                                    )}
                                  </td>
                                  <td className="text-end border_right">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyAdr
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.revenue
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyRevenue
                                    )}
                                  </td>
                                </tr>
                                {Array.isArray(rateCodeItem?.items) &&
                                  rateCodeItem?.items?.length > 0 &&
                                  rateCodeItem?.items?.map(
                                    (companyItem, companyIndex) => (
                                      <>
                                        <tr
                                          className={`${rateCodeItem?.guid} nestedTableRowNone `}
                                          id={companyItem?.guid}
                                        >
                                          <td className="border_right">
                                            <div
                                              style={{ paddingLeft: "20px" }}
                                              className="d-flex gap-2"
                                              onClick={() => {
                                                const classList =
                                                  document.getElementsByClassName(
                                                    companyItem?.guid
                                                  );

                                                if (
                                                  classList[0].classList.contains(
                                                    "nestedTableRowNone"
                                                  )
                                                ) {
                                                  handleShowHideRow(
                                                    companyItem?.guid
                                                  );
                                                } else {
                                                  handleShowHideRow(
                                                    companyItem?.guid,
                                                    companyItem
                                                  );
                                                }
                                              }}
                                            >
                                              <p>{companyItem?.name}</p>
                                              <img src={downArrow} />
                                            </div>
                                          </td>
                                          <td className="text-center">
                                            {companyItem?.room}
                                          </td>
                                          <td className="border_right text-center">
                                            {companyItem?.lyRoom}
                                          </td>
                                          <td>
                                            {companyItem?.arrDate
                                              ? commonService.getDateInFormat(
                                                  companyItem?.arrDate
                                                )
                                              : ""}
                                          </td>
                                          <td></td>
                                          <td className="text-center">
                                            {companyItem?.pickupFrom}
                                          </td>
                                          <td className="border_right text-end">
                                            {commonService.formateAmount(
                                              companyItem?.pickupAdr
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.adr
                                            )}
                                          </td>
                                          <td className="text-end border_right">
                                            {commonService.formateAmount(
                                              companyItem?.lyAdr
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.revenue
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.lyRevenue
                                            )}
                                          </td>
                                        </tr>
                                        {Array.isArray(companyItem?.items) &&
                                          companyItem?.items?.length > 0 &&
                                          companyItem?.items?.map(
                                            (guestItem, guestIndex) => (
                                              <tr
                                                className={`${companyItem?.guid} nestedTableRowNone `}
                                                onClick={(e) => {
                                                  checkActiveRow(e);
                                                }}
                                              >
                                                <td className="border_right">
                                                  <div
                                                    style={{
                                                      paddingLeft: "30px",
                                                    }}
                                                    className="d-flex gap-2"
                                                  >
                                                    <p>{guestItem?.name}</p>
                                                  </div>
                                                </td>
                                                <td className="text-center">
                                                  {guestItem?.room}
                                                </td>
                                                <td className="border_right text-center">
                                                  {guestItem?.lyRoom}
                                                </td>
                                                <td>
                                                  {guestItem?.arrDate
                                                    ? commonService.getDateInFormat(
                                                        guestItem?.arrDate
                                                      )
                                                    : ""}
                                                </td>
                                                <td className="text-center">
                                                  {guestItem?.LOS}
                                                </td>
                                                <td className="text-center">
                                                  {guestItem?.pickupFrom}
                                                </td>
                                                <td className="border_right text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.pickupAdr
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {guestItem?.adr
                                                    ? commonService.formateAmount(
                                                        guestItem?.adr
                                                      )
                                                    : commonService.formateAmount(
                                                        0
                                                      )}
                                                </td>
                                                <td className="text-end border_right">
                                                  {guestItem?.lyAdr
                                                    ? commonService.formateAmount(
                                                        guestItem?.lyAdr
                                                      )
                                                    : commonService.formateAmount(
                                                        0
                                                      )}
                                                </td>
                                                <td className="text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.revenue
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.lyRevenue
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </>
                                    )
                                  )}
                              </>
                            ))}
                          {index === singleDateData?.length - 1 && (
                            <tr>
                              <td className="boldTd border_right">Total</td>
                              <td className="boldTd text-center">
                                {totalRef.current.room}
                              </td>
                              <td className="boldTd text-center border_right">
                                {totalRef.current.lyRoom}
                              </td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd text-center">
                                {totalRef.current.pickupFrom}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  totalRef.current.pickupAdr
                                )}
                                {/* {countForPickupAdrDivision.current > 0
                                  ? Math.round(
                                      totalRef.current.pickupAdr /
                                        countForPickupAdrDivision.current
                                    )
                                  : totalRef.current.pickupAdr} */}
                              </td>
                              <td className="boldTd text-end">
                                {totalRef.current.revenue > 0 &&
                                totalRef.current.room > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.revenue /
                                          totalRef.current.room
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {totalRef.current.lyRevenue > 0 &&
                                totalRef.current.lyRoom > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.lyRevenue /
                                          totalRef.current.lyRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current.revenue
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current.lyRevenue
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDateSummaryModal;
