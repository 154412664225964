import React, { useRef, useState } from "react";
import {
    Button,
    Col,
    FloatingLabel,
    Form,
    Modal,
    Row,
    Tab,
    Table,
    Tabs,
} from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "primereact/multiselect";
import RmEventDetailCard from "./RmEventDetailCard";
import reloadIcon from "../../Assets/Images/Revenue-management/blue-reload.svg";
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";
import moment from "moment";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { useSelector } from "react-redux";
import {
    GoogleMap,
    StandaloneSearchBox,
    LoadScript,
    Marker,
} from "@react-google-maps/api";
import DeleteItemModal from "../Configuration/DeleteItemModal";
const lib = ["places", "geometry"];

function ToolsEventModal({
    showToolsEventModal,
    closeToolsEventModal,
    eventDetails,
    nodeEventData,
    dbEventData,
    importEventDetail,
    getEventList,
}) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const propertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.propertyDetail
    );
    const [loading, setLoading] = useState(false);

    let latitude = "",
        longitude = "",
        presentLocation = {};
    let showAddPropertyRef = useRef(false);
    if (eventDetails?.event_id !== undefined) {
        let isEventExists = importEventDetail?.filter(
            (x) => x?.eventsourceid === eventDetails?.event_id
        );
        if (
            isEventExists !== null &&
            isEventExists?.length !== 0 &&
            isEventExists !== undefined
        ) {
            showAddPropertyRef.current = false;
        } else {
            showAddPropertyRef.current = true;
        }
    }
    if (eventDetails?.geo_lat) {
        presentLocation = {
            lat: parseFloat(eventDetails?.geo_long),
            lng: parseFloat(eventDetails?.geo_lat),
        };
        latitude = eventDetails?.geo_lat;
        longitude = eventDetails?.geo_long;
    }

    const handleSubmit = async (d) => {
        setLoading(true);
        let eventName = d?.title?.replace(/['"]/g, "");
        let description = d?.description?.replace(/['"]/g, "");
        let userNote = d?.labels?.replace(/['"]/g, "");

        const saveResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.IMPORT,
                body: {
                    F: [
                        {
                            id: d?.id,
                            yearterm: new Date(d?.start_date).getFullYear(),
                            startdate: d?.start_date,
                            eventname: eventName,
                            eventdetails: description,
                            eventtypeterm: d?.category,
                            eventimpactterm: "",
                            propertyid: propertyDetailFromRTK?.propertyid,
                            usernote: userNote,
                            enddate: d?.end_date,
                            eventsourceid: d?.event_id,
                            eventjson: `"${JSON.stringify(d)}"`,
                        },
                    ],
                },
            },
            true
        );

        let response = apiResponse(true, saveResponse, setLoading);
        setLoading(false);
        if (response?.isValidate) {
            closeToolsEventModal();
            setLoading(false);
        }

        //if (!response?.isValidate) {
        //    /*setLoading(false);*/
        //}
    };

    const handleDelete = (eventDetails) => {
        setDeleteModal(true);
        setDeleteId(eventDetails.id);
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
        setLoading(false);
        closeToolsEventModal();
    };

    return (
        <>
            <Modal
                className="ToolsEventModalMain transparent-modal"
                size="xl"
                centered
                show={showToolsEventModal}
                onHide={
                    closeToolsEventModal ? closeToolsEventModal : closeDeleteModal()
                }
            >
                <div className="ToolsEventModalInner">
                    <Row>
                        <Col xl={3} className="tools-new-design-main">
                            <div className="tools-new-design-header">
                                <h4>Events</h4>
                            </div>
                            <div className="tools-new-design-body">
                                <p className="danger-color-text">Austin trails of lights</p>
                                <p>Tater Day at the Square</p>
                                <p>MargaritaBill at LaChance Winery</p>
                                <p>Kimmswick 5K</p>
                            </div>
                        </Col>
                        <Col xl={9}>
                            <Row>
                                <Col xl={7} className="pe-0">
                                    <div
                                        className="RmEventDetailCardMain light-pink-bg-wrapper"
                                    // style={{ background: bgColor }}
                                    >
                                        <div className="eventDetailWrapper">
                                            <div className="eventDetailInner">
                                                <div className="d-flex align-items-start justify-content-between">
                                                    <h4>
                                                        {eventDetails?.title || eventDetails?.eventname}
                                                    </h4>
                                                </div>
                                                <p>
                                                    {`${moment(
                                                        eventDetails?.start_date || eventDetails?.startdate
                                                    ).format("L")} -
                    ${moment(
                                                        eventDetails?.end_date || eventDetails?.enddate
                                                    ).format("L")}`}
                                                </p>
                                            </div>
                                            {eventDetails?.labels && (
                                                <div className="eventDetailInner2">
                                                    <img src={tentIcon} alt="tentIcon" />
                                                    {eventDetails?.labels
                                                        ?.split(",")
                                                        ?.map((item, index) => (
                                                            <p key={item}>{item}</p>
                                                        ))}
                                                </div>
                                            )}

                                            <div className="eventDetailInner3">
                                                <span>Description</span>
                                                <p>
                                                    {eventDetails?.description ||
                                                        eventDetails?.eventdetails ||
                                                        "-"}
                                                </p>
                                            </div>
                                            <div className="eventDetailInner4">
                                                <div className="eventDetailInnerCircular">
                                                    <h5>
                                                        {eventDetails?.rank
                                                            ? Math.round(eventDetails?.rank)
                                                            : 0}
                                                    </h5>
                                                    <p>Rank</p>
                                                </div>
                                                <div className="eventDetailInnerCircular">
                                                    <h5>
                                                        {eventDetails?.local_rank
                                                            ? Math.round(eventDetails?.local_rank)
                                                            : 0}
                                                    </h5>
                                                    <p>Local</p>
                                                </div>
                                                <div>
                                                    <h5>
                                                        {eventDetails?.phq_attendance
                                                            ? Math.round(eventDetails?.phq_attendance)
                                                            : 0}
                                                    </h5>
                                                    <p>Attendance</p>
                                                </div>
                                            </div>
                                            <div
                                                className="eventDetailInner5 d-block"
                                                style={{ zIndex: "9999 !important", minHeight: "300px" }}
                                            >
                                                {eventDetails?.geo_lat && (
                                                    <LoadScript
                                                        googleMapsApiKey={
                                                            process.env.REACT_APP_GOOGLE_MAP_API_KEY
                                                        }
                                                        libraries={lib}
                                                    >
                                                        <GoogleMap
                                                            mapContainerStyle={{
                                                                height: "300px",
                                                                width: "100%",
                                                                position: "relative",
                                                            }}
                                                            center={presentLocation}
                                                            zoom={12}
                                                            options={{ mapTypeControl: false }}
                                                        >
                                                            <Marker position={presentLocation} />
                                                        </GoogleMap>
                                                    </LoadScript>
                                                )}
                                            </div>
                                            {eventDetails?.eventfrom == "DB" ? (
                                                <div className="eventDetailFooterBtn mt-3">
                                                    <>
                                                        {loading ? (
                                                            <button
                                                                className="default-btn primary-btn"
                                                                type="button"
                                                                disabled
                                                            >
                                                                <span
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                Loading...
                                                            </button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    handleDelete(eventDetails);
                                                                }}
                                                                className="default-btn primary-btn w-100 red-btn"
                                                            >
                                                                Delete event
                                                            </Button>
                                                        )}
                                                    </>
                                                </div>
                                            ) : (
                                                <div className="eventDetailFooterBtn mt-3">
                                                    {showAddPropertyRef.current === true && (
                                                        <>
                                                            {loading ? (
                                                                <button
                                                                    className="default-btn primary-btn"
                                                                    type="button"
                                                                    disabled
                                                                >
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    Loading...
                                                                </button>
                                                            ) : (
                                                                <Button
                                                                    onClick={() => {
                                                                        handleSubmit(eventDetails);
                                                                    }}
                                                                    className="default-btn primary-btn w-100"
                                                                >
                                                                    Add to Property
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* <Button className="heading-menu-btns" type="button">
                  <img src={reloadIcon} alt="reloadIcon" />
                </Button> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={5} className="ps-0">
                                    <div className="tools-new-design-right-sec">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h6>Sun 10, Sep 2023</h6>
                                            <button
                                                className="btn-close"
                                                onClick={closeToolsEventModal}
                                            ></button>
                                        </div>
                                        <div className="tools-new-design-right-sec-table">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className="text-center">7 DAY PICKUP</th>
                                                        <th className="text-center">14 DAY PICKUP</th>
                                                        <th className="text-center">LAST YEAR</th>
                                                        <th className="text-center">STLY</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>RMS</b>
                                                        </td>
                                                        <td className="text-center">0</td>
                                                        <td className="text-center">3</td>
                                                        <td className="text-center">62</td>
                                                        <td className="text-center">4</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>ADR</b>
                                                        </td>
                                                        <td className="text-center">$0</td>
                                                        <td className="text-center">$80</td>
                                                        <td className="text-center">$47</td>
                                                        <td className="text-center">$76</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>REV</b>
                                                        </td>
                                                        <td className="text-center">$0</td>
                                                        <td className="text-center">$240</td>
                                                        <td className="text-center">$2918</td>
                                                        <td className="text-center">$304</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <h6 className="tools-new-design-right-sec-red-title">
                                            On The Books
                                        </h6>
                                        <div className="tools-new-design-right-sec-table no-thead-table">
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>Left to sell</b>
                                                        </td>
                                                        <td className="text-end">88</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>on the books</b>
                                                        </td>
                                                        <td className="text-end">5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>total OCC%</b>
                                                        </td>
                                                        <td className="text-end">5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>ADR</b>
                                                        </td>
                                                        <td className="text-end">$79</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Revenue</b>
                                                        </td>
                                                        <td className="text-end">$394</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <h6 className="tools-new-design-right-sec-red-title">
                                            Forecast
                                        </h6>
                                        <div className="tools-new-design-right-sec-table no-thead-table">
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>Rooms</b>
                                                        </td>
                                                        <td className="text-end">53</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <h6 className="tools-new-design-right-sec-red-title">
                                            Rate Shop
                                        </h6>
                                        <div className="tools-new-design-right-sec-table no-thead-table">
                                            <Table responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>Comfort Inn...</b>
                                                        </td>
                                                        <td className="text-end">$87</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Avg Compititor</b>
                                                        </td>
                                                        <td className="text-end">$110</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Holiday Inn...</b>
                                                        </td>
                                                        <td className="text-end">$148</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>La Quinta...</b>
                                                        </td>
                                                        <td className="text-end">$112</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Quality Inn...</b>
                                                        </td>
                                                        <td className="text-end">$0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Super 8 By...</b>
                                                        </td>
                                                        <td className="text-end">$69</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {deleteModal && (
                <DeleteItemModal
                    showDeleteItemModal={deleteModal}
                    closeDeleteItemModal={closeDeleteModal}
                    id={deleteId}
                    getList={getEventList}
                    url={API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.DELETE}
                />
            )}
        </>
    );
}

export default ToolsEventModal;
