import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { useSelector } from "react-redux";
import { Button, Form, Overlay, Popover, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const LastYearAndForecast = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    dayName = false,
    lyRms = 0,
    lyAdr = 0,
    lyRev = 0,
    netStlyRms = 0,
    netStlyAdr = 0,
    netStlyRev = 0,
    lyForecastRms = 0,
    lyForecastAdr = 0,
    lyForecastRev = 0,
    budgetRms = 0,
    budgetAdr = 0,
    budgetRev = 0;
  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [lyData, setLyData] = useState(null);
  const [lyLoading, setLyLoading] = useState(false);
  const [netStlyData, setNetStlyData] = useState(null);
  const [netstlyLoading, setNetstlyLoading] = useState(false);
  const [selectedForecast, setSelectedForecast] = useState("EMA");
  const [lyForecastData, setLyForecastData] = useState(null);
  const [lyForecastLoading, setLyForecastLoading] = useState(false);
  const [lyBudgetLoading, setLyBudgetLoading] = useState(false);
  const [lyBudgetData, setLyBudgetData] = useState(null);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const startDateArrayRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const singleDateSelectedDateRef = useRef();
  const selectedTdIndexRef = useRef();
  const pickupDateRef = useRef();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const closeTicketModal = () => {
    setShowTicketModal(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // let datesArr = commonService.getDateBetweenTwoDates(event[0], event[1]);
      // startDateArrayRef.current = datesArr;
      // // call api when date changes
      // getORGDetail();
      // getLyDetail();
      // getNetStlyDetail();
      // getLastYearForecast();
      // getLyBudgetDetail();
    }
  };

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setLoading(true);
    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOrgData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getLyDetail = async () => {
    setLyLoading(true);
    let year = moment(startDate).toDate().getFullYear() - 1;
    let month = moment(startDate).toDate().getMonth();
    let date = moment(startDate).toDate().getDate();
    let d = new Date(year, month, date);

    if (startRangeDateRef.current) {
      let year = moment(startRangeDateRef.current).toDate().getFullYear() - 1;
      let month = moment(startRangeDateRef.current).toDate().getMonth();
      let date = moment(startRangeDateRef.current).toDate().getDate();
      d = new Date(year, month, date);
    }

    let endDateYear = moment(endDate).toDate().getFullYear() - 1;
    let endDateMonth = moment(endDate).toDate().getMonth();
    let endDateDate = moment(endDate).toDate().getDate();
    let ed = new Date(endDateYear, endDateMonth, endDateDate);

    if (endRangeDateRef.current) {
      let endDateYear =
        moment(endRangeDateRef.current).toDate().getFullYear() - 1;
      let endDateMonth = moment(endRangeDateRef.current).toDate().getMonth();
      let endDateDate = moment(endRangeDateRef.current).toDate().getDate();
      ed = new Date(endDateYear, endDateMonth, endDateDate);
    }
    let lyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.REV_PAK_90_DAY_OUTLOOK.GET_LY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? commonService.getDateForNode(
                commonService.convertDateInTimezone(d)
                // new Date(startRangeDateRef.current).setFullYear(
                //   new Date(startRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                commonService.convertDateInTimezone(d)
                // new Date(startDate).setFullYear(
                //   new Date(startDate).getFullYear() - 1
                // )
              ),
          endDate: endRangeDateRef.current
            ? commonService.getDateForNode(
                commonService.convertDateInTimezone(ed)
                // new Date(endRangeDateRef.current).setFullYear(
                //   new Date(endRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                commonService.convertDateInTimezone(ed)
                // new Date(endDate).setFullYear(
                //   new Date(endDate).getFullYear() - 1
                // )
              ),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, lyDataResponse);

    if (response?.isValidate) {
      setLyData(response?.data?.data);
      setLyLoading(false);
    }

    if (!response?.isValidate) {
      setLyLoading(false);
    }
  };

  const getNetStlyDetail = async () => {
    setNetstlyLoading(true);
    let year = moment(startDate).toDate().getFullYear() - 1;
    let month = moment(startDate).toDate().getMonth();
    let date = moment(startDate).toDate().getDate();
    let d = new Date(year, month, date);

    if (startRangeDateRef.current) {
      let year = moment(startRangeDateRef.current).toDate().getFullYear() - 1;
      let month = moment(startRangeDateRef.current).toDate().getMonth();
      let date = moment(startRangeDateRef.current).toDate().getDate();
      d = new Date(year, month, date);
    }

    let endDateYear = moment(endDate).toDate().getFullYear() - 1;
    let endDateMonth = moment(endDate).toDate().getMonth();
    let endDateDate = moment(endDate).toDate().getDate();
    let ed = new Date(endDateYear, endDateMonth, endDateDate);

    if (endRangeDateRef.current) {
      let endDateYear =
        moment(endRangeDateRef.current).toDate().getFullYear() - 1;
      let endDateMonth = moment(endRangeDateRef.current).toDate().getMonth();
      let endDateDate = moment(endRangeDateRef.current).toDate().getDate();
      ed = new Date(endDateYear, endDateMonth, endDateDate);
    }
    let lyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_NET_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? commonService.getDateForNode(
                commonService.convertDateInTimezone(d)
              )
            : commonService.getDateForNode(
                commonService.convertDateInTimezone(d)
              ),
          endDate: endRangeDateRef.current
            ? commonService.getDateForNode(
                commonService.convertDateInTimezone(ed)
              )
            : commonService.getDateForNode(
                commonService.convertDateInTimezone(ed)
              ),
          // startDate: startRangeDateRef.current
          //   ? commonService.getDateForNode(
          //       new Date(startRangeDateRef.current).setFullYear(
          //         new Date(startRangeDateRef.current).getFullYear() - 1
          //       )
          //     )
          //   : commonService.getDateForNode(
          //       new Date(startDate).setFullYear(
          //         new Date(startDate).getFullYear() - 1
          //       )
          //     ),
          // endDate: endRangeDateRef.current
          //   ? commonService.getDateForNode(
          //       new Date(endRangeDateRef.current).setFullYear(
          //         new Date(endRangeDateRef.current).getFullYear() - 1
          //       )
          //     )
          //   : commonService.getDateForNode(
          //       new Date(endDate).setFullYear(
          //         new Date(endDate).getFullYear() - 1
          //       )
          //     ),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, lyDataResponse);

    if (response?.isValidate) {
      setNetStlyData(response?.data?.data);
      setNetstlyLoading(false);
    }

    if (!response?.isValidate) {
      setNetstlyLoading(false);
    }
  };

  const getLastYearForecast = async () => {
    setLyForecastLoading(true);
    let lastYearResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.LAST_YEAR_AND_FORECAST.LAST_YEAR_AND_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: selectedForecast,
        },
      },
      false
    );

    let response = apiResponse(false, lastYearResponse);

    if (response?.isValidate) {
      setLyForecastData(response?.data?.data);
      setLyForecastLoading(false);
    }

    if (!response?.isValidate) {
      setLyForecastLoading(false);
    }
  };

  const getLyBudgetDetail = async () => {
    setLyBudgetLoading(true);
    let ltBudgetResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.LAST_YEAR_AND_FORECAST.GET_BUDGET_DATA,
        body: {
          propertyid: `${para?.propertyDetail?.propertyid}`,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, ltBudgetResponse);

    if (response?.isValidate) {
      setLyBudgetData(response?.data?.data);
      setLyBudgetLoading(false);
    }

    if (!response?.isValidate) {
      setLyBudgetLoading(false);
    }
  };

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    // setONTHEBOOKS({ IsDisplay: false, Property: { top: 0, left: 0 } });
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(startDate),
          commonService.convertDateInTimezone(endDate)
          // new Date(startDate),
          // new Date(endDate)
        );
        let date = new Date(para?.asOfDate);
        pickupDateRef.current = date.setDate(date.getDate() - 7);
        startDateArrayRef.current = datesArr;
        getORGDetail();
        getLyDetail();
        getNetStlyDetail();
        getLyBudgetDetail();
      }
    }
  }, [para?.asOfDate, propertyDetailFromRTK]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getLastYearForecast();
      }
    }
  }, [propertyDetailFromRTK, selectedForecast]);

  const [tblids, settblids] = useState({
    lastYearAndForecast: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.lastYearAndForecast} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "#63C384",
    "Red",
    `#${tblids.lastYearAndForecast} .perbackground-adr`
  );
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.lastYearAndForecast} .lastYearRms`
  );
  commonService.setBackColorPerByTDValue(
    "#63C384",
    "Red",
    `#${tblids.lastYearAndForecast} .lastYearAdr`
  );
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.lastYearAndForecast} .netStlyRms`
  );
  commonService.setBackColorPerByTDValue(
    "#63C384",
    "Red",
    `#${tblids.lastYearAndForecast} .netStlyAdr`
  );
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.lastYearAndForecast} .lyForecastRms`
  );
  commonService.setBackColorPerByTDValue(
    "#63C384",
    "Red",
    `#${tblids.lastYearAndForecast} .lyForecastAdr`
  );

  FixColumn(tblids.lastYearAndForecast, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.lastYearAndForecast,
                  "Last Year & Forecast",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.lastYearAndForecast,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.lastYearAndForecast}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.lastYearAndForecast,
                  // "Last Year & Forecast"
                  `${para?.propertyCode}-Last Year & Forecast-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.lastYearAndForecast}
              className="table-with-spacing positionStaticTable"
            >
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right">Date</th>
                  <th className="border_right text-center" colSpan={5}>
                    On The Books
                  </th>
                  <th className="border_right text-center" colSpan={2}>
                    Revenue
                  </th>
                  <th colSpan={6} className="text-center border_right">
                    Last Year & Var To LY
                  </th>
                  <th colSpan={6} className="text-center border_right">
                    Stly & Var To Net Stly
                  </th>
                  <th colSpan={6} className="text-center border_right">
                    Budget & Var To Budget
                  </th>
                  <th colSpan={6} className="text-center">
                    <div className="d-flex align-items-center gap-2">
                      <p>Forecast & Var to</p>
                      <div>
                        <Form.Select
                          value={selectedForecast}
                          onChange={(e) => {
                            setSelectedForecast(e.target.value);
                          }}
                        >
                          <option value="EMA">System Forecast</option>
                          <option value="RMS">RMS Forecast</option>
                          <option value="User">USER Forecast</option>
                        </Form.Select>
                        <span style={{ display: "none" }}>
                          {selectedForecast}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="border_right width175"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  let datesArr =
                                    commonService.getDateBetweenTwoDates(
                                      // new Date(startRangeDateRef.current),
                                      // new Date(endRangeDateRef.current)
                                      commonService.convertDateInTimezone(
                                        startRangeDateRef.current
                                      ),
                                      commonService.convertDateInTimezone(
                                        endRangeDateRef.current
                                      )
                                    );
                                  startDateArrayRef.current = datesArr;
                                  // call api when date changes
                                  getORGDetail();
                                  getLyDetail();
                                  getNetStlyDetail();
                                  getLastYearForecast();
                                  getLyBudgetDetail();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                    {/*  */}
                  </th>
                  <th className="width100">OOO/Rooms Available</th>
                  <th className="width75 text-center ">Left To sell</th>
                  <th className="width75 text-center">On The Books</th>
                  <th className="width75 text-center">Total OCC Percentage</th>
                  <th className="text-end border_right">ADR</th>
                  <th className="text-end">Rev</th>
                  <th className="text-end border_right">RevPAR</th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-center"></th>
                  <th className="text-end">ADR</th>
                  <th className="text-end"></th>
                  <th className="text-end">REV</th>
                  <th className="text-end"></th>
                </tr>
              </thead>
              <tbody>
                {loading === true ||
                lyLoading === true ||
                netstlyLoading === true ||
                lyForecastLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  // orgData?.map((item, index) => {
                  startDateArrayRef.current?.map((item, index) => {
                    // ooo = ooo + item?.OOO;
                    // RmsAvailable = RmsAvailable + item?.RoomAvailable;
                    // leftToSell = leftToSell + item?.LeftToSell;
                    // otb = otb + item?.OnTheBook;
                    // occPercentage = occPercentage + item?.TotalOCCPercentage;
                    // adr = adr + item?.ADR;
                    // rev = rev + item?.REV;
                    // revPar = revPar + item?.RevPAR;
                    dayName = false;
                    if (
                      moment(
                        // new Date(item),
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        // new Date(item)
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let singleOTBData = orgData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                      // moment(startDateArrayRef.current[index]).format(
                      //   "YYYY-MM-DD"
                      // )
                    );

                    ooo =
                      ooo +
                      (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);
                    RmsAvailable =
                      RmsAvailable +
                      (singleOTBData?.[0]?.RoomAvailable
                        ? singleOTBData?.[0]?.RoomAvailable
                        : 0);
                    leftToSell =
                      leftToSell +
                      (singleOTBData?.[0]?.LeftToSell
                        ? singleOTBData?.[0]?.LeftToSell
                        : 0);
                    otb =
                      otb +
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0);
                    occPercentage =
                      occPercentage +
                      (singleOTBData?.[0]?.TotalOCCPercentage
                        ? singleOTBData?.[0]?.TotalOCCPercentage
                        : 0);
                    adr =
                      adr +
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0);
                    rev =
                      rev +
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0);
                    revPar =
                      revPar +
                      (singleOTBData?.[0]?.RevPAR
                        ? singleOTBData?.[0]?.RevPAR
                        : 0);

                    let singleLyData = lyData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(
                          // new Date(startDateArrayRef.current[index]).setFullYear(
                          //   new Date(
                          //     startDateArrayRef.current[index]
                          //   ).getFullYear() - 1
                          // )
                          new Date(item).setFullYear(
                            new Date(item).getFullYear() - 1
                          )
                        ).format("YYYY-MM-DD")
                    );

                    let singleNetStlyData = netStlyData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(
                          // new Date(startDateArrayRef.current[index]).setFullYear(
                          //   new Date(
                          //     startDateArrayRef.current[index]
                          //   ).getFullYear() - 1
                          // )
                          new Date(item).setFullYear(
                            new Date(item).getFullYear() - 1
                          )
                        ).format("YYYY-MM-DD")
                    );

                    let lyForecast = lyForecastData?.filter(
                      (x) =>
                        moment(x.Dates).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                      // moment(startDateArrayRef.current[index]).format(
                      //   "YYYY-MM-DD"
                      // )
                    );

                    let budgetData = lyBudgetData?.filter(
                      (x) =>
                        moment(x?.budgetdate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                      // moment(startDateArrayRef.current[index]).format(
                      //   "YYYY-MM-DD"
                      // )
                    );

                    let lyVarientRms =
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0) -
                      (singleLyData?.[0]?.RMS ? singleLyData?.[0]?.RMS : 0);

                    let lyVarientAdr =
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0) -
                      (singleLyData?.[0]?.ADR ? singleLyData?.[0]?.ADR : 0);

                    let lyVarientRev =
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0) -
                      (singleLyData?.[0]?.REV ? singleLyData?.[0]?.REV : 0);

                    let netStlyVarientRms =
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0) -
                      (singleNetStlyData?.[0]?.RMS
                        ? singleNetStlyData?.[0]?.RMS
                        : 0);

                    let netStlyVarientAdr =
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0) -
                      (singleNetStlyData?.[0]?.ADR
                        ? singleNetStlyData?.[0]?.ADR
                        : 0);

                    let netStlyVarientRev =
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0) -
                      (singleNetStlyData?.[0]?.REV
                        ? singleNetStlyData?.[0]?.REV
                        : 0);

                    lyRms =
                      lyRms +
                      (singleLyData?.[0]?.RMS ? singleLyData?.[0]?.RMS : 0);
                    lyAdr =
                      lyAdr +
                      (singleLyData?.[0]?.ADR ? singleLyData?.[0]?.ADR : 0);
                    lyRev =
                      lyRev +
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0);

                    netStlyRms =
                      netStlyRms +
                      (singleNetStlyData?.[0]?.RMS
                        ? parseInt(singleNetStlyData?.[0]?.RMS)
                        : 0);

                    netStlyAdr =
                      netStlyAdr +
                      (singleNetStlyData?.[0]?.ADR
                        ? parseInt(singleNetStlyData?.[0]?.ADR)
                        : 0);

                    netStlyRev =
                      netStlyRev +
                      (singleNetStlyData?.[0]?.REV
                        ? parseInt(singleNetStlyData?.[0]?.REV)
                        : 0);

                    lyForecastRms =
                      lyForecastRms +
                      (lyForecast?.[0]?.RMS ? lyForecast?.[0]?.RMS : 0);
                    lyForecastRev =
                      lyForecastRev +
                      (lyForecast?.[0]?.REV ? lyForecast?.[0]?.REV : 0);

                    let lyForecastVarientRms =
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0) -
                      (lyForecast?.[0]?.RMS ? lyForecast?.[0]?.RMS : 0);

                    let lyForecastVarientAdr =
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0) -
                      (lyForecast?.[0]?.ADR ? lyForecast?.[0]?.ADR : 0);

                    let lyForecastVarientRev =
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0) -
                      (lyForecast?.[0]?.REV ? lyForecast?.[0]?.REV : 0);

                    budgetRms =
                      budgetRms +
                      (budgetData?.[0]?.RMS
                        ? parseInt(budgetData?.[0]?.RMS)
                        : 0);
                    budgetAdr =
                      budgetAdr +
                      (budgetData?.[0]?.ADR
                        ? parseInt(budgetData?.[0]?.ADR)
                        : 0);
                    budgetRev =
                      budgetRev +
                      (budgetData?.[0]?.REV
                        ? parseInt(budgetData?.[0]?.REV)
                        : 0);

                    let budgetVarientRms =
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0) -
                      (budgetData?.[0]?.RMS
                        ? parseInt(budgetData?.[0]?.RMS)
                        : 0);

                    let budgetVarientAdr =
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0) -
                      (budgetData?.[0]?.ADR
                        ? parseInt(budgetData?.[0]?.ADR)
                        : 0);

                    let budgetVarientRev =
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0) -
                      (budgetData?.[0]?.REV
                        ? parseInt(budgetData?.[0]?.REV)
                        : 0);

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    return (
                      <React.Fragment key={index}>
                        <tr
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td className="">
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100 border-right">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.OOO
                                )
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.RoomAvailable
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground perbackground-otb"
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item;
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.OnTheBook
                                  )
                                : 0}
                            </span>
                          </td>

                          <td>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? singleOTBData?.[0]?.TotalOCCPercentage
                              : 0}
                            %
                          </td>
                          <td
                            className="text-end perbackground perbackground-adr"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border-right">
                            {singleOTBData?.[0]?.REV
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleOTBData?.[0]?.RevPAR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className="text-center perbackground lastYearRms"
                            value={singleLyData?.[0]?.RMS}
                          >
                            {singleLyData?.[0]?.RMS
                              ? commonService.formateRoom(
                                  singleLyData?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              lyVarientRms < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyVarientRms !== 0
                              ? commonService.formateRoom(lyVarientRms)
                              : ""}
                          </td>
                          <td
                            className="text-end perbackground lastYearAdr"
                            value={singleLyData?.[0]?.ADR}
                          >
                            {singleLyData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singleLyData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              lyVarientAdr < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyVarientAdr !== 0
                              ? `${commonService.formateAmount(lyVarientAdr)}`
                              : ""}
                          </td>
                          <td className="text-end">
                            {singleLyData?.[0]?.REV
                              ? commonService.formateAmount(
                                  singleLyData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              lyVarientRev < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyVarientRev !== 0
                              ? `${commonService.formateAmount(lyVarientRev)}`
                              : ""}
                          </td>

                          <td
                            className="text-center perbackground netStlyRms"
                            value={singleNetStlyData?.[0]?.RMS}
                          >
                            {singleNetStlyData?.[0]?.RMS
                              ? commonService.formateRoom(
                                  singleNetStlyData?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              netStlyVarientRms < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {netStlyVarientRms !== 0
                              ? commonService.formateRoom(netStlyVarientRms)
                              : ""}
                          </td>
                          <td
                            className="text-end perbackground netStlyAdr"
                            value={singleNetStlyData?.[0]?.ADR}
                          >
                            {singleNetStlyData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singleNetStlyData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              netStlyVarientAdr < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {netStlyVarientAdr !== 0
                              ? `${commonService.formateAmount(
                                  netStlyVarientAdr
                                )}`
                              : ""}
                          </td>
                          <td className="text-end">
                            {singleNetStlyData?.[0]?.REV
                              ? commonService.formateAmount(
                                  singleNetStlyData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              netStlyVarientRev < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {netStlyVarientRev !== 0
                              ? `${commonService.formateAmount(
                                  netStlyVarientRev
                                )}`
                              : ""}
                          </td>

                          <td className="text-center">
                            {budgetData?.[0]?.RMS
                              ? commonService.formateRoom(budgetData?.[0]?.RMS)
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              budgetVarientRms < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {budgetVarientRms !== 0
                              ? commonService.formateRoom(budgetVarientRms)
                              : ""}
                          </td>
                          <td className="text-end">
                            {budgetData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  budgetData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              budgetVarientAdr < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {budgetVarientAdr !== 0
                              ? `${commonService.formateAmount(
                                  budgetVarientAdr
                                )}`
                              : ""}
                          </td>
                          <td className="text-end">
                            {budgetData?.[0]?.REV
                              ? commonService.formateAmount(
                                  budgetData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size border_right ${
                              budgetVarientRev < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {budgetVarientRev !== 0
                              ? `${commonService.formateAmount(
                                  budgetVarientRev
                                )}`
                              : ""}
                          </td>

                          <td
                            className="text-center perbackground lyForecastRms"
                            value={lyForecast?.[0]?.RMS}
                          >
                            {lyForecast?.[0]?.RMS
                              ? commonService.formateRoom(lyForecast?.[0]?.RMS)
                              : 0}
                          </td>
                          <td
                            className={`text-center variance-font-size ${
                              lyForecastVarientRms < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyForecastVarientRms !== 0
                              ? commonService.formateRoom(lyForecastVarientRms)
                              : ""}
                          </td>
                          <td
                            className="text-end perbackground lyForecastAdr"
                            value={lyForecast?.[0]?.ADR}
                          >
                            {lyForecast?.[0]?.ADR
                              ? commonService.formateAmount(
                                  lyForecast?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              lyForecastVarientAdr < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyForecastVarientAdr !== 0
                              ? `${commonService.formateAmount(
                                  lyForecastVarientAdr
                                )}`
                              : ""}
                          </td>
                          <td className="text-end">
                            {lyForecast?.[0]?.REV
                              ? commonService.formateAmount(
                                  lyForecast?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className={`text-end variance-font-size ${
                              lyForecastVarientRev < 0 ? "redText" : "greenText"
                            }`}
                          >
                            {lyForecastVarientRev !== 0
                              ? `${commonService.formateAmount(
                                  lyForecastVarientRev
                                )}`
                              : ""}
                          </td>
                        </tr>
                        {index === startDateArrayRef.current?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd border_right">Total</td>
                            <td className="boldTd">
                              {commonService.formateRoom(ooo)}/
                              {commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(leftToSell)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb)}
                            </td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end border_right">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    parseInt(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(lyRms)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb - lyRms)}
                            </td>
                            <td className="text-end boldTd">
                              {lyRev > 0 && lyRms > 0
                                ? commonService.formateAmount(
                                    parseInt(lyRev / lyRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-end boldTd">
                              {commonService.formateAmount(
                                (rev > 0 && otb > 0 ? parseInt(rev / otb) : 0) -
                                  (lyRev > 0 && lyRms > 0
                                    ? parseInt(lyRev / lyRms)
                                    : 0)
                              )}
                            </td>
                            <td className="text-end boldTd">
                              {commonService.formateAmount(lyRev)}
                            </td>
                            <td className="text-end boldTd border_right">
                              {commonService.formateAmount(rev - lyRev)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(netStlyRms)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb - netStlyRms)}
                            </td>
                            <td className="boldTd text-end">
                              {netStlyRev > 0 && netStlyRms > 0
                                ? commonService.formateAmount(
                                    parseInt(netStlyRev / netStlyRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(
                                (rev > 0 && otb > 0 ? parseInt(rev / otb) : 0) -
                                  (netStlyRev > 0 && netStlyRms > 0
                                    ? parseInt(netStlyRev / netStlyRms)
                                    : 0)
                              )}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(netStlyRev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {commonService.formateAmount(rev - netStlyRev)}
                            </td>

                            <td className="boldTd text-center">
                              {commonService.formateRoom(budgetRms)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb - budgetRms)}
                            </td>
                            <td className="boldTd text-end">
                              {budgetRev > 0 && budgetRms > 0
                                ? commonService.formateAmount(
                                    parseInt(budgetRev / budgetRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(
                                (rev > 0 && otb > 0 ? parseInt(rev / otb) : 0) -
                                  (budgetRev > 0 && budgetRms > 0
                                    ? parseInt(budgetRev / budgetRms)
                                    : 0)
                              )}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(budgetRev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {commonService.formateAmount(rev - budgetRev)}
                            </td>

                            <td className="text-center boldTd">
                              {commonService.formateRoom(lyForecastRms)}
                            </td>
                            <td className="text-center boldTd">
                              {commonService.formateRoom(otb - lyForecastRms)}
                            </td>
                            <td className="boldTd text-end">
                              {lyForecastRev > 0 && lyForecastRms > 0
                                ? commonService.formateAmount(
                                    parseInt(lyForecastRev / lyForecastRms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(
                                (rev > 0 && otb > 0 ? parseInt(rev / otb) : 0) -
                                  (lyForecastRev > 0 && lyForecastRms > 0
                                    ? parseInt(lyForecastRev / lyForecastRms)
                                    : 0)
                              )}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(lyForecastRev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {commonService.formateAmount(rev - lyForecastRev)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"LastYearAndForecast"} />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={pickupDateRef.current}
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default LastYearAndForecast;
