import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Container, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import darkNoteIcon from "../../../../Assets/Images/CRM/darkNotes.svg";
import WidgetNote from "../.././WidgetNote";

const PerityCheck = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [perityCheckData, setPerityCheckData] = useState(null);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const channelRef = useRef([]);
  const startDateDefault = new Date();
  const endDateDefault = new Date();
  endDateDefault.setDate(endDateDefault.getDate() + 14);
  // const endDateDefault = new Date(
  //   startDateDefault.getFullYear(),
  //   startDateDefault.getMonth() + 1,
  //   0
  // );
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const clickedDateForNote = useRef();
  const [tblids, settblids] = useState({
    tblPerityCheck: commonService.GenerateGUID(),
  });
  const [noteDetail, setNoteDetail] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getPerityCheckData = async () => {
    setLoading(true);
    let perityCheckResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.GET_PERITY_CHECK,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDate),
          endDate: commonService.getDateForNode(endDate),
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, perityCheckResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      setPerityCheckData(dataFromApi);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setPerityCheckData(null);
      setLoading(false);
    }
  };

  const getNoteByDataList = async () => {
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          // widgetid: para?.widgetId,
          widgetid: 0,
          startdate: commonService.getDateInDBFormat(startDate),
          enddate: commonService.getDateInDBFormat(endDate),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    if (response?.isValidate) {
      setNoteDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
    }
  };

  useEffect(() => {
    if (startDate && endDate && api !== 0 && para?.asOfDate) {
      getPerityCheckData();
      getNoteByDataList();
    }
  }, [startDate, endDate, propertyDetailFromRTK, para?.asOfDate]);

  return (
    <div className="RMRateShopMain">
      {propertyDetailFromRTK ? (
        <Container fluid>
          <div className="form-groups from-groups no-border-break meghaDatePicker width225">
            <DatePicker
              onChange={handleDateChange}
              startDate={startDate}
              minDate={new Date()}
              maxDate={new Date().setDate(new Date().getDate() + 90)}
              endDate={endDate}
              selectsRange
              monthsShown={2}
            />
          </div>
          <div className="ViewPropertySetupTable mt-2">
            <div className="stripped__table">
              <div className="table-responsive tblDynamicPickupSticky">
                <Table id={tblids.tblPerityCheck}>
                  <thead className="tbl-header-sticky">
                    <tr>
                      <th className="width150">Date</th>
                      {perityCheckData &&
                        Object.entries(perityCheckData?.[0])?.map(
                          ([key, value]) => {
                            if (key !== "AsOfDate" && key !== "Dates") {
                              return (
                                <th className="width100 text-center">{key}</th>
                              );
                            }
                          }
                        )}
                      {/* <th className="width150 text-center">
                        Lowest Rate On Metasearch
                      </th>
                      <th>Loss channels on Metasearch</th> */}
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : perityCheckData ? (
                      perityCheckData?.map((item) => {
                        let checkNoteExists = noteDetail?.notedata?.filter(
                          (x) =>
                            moment(x?.datadate).format("YYYY-MM-DD") ===
                            moment(item?.Dates).format("YYYY-MM-DD")
                        );
                        return (
                          <tr className="shadowOnHover">
                            <td className="border_right">
                              {commonService.getDateInFormat(item?.Dates)}{" "}
                              {moment(new Date(item?.Dates), "l").format("ddd")}
                            </td>
                            {Object?.entries(item)?.map(([key, value]) => {
                              if (key !== "AsOfDate" && key !== "Dates") {
                                return (
                                  <td
                                    className={`text-center ${
                                      Math.round(item?.Brand) !==
                                      Math.round(value)
                                        ? "redText"
                                        : ""
                                    }`}
                                  >
                                    {value ? Math.round(value) : 0}
                                  </td>
                                );
                              }
                            })}
                            {/* <td className="text-center border-right">0</td>
                            <td>-</td> */}
                            <td>
                              {checkNoteExists?.length > 0 ? (
                                <img
                                  className="cursorPointer"
                                  src={darkNoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item?.Dates;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              ) : (
                                <img
                                  className="cursorPointer"
                                  src={NoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item?.Dates;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
      {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          // widgetId={117}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={"Perity Check"}
          dateForNote={clickedDateForNote.current}
        />
      )}
    </div>
  );
};

export default PerityCheck;
