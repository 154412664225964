import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { Table } from "react-bootstrap";

const SentimateReviewCount = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [loading, setLoading] = useState(false);
  const [selfPropertyData, setSelfPropertyData] = useState(null);
  const [sentimateReviewData, setSentimateReviewData] = useState(null);

  const getSentimateReview = async () => {
    setLoading(true);
    let sentimateReviewResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.ANALYTICS.SENTIMATE_REVIEW_COUNT,
        body: {
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, sentimateReviewResponse);

    if (response?.isValidate) {
      let findSelfPropertyData = response?.data?.data?.filter(
        (x) => x?.propertyname === propertyDetailFromRTK?.propertyname
      );
      setSelfPropertyData(findSelfPropertyData);
      setSentimateReviewData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSelfPropertyData(null);
      setSentimateReviewData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.propertyCode) {
        getSentimateReview();
      }
    }
  }, [para?.propertyCode]);

  return (
    <div>
      <div className="table__container mt-3">
        <div className="stripped__table">
          <div className="table-responsive">
            <Table>
              <thead className="tbl-header-sticky my-tbl-header-sticky">
                <tr>
                  <th>Property</th>
                  <th className="width100">Positive</th>
                  <th className="width100">Negative</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  <>
                    {selfPropertyData?.length > 0 && (
                      <tr className="shadowOnHover">
                        <td className="fontBold">
                          {selfPropertyData?.[0]?.propertyname}
                        </td>
                        <td className="fontBold">
                          {selfPropertyData?.[0]?.positive_count}
                        </td>
                        <td className="fontBold">
                          {selfPropertyData?.[0]?.negative_count}
                        </td>
                      </tr>
                    )}
                    {sentimateReviewData?.map((item) => {
                      if (
                        item?.propertyname !==
                        propertyDetailFromRTK?.propertyname
                      ) {
                        return (
                          <tr
                            key={item?.propertyname}
                            className="shadowOnHover"
                          >
                            <td>{item?.propertyname}</td>
                            <td>{item?.positive_count}</td>
                            <td>{item?.negative_count}</td>
                          </tr>
                        );
                      }
                    })}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentimateReviewCount;
