import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import emailIcon from "../../../../Assets/Images/Email.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import Comments from "../Comments/Comment";
import moment from "moment";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import { compName } from "../../../../utils/constant";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const PerityCheck = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [perityCheckData, setPerityCheckData] = useState(null);
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const [widgetModal, setWidgetModal] = useState(false);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const channelRef = useRef([]);
  const widgetDetailRef = useRef();
  const startDateDefault = new Date();
  const endDateDefault = new Date();
  endDateDefault.setDate(endDateDefault.getDate() + 14);
  // const endDateDefault = new Date(
  //   startDateDefault.getFullYear(),
  //   startDateDefault.getMonth() + 1,
  //   0
  // );
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);
  const [tblids, settblids] = useState({
    tblPerityCheck: commonService.GenerateGUID(),
  });

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getPerityCheckData = async () => {
    setLoading(true);
    let perityCheckResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.GET_PERITY_CHECK,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDate),
          endDate: commonService.getDateForNode(endDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, perityCheckResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = {};
      dataFromApi?.forEach((item) => {
        let checkChannelExists = channelRef.current?.filter(
          (x) => x === item?.Channel
        );

        if (checkChannelExists?.length <= 0) {
          channelRef.current = [...channelRef.current, item?.Channel];
        }

        if (groupingJson?.hasOwnProperty(item?.CheckInDate)) {
        } else {
          let findCheckInDateData = dataFromApi?.filter(
            (x) => x?.CheckInDate === item?.CheckInDate
          );
          let obj = {};
          findCheckInDateData?.forEach((channelItem) => {
            obj[channelItem.Channel] = channelItem?.Rate;
          });
          groupingJson = { ...groupingJson, [item?.CheckInDate]: obj };
        }
      });
      setPerityCheckData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setPerityCheckData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api !== 0 && startDate && endDate) {
      getPerityCheckData();
    }
  }, [startDate, endDate]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblPerityCheck,
                  "Perity Check",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.perityCheck,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblPerityCheck}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblPerityCheck,
                  // "Perity Check"
                  `${para?.propertyCode}-Perity Check-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="form-groups from-groups no-border-break meghaDatePicker width225">
        <DatePicker
          onChange={handleDateChange}
          startDate={startDate}
          minDate={new Date()}
          maxDate={new Date().setDate(new Date().getDate() + 90)}
          endDate={endDate}
          selectsRange
          monthsShown={2}
        />
      </div>
      <div className="ViewPropertySetupTable mt-2">
        <div className="stripped__table">
          <Table id={tblids.tblPerityCheck} responsive>
            <thead>
              <tr>
                <th className="width150">Date</th>
                {channelRef.current?.map((item) => {
                  return (
                    <th
                      key={`channelHeader${item}`}
                      className="width100 text-center"
                    >
                      {item}
                    </th>
                  );
                })}
                <th className="width150 text-center">
                  Lowest Rate On Metasearch
                </th>
                <th>Loss channels on Metasearch</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : perityCheckData &&
                Object?.keys(perityCheckData)?.length > 0 ? (
                Object?.entries(perityCheckData)?.map(([key, value]) => {
                  return (
                    <tr>
                      <td className="border_right">
                        {commonService.getDateInFormat(key)}{" "}
                        {moment(new Date(key), "l").format("ddd")}
                      </td>
                      {channelRef.current?.map((item) => {
                        return (
                          <td className="text-center">
                            {commonService.formateAmount(value[item])}
                          </td>
                        );
                      })}
                      <td className="text-center border-right">0</td>
                      <td>-</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"PerityCheck"} />}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default PerityCheck;
