import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { commonService } from "../../../../utils/commonService";
import { Button, Col, Form } from "react-bootstrap";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { compName } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";
import { setBookingPaceMonth } from "../../../../utils/slices/headerPropertySlice";
import moment from "moment";

const BookinPaceChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const dispatch = useDispatch();
  let datesArray = commonService.getAllDatesInMonth(
    new Date(para?.asOfDate).getFullYear(),
    new Date(para?.asOfDate).getMonth()
  );
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const bookingPaceMonth = useSelector(
    (store) => store.headerProperty.bookingPaceMonth
  );
  const defaultAsOfDateRtk = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [chartData, setChartData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [monthList, setMonthList] = useState(null);

  let prevYear = {
    label: "Prev Year",
    backgroundColor: "#97C2E0",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack1",
  };

  let currentYear = {
    label: "Current Year",
    backgroundColor: "#7ACFA2",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack2",
  };

  const getBookingPaceDetail = async () => {
    let bookingPaceResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.CHART,
        body: {
          AsOfDate: bookingPaceMonth ? bookingPaceMonth : para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, bookingPaceResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      datesArray?.map((item) => {
        // let date = item > 9 ? item : `0${item}`;
        let findData = dataFromApi?.filter((x) => {
          if (
            commonService.convertDateInTimezone(x?.Dates).getDate() === item
          ) {
            return x;
          }
          // if (new Date(x?.Dates).getDate() === item) {
          //   return x;
          // }
        });
        prevYear.data = [
          ...prevYear.data,
          findData?.[0]?.TotalRevenuePreviousYear
            ? findData?.[0]?.TotalRevenuePreviousYear
            : 0,
        ];
        currentYear.data = [
          ...currentYear.data,
          findData?.[0]?.TotalRevenueCurrentYear
            ? findData?.[0]?.TotalRevenueCurrentYear
            : 0,
        ];
      });

      setChartData([prevYear, currentYear]);
    }

    if (!response?.isValidate) {
      prevYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      currentYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      setChartData([prevYear, currentYear]);
    }
  };
  let chartDetail = {};
  if (chartData) {
    chartDetail = {
      labels: datesArray,
      datasets: [
        {
          type: "line",
          label: `${new Date().getFullYear() - 1}`,
          backgroundColor: chartData?.[0]?.backgroundColor,
          data: chartData?.[0]?.data,
          stack: chartData?.[0]?.stack || "default",
          fill: {
            target: "origin",
            above: "rgba(151, 194, 224, 0.4)",
          },
        },
        {
          type: "line",
          label: `${new Date().getFullYear()}`,
          backgroundColor: chartData?.[1]?.backgroundColor,
          data: chartData?.[1]?.data,
          // fill: true,
          fill: {
            target: "origin",
            above: "rgba(122, 207, 162, 0.4)",
          },
          stack: chartData?.[1]?.stack || "default",
        },
      ],
    };
  }

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        // beginAtZero: true,
        // max: 120000,
        stacked: true,
        title: {
          display: true,
          text: "Date",
        },
        // categorySpacing: 100000,
        // ticks: {
        //   callback: function (value) {
        //     return value+"dfd";
        //   },
        // },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Revenue",
        },
        grid: {
          display: true,
        },
        offset: true,
        // ticks: {
        //   align: "start",
        //   autoSkip: false,
        // },
        ticks: {
          callback: function (value) {
            // return propertyDetailFromRTK?. + value;
            return `${
              propertyDetailFromRTK?.currency_term
                ? propertyDetailFromRTK?.currency_term
                : "$"
            }${value}`;
          },
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            // You can provide a title for the tooltip here
            return context[0].label;
          },
          label: function (context) {
            // Use context.datasetIndex to differentiate between datasets
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    // aspectRatio: 1,
    responsive: true,
    // maintainAspectRatio: false,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  useEffect(() => {
    if (api === 0) {
      prevYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      currentYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      setChartData([prevYear, currentYear]);
    } else {
      if (para?.asOfDate) {
        if (!monthList) {
          let monthsData = commonService.getMonthNamesUntil(para?.asOfDate);
          setMonthList(monthsData);
        }
        if (!bookingPaceMonth) {
          setSelectedMonth("");
        }

        getBookingPaceDetail();
      }
    }
  }, [para?.asOfDate]);

  useEffect(() => {
    if (bookingPaceMonth) {
      getBookingPaceDetail();
    }
  }, [bookingPaceMonth]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.bookingPaceChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Form.Select
        className="width150 mb-2"
        value={selectedMonth}
        onChange={(e) => {
          let lastDate = "";
          if (e.target.value !== "") {
            const date = moment(
              `${e.target.value} ${new Date().getFullYear()}`,
              "MMMM YYYY"
            );
            lastDate = date.endOf("month").format("YYYY-MM-DD");
            if (moment(defaultAsOfDateRtk).isSameOrBefore(lastDate)) {
              lastDate = defaultAsOfDateRtk;
            }
          }
          dispatch(setBookingPaceMonth(lastDate));
          setSelectedMonth(e.target.value);
        }}
      >
        <option value="">Select Month</option>
        {monthList?.map((item, index) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Form.Select>
      {chartData && (
        <>
          <div className="ForecastHorizontalChartInner">
            <Line data={chartDetail} options={chartOptions} />
          </div>
          {api !== 0 && (
            <Comments widgetDetails={para} editorId={"BookingPaceChart"} />
          )}
        </>
      )}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default BookinPaceChart;
