import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Overlay,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import calendarIcon from "../../Assets/Images/Revenue-management/calendarIcon.svg";
import horizobtalBarIcon from "../../Assets/Images/Revenue-management/horizontal-bars.svg";
import { commonService } from "../../utils/commonService";
import premonth from "../../Assets/Images/Revenue-management/preMonth.svg";
import nextmonth from "../../Assets/Images/Revenue-management/nextMonth.svg";
import { API_URL } from "../../utils/apiRoutes";
import { errorToastMessage } from "../../utils/toastMessage";
import { apiCall } from "../../utils/axiosService";
import { apiResponse } from "../../utils/apiResponse";
import moment from "moment";
import DatePicker from "react-datepicker";
import ticket from "../../Assets/Images/Revenue-management/ticket.svg";
import alarm from "../../Assets/Images/Revenue-management/alarm.svg";
import ToolsSingleTicketPopUp from "../CommonComponent/ToolsSingleTicketPopUp";
import ToolsSummaryModal from "./ToolsSummaryModal";
import viewIcon from "../../Assets/Images/Action Icons/viewaction.svg";
import RateIqViewModal from "./RateIqViewModal";
import EventViewModal from "../CommonComponent/EventViewModal";

const RMRateIqCalenderView = () => {
  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 2, 0)
    )
  );
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  const [dateRange, setDateRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tableData, setTableData] = useState(null);
  const [checkIn, setCheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [night, setNight] = useState(1);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const reminderListRef = useRef();
  const eventListRef = useRef();
  const isReminderRef = useRef();
  const eventListPropsRef = useRef();
  const reminderListPropsRef = useRef();
  const [showToolsSummaryModal, setToolsSummaryModal] = useState(false);
  const [ClickedDate, setClickedDate] = useState(new Date());
  const [datesArr, setDatesArr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewReminderModal, setViewReminderModal] = useState(false);
  const clickedDateRef = useRef();
  const selectedEventDateRef = useRef();

  const closeViewReminderModal = () => {
    setViewReminderModal(false);
  };

  const closeToolsSummaryModal = () => {
    setToolsSummaryModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const closeRateIqViewModal = () => {
    setRateIqViewModal(false);
  };

  const calculateMyRate = (currentDate) => {
    let myRate = 0;

    let filterOutData = rateGainData?.filter((item) => {
      if (
        commonService.getDateInFormat(item?.CheckInDate) ===
          commonService.getDateInFormat(currentDate) &&
        item?.isself === true
      ) {
        return item;
      }
    });

    myRate = filterOutData?.[0]?.Rate ? filterOutData?.[0]?.Rate : 0;
    filterOutData?.forEach((item) => {
      if (myRate > item?.Rate) {
        myRate = item?.Rate;
      }
    });

    return myRate;
  };

  const calculateCompetitorAvg = (currentDate) => {
    let avgRate = 0;

    let filterOutData = rateGainData?.filter((item) => {
      if (
        commonService.getDateInFormat(item?.CheckInDate) ===
          commonService.getDateInFormat(currentDate) &&
        item?.isself === false
      ) {
        return item;
      }
    });

    let obj = {};
    filterOutData?.forEach((item) => {
      if (obj.hasOwnProperty([item?.RGPropertyID])) {
        if (item?.Rate < obj[item?.RGPropertyID]) {
          obj[item?.RGPropertyID] = item?.Rate;
        }
      } else {
        obj[item?.RGPropertyID] = item?.Rate;
      }
    });
    let countForPropertyAvg = 0,
      totalOfCompetitorPropertyRate = 0;
    Object?.entries(obj)?.map(([key, value]) => {
      if (
        value !== null &&
        value !== 0 &&
        value !== "" &&
        value !== undefined
      ) {
        countForPropertyAvg++;
        totalOfCompetitorPropertyRate = totalOfCompetitorPropertyRate + value;
      }
    });
    avgRate =
      countForPropertyAvg > 0
        ? Math.round(totalOfCompetitorPropertyRate / countForPropertyAvg)
        : 0;
    return avgRate;
  };

  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    clickedDateRef.current = "";
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const renderCalendar = (calDate) => {
    const calendar = [];
    let MonthFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    let MonthLastDate = new Date(
      calDate.getFullYear(),
      calDate.getMonth() + 1,
      0
    );

    let CalFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    CalFirstDate = new Date(
      CalFirstDate.setDate(CalFirstDate.getDate() - CalFirstDate.getDay())
    );

    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        let DTDate = commonService.convertDateInTimezone(CalFirstDate);
        let borderColor = "",
          showBorder = false,
          style = "",
          showThreeDot = false;

        if (CalFirstDate >= MonthFirstDate && MonthFirstDate <= MonthLastDate) {
        }

        let dayDetail = tableData?.filter(
          (x) =>
            moment(x?.Dates).format("YYYY-MM-DD") ===
            moment(DTDate).format("YYYY-MM-DD")
        );

        let isApplyBgColor = datesArr?.filter(
          (x) =>
            moment(x).format("YYYY-MM-DD") ===
            moment(DTDate).format("YYYY-MM-DD")
        );

        let checkEvent = eventListRef.current?.filter((x) => {
          let checkingDate = moment(DTDate);
          let startingDate = moment(x?.startDate);
          let endingDate = moment(x?.endDate);
          if (
            checkingDate.isSameOrAfter(startingDate) &&
            checkingDate.isSameOrBefore(endingDate)
          ) {
            return x;
          }
        });

        let checkReminder = reminderListRef.current?.filter((x) => {
          let checkingDate = moment(DTDate);
          let startingDate = moment(x?.startdate);
          let endingDate = moment(x?.enddate);
          if (
            checkingDate.isSameOrAfter(startingDate) &&
            checkingDate.isSameOrBefore(endingDate)
          ) {
            return x;
          }
        });

        let myRateByCalculation = dayDetail?.[0]?.["predicted rate"];

        if (!calculationDetailRef.current[DTDate]) {
          calculationDetailRef.current[DTDate] = {
            0: {
              type: "Predicated Rate",
              calculation: `${commonService.formateAmount(
                dayDetail?.[0]?.["predicted rate"]
              )}`,
            },
          };
        }

        const calculationForMyRate = (rate, type, order) => {
          let myRateCalc = (myRateByCalculation * Math.abs(rate)) / 100;

          if (rate > 0) {
            myRateByCalculation = myRateByCalculation + myRateCalc;
            calculationDetailRef.current[DTDate] = {
              ...calculationDetailRef.current[DTDate],
              [order]: {
                type,
                calculation: `+${commonService.formateAmount(
                  myRateCalc?.toFixed(2)
                )}`,
              },
            };
          } else {
            myRateByCalculation = myRateByCalculation - myRateCalc;
            calculationDetailRef.current[DTDate] = {
              ...calculationDetailRef.current[DTDate],
              [order]: {
                type,
                calculation: `-${commonService.formateAmount(
                  myRateCalc?.toFixed(2)
                )}`,
              },
            };
          }
        };

        if (isApplyBgColor?.length > 0 && myRateByCalculation) {
          let nearestOcc = yieldDataRef.current?.reduce((acc, obj) =>
            Math.abs(dayDetail?.[0]?.OCC - obj.occupancy) <
            Math.abs(dayDetail?.[0]?.OCC - acc.occupancy)
              ? obj
              : acc
          );

          let findYieldData = yieldDataRef.current?.filter((yieldItem) => {
            if (
              yieldItem?.occupancy === nearestOcc?.occupancy &&
              night >= yieldItem?.minday &&
              night <= yieldItem?.maxday
            ) {
              return yieldItem;
            }
          });
          if (
            findYieldData?.length > 0 &&
            findYieldData?.[0]?.ratevalue !== 0
          ) {
            calculationForMyRate(findYieldData?.[0]?.ratevalue, "Yield", "1");
          }

          let findLosData = losDataRef.current?.filter((losItem) => {
            if (night >= losItem?.minlos && night <= losItem?.maxlos) {
              return losItem;
            }
          });

          if (findLosData?.length > 0 && findLosData?.[0]?.ratevalue !== 0) {
            calculationForMyRate(findLosData?.[0]?.ratevalue, "LOS", "2");
          }

          let findCustomerData = customerTypeRef.current?.filter(
            (customerItem) => {
              if (customerItem?.customertype === selectedCustomer) {
                return customerItem;
              }
            }
          );

          if (
            findCustomerData?.length > 0 &&
            findCustomerData?.[0]?.ratevalue !== 0
          ) {
            calculationForMyRate(
              findCustomerData?.[0]?.ratevalue,
              "Customer Type",
              "3"
            );
          }

          let findWeekDayData = weekDayRef.current?.filter((weekDayItem) => {
            if (
              weekDayItem?.weekdayname ==
              moment(commonService.convertDateInTimezone(DTDate), "l").format(
                "dddd"
              )
            ) {
              return weekDayItem;
            }
          });

          if (
            findWeekDayData?.length > 0 &&
            findWeekDayData?.[0]?.ratevalue !== 0
          ) {
            calculationForMyRate(
              findWeekDayData?.[0]?.ratevalue,
              "Week Day",
              "4"
            );
          }

          let findEventData = eventRef.current?.filter((x) => {
            let checkingDate = moment(DTDate);
            let startingDate = moment(x?.startdate);
            let endingDate = moment(x?.enddate);
            if (
              checkingDate.isSameOrAfter(startingDate) &&
              checkingDate.isSameOrBefore(endingDate)
            ) {
              return x;
            }
          });

          if (
            findEventData?.length > 0 &&
            findEventData?.[0]?.rateiqratevalue !== 0
          ) {
            calculationForMyRate(
              findEventData?.[0]?.rateiqratevalue,
              "Event",
              "5"
            );
          }
        }

        calculationDetailRef.current[DTDate] = {
          ...calculationDetailRef.current[DTDate],
          6: {
            type: "Final Rate",
            calculation: `${
              myRateByCalculation
                ? commonService.formateAmount(Math.round(myRateByCalculation))
                : commonService.formateAmount(0)
            }`,
          },
        };

        row.push(
          <td
            key={`${i}-${j}`}
            className={`main-date-box-with-bg  ${
              CalFirstDate?.getMonth() !== calDate.getMonth()
                ? "disableDate"
                : ""
            } ${
              commonService.getDateInDBFormat(
                commonService.convertDateInTimezone(CalFirstDate)
              ) ===
              commonService.getDateInDBFormat(
                commonService.convertDateInTimezone(new Date())
              )
                ? "today"
                : ""
            }`}
            style={{ borderTop: "2px solid #E4E5E8" }}
          >
            <div
              className={`temp-calendar-td ${
                isApplyBgColor?.length > 0 ? "calendar-lightgreenbg" : ""
              }`}
            >
              <div className="main-date-box-inner">
                <p>{CalFirstDate.getDate()}</p>
                <div className="d-flex gap-1">
                  {isApplyBgColor?.length > 0 && (
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      src={viewIcon}
                      onClick={(e) => {
                        if (
                          clickedDateRef.current ===
                          commonService.getDateInFormat(DTDate)
                        ) {
                          closeGroupDate();
                        } else {
                          clickedDateRef.current =
                            commonService.getDateInFormat(DTDate);
                          handleGroupBtnClick(e);
                          clickedDateDetail.current =
                            calculationDetailRef.current[DTDate];
                        }
                      }}
                    />
                  )}
                  {checkEvent?.length > 0 && (
                    <img
                      src={ticket}
                      alt="ticket"
                      onClick={() => {
                        eventListPropsRef.current = checkEvent;
                        selectedEventDateRef.current = DTDate;
                        setShowTicketModal(true);
                      }}
                    />
                  )}
                  {checkReminder?.length > 0 && (
                    <img
                      src={alarm}
                      alt="alarm"
                      onClick={() => {
                        reminderListPropsRef.current = checkReminder;
                        isReminderRef.current = true;
                        setViewReminderModal(true);
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                DTDate={DTDate}
                className="revenueOnSameDate"
                onClick={() => {
                  setClickedDate(DTDate);
                  setToolsSummaryModal(true);
                }}
                style={{ color: "#202842" }}
              >
                {myRateByCalculation
                  ? commonService.formateAmount(Math.round(myRateByCalculation))
                  : commonService.formateAmount(0)}
              </div>
              <div className="subdetails-container">
                <div className="subdetails" style={{ color: "#3565FC" }}>
                  {isApplyBgColor?.length > 0
                    ? `${commonService.formateAmount(calculateMyRate(DTDate))}`
                    : dayDetail?.[0]?.my_rate
                    ? `${commonService.formateAmount(dayDetail?.[0]?.my_rate)}`
                    : commonService.formateAmount(0)}
                </div>
                <div className="subdetails" style={{ color: "#ea2b12e6" }}>
                  {isApplyBgColor?.length > 0
                    ? `${commonService.formateAmount(
                        calculateCompetitorAvg(DTDate)
                      )}`
                    : dayDetail?.[0]?.avg_rate
                    ? `${commonService.formateAmount(dayDetail?.[0]?.avg_rate)}`
                    : commonService.formateAmount(0)}
                </div>
              </div>
            </div>
          </td>
        );
        CalFirstDate = new Date(
          CalFirstDate.setDate(CalFirstDate.getDate() + 1)
        );
      }
      calendar.push(<tr key={i}>{row}</tr>);
    }

    return calendar;
  };

  const yieldDataRef = useRef();
  const losDataRef = useRef();
  const marketSegmentRef = useRef();
  const customerTypeRef = useRef();
  const weekDayRef = useRef();
  const eventRef = useRef();
  const rateIqSettingApiCalledRef = useRef(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [rateGainData, setRateGainData] = useState(null);
  const calculationDetailRef = useRef({});
  const [rateIqViewModal, setRateIqViewModal] = useState(false);
  const clickedDateDetail = useRef();

  const handlePrevMonth = () => {
    let startDate = moment(dateRange.start).toDate();
    startDate.setMonth(startDate.getMonth() - 2);
    let sd = commonService.convertDateInTimezone(startDate);

    let ed = moment(dateRange.end).toDate();
    let endDate = commonService.getDateInDBFormat(
      commonService.convertDateInTimezone(
        new Date(ed.getFullYear(), ed.getMonth() - 1, 0)
      )
    );

    setDateRange({
      start: commonService.convertDateInTimezone(sd),
      end: commonService.convertDateInTimezone(endDate),
    });
  };

  const handleNextMonth = () => {
    let startDate = moment(dateRange.start).toDate();
    startDate.setMonth(startDate.getMonth() + 2);
    let sd = commonService.convertDateInTimezone(startDate);

    let ed = moment(dateRange.end).toDate();
    let endDate = commonService.getDateInDBFormat(
      commonService.convertDateInTimezone(
        new Date(ed.getFullYear(), ed.getMonth() + 3, 0)
      )
    );

    setDateRange({
      start: commonService.convertDateInTimezone(sd),
      end: commonService.convertDateInTimezone(endDate),
    });
  };

  const getReminderList = async () => {
    let reminderListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          startdate: commonService.getDateInDBFormat(
            commonService.convertDateInTimezone(dateRange?.start)
          ),
          enddate: commonService.getDateInDBFormat(
            commonService.convertDateInTimezone(dateRange?.end)
          ),
          remindername: "",
          remindertypeterm: "",
        },
      },
      false
    );

    let reminderResponse = apiResponse(false, reminderListResponse);

    if (reminderResponse?.isValidate) {
      reminderListRef.current = reminderResponse?.data?.data;
    }

    if (!reminderResponse?.isValidate) {
      reminderListRef.current = [];
    }
  };

  const getRateIqData = async () => {
    setTableData(null)
    setLoading(true);

    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        body: {
          propertyCode: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertycode
            : propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    if (asOfDateResponse?.status_code === 0) {
      return errorToastMessage(asOfDateResponse?.message);
      setLoading(false);
    } else if (asOfDateResponse?.status_code === 1) {
      let rateIqResponse = await apiCall(
        {
          method: "POST",
          url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ.GET_LIST,
          body: {
            propertyCode: window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK?.propertycode
              : propertyDetailFromRTK?.propertycode,
            propertyid: window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK?.propertyid
              : propertyDetailFromRTK?.propertyid,
            AsOfDate: asOfDateResponse?.data?.[0]?.last_data_refresh
              ? asOfDateResponse?.data?.[0]?.last_data_refresh
              : "",
            startDate: commonService.getDateForNode(
              commonService.convertDateInTimezone(dateRange.start)
            ),
            endDate: commonService.getDateForNode(
              commonService.convertDateInTimezone(dateRange.end)
            ),
          },
        },
        false
      );

      let response = apiResponse(false, rateIqResponse);

      if (response?.isValidate) {
        setTableData(response?.data?.data);
        setLoading(false);
      }

      if (!response?.isValidate) {
        setTableData(null);
        setLoading(false);
      }
    }
  };

  const getRateGainData = async () => {
    let rateGainDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.RATEIQ.GET_RATE_GAIN_DATA_FROM_THIRD_PARTY,
        body: {
          propertyCode: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertycode
            : "",
          propertyid: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertyid
            : "",
          CheckInStartDate: checkIn
            ? commonService.getDateForNode(checkIn)
            : "",
          CheckInEndDate: checkOut
            ? commonService.getDateForNode(checkOut)
            : "",
        },
      },
      false
    );

    let response = apiResponse(false, rateGainDataResponse);

    if (response?.isValidate) {
      setRateGainData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setRateGainData(null);
    }
  };

  const getYieldList = async () => {
    setLoading(true);
    rateIqSettingApiCalledRef.current = true;
    let yieldResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_YIELD.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, yieldResponse);

    if (response?.isValidate) {
      let dataFromapi = response?.data?.data;
      yieldDataRef.current = dataFromapi;
    }

    if (!response?.isValidate) {
      yieldDataRef.current = null;
    }
  };

  const getLosDetail = async () => {
    let losResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_LOS.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, losResponse);

    if (response?.isValidate) {
      losDataRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      losDataRef.current = [];
    }
  };

  const getMarketSegmentData = async () => {
    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Market Segment",
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      marketSegmentRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      marketSegmentRef.current = null;
    }
  };

  const getCustomerType = async () => {
    let customerTypeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_CUSTOMER_TYPE.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, customerTypeResponse);

    if (response?.isValidate) {
      customerTypeRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      customerTypeRef.current = null;
    }
  };

  const getWeekDayData = async () => {
    let weekDayResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_WEEKDAY.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, weekDayResponse);

    if (response?.isValidate) {
      weekDayRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      weekDayRef.current = null;
    }
  };

  const getEventList = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(
            window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK?.propertyid
              : propertyDetailFromRTK?.propertyid
          ),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      eventRef.current = response?.data?.data;
      let eventListArr = [];

      response?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!response?.isValidate) {
      eventRef.current = null;
      eventListRef.current = null;
    }
  };

  const callAllApi = async () => {
    if (rateIqSettingApiCalledRef.current === false) {
      await getYieldList();
      await getLosDetail();
      await getMarketSegmentData();
      await getCustomerType();
      await getWeekDayData();
      await getEventList();
    }
    await getRateIqData();
  };

  const handleGoBtnClick = () => {
    if (checkIn && checkOut) {
      calculationDetailRef.current=[]
      let datesArr = commonService.getDateBetweenTwoDatesForHiglightRateIq(
        commonService.convertDateInTimezone(checkIn),
        commonService.convertDateInTimezone(checkOut)
      );
      setDatesArr(datesArr);
      getRateGainData();
    }
  };

  useEffect(() => {
    handleGoBtnClick();
    getRateGainData();
  }, []);

  useEffect(() => {
    getReminderList();
    callAllApi();
  }, [propertyDetailFromRTK, dateRange, crmPropertyDetailFromRTK]);

  let propertySelectionfromHeader = propertyDetailFromRTK;

  if (window.location.href.includes("/crm")) {
    propertySelectionfromHeader = crmPropertyDetailFromRTK;
  }
  return (
    <div
      className={`RMRateShopMain ${
        rateIqViewModal ? "fade rateiq-calendar-popup" : ""
      }`}
    >
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center">
                {/* <div className="generalTermSetup-heading-btns">
                  <Link to="/revenue/rateIq-calender">
                    <Button className="heading-menu-btns" type="button">
                      <img src={calendarIcon} alt="calendarIcon" />
                    </Button>
                  </Link>
                  <Link to="/revenue/rateIq">
                    <Button className="heading-menu-btns" type="button">
                      <img src={horizobtalBarIcon} alt="horizobtalBarIcon" />
                    </Button>
                  </Link>
                </div> */}
              </div>
            </Col>
            <Col xl={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="from-groups no-border-break width125">
                    <Form.Label>Check In</Form.Label>
                    <DatePicker
                      selected={checkIn}
                      onChange={(date) => {
                        if (night) {
                          let checkoutDate = new Date(date);
                          checkoutDate.setDate(
                            checkoutDate.getDate() + parseInt(night)
                          );
                          setCheckOut(checkoutDate);
                        }
                        setCheckIn(date);
                      }}
                    />
                  </div>
                  <div className="from-groups no-border-break width100">
                    <Form.Label>Night</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={16}
                      value={night}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        if (e.target.validity.valid) {
                          if (checkIn && e.target.value !== "") {
                            let checkoutDate = new Date(checkIn);
                            checkoutDate.setDate(
                              checkoutDate.getDate() + parseInt(e.target.value)
                            );
                            setCheckOut(checkoutDate);
                          }

                          setNight(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="from-groups no-border-break width125">
                    <Form.Label>Check Out</Form.Label>
                    <DatePicker
                      selected={checkOut}
                      onChange={(date) => {
                        if (checkIn && checkOut) {
                          let datesArr =
                            commonService.getDateBetweenTwoDatesForHiglightRateIq(
                              new Date(checkIn),
                              new Date(date)
                            );

                          setNight(datesArr?.length);
                        }
                        setCheckOut(date);
                      }}
                    />
                  </div>
                  <div className="from-groups no-border-break width200">
                    <Form.Label>Customer Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedCustomer}
                      onChange={(e) => {
                        setSelectedCustomer(e.target.value);
                      }}
                    >
                      <option value="">Select Customer Type</option>
                      {marketSegmentRef.current?.map((item) => {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <Col className="gobtn width100">
                    <div className="d-flex gap-2">
                      <Button
                        className="default-btn primary-btn gobtnwidth"
                        onClick={() => {
                          handleGoBtnClick();
                        }}
                      >
                        Go
                      </Button>
                    </div>
                  </Col>
                </div>
                <div>
                  <div className="allDateDetailPercent justify-content-between">
                    <div className="allDateDetailPercentInner">
                      <div style={{ background: "#ED412A" }}></div>
                      <p>Competitors Rate</p>
                    </div>
                    <div className="allDateDetailPercentInner">
                      <div style={{ background: "#3565FC" }}></div>
                      <p>My Rate</p>
                    </div>
                    <div className="allDateDetailPercentInner">
                      <div style={{ background: "#202842" }}></div>
                      <p>Predicated Rate</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} className="mt-3">
              <Row>
                <Col xl={12}>
                  <div className="month-name-with-arrow">
                    <button
                      onClick={() => {
                        let d = new Date(dateRange?.start);
                        d.setMonth(d.getMonth() - 1);
                        const startDate = commonService.getDateInDBFormat(
                          new Date(d.getFullYear(), d.getMonth(), 1)
                        );
                        const endDate = commonService.getDateInDBFormat(
                          new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        );
                        setDateRange({
                          start: startDate,
                          end: endDate,
                        });
                        handlePrevMonth();
                      }}
                      disabled={loading ? true : false}
                    >
                      <img src={premonth} alt="premonth" />
                    </button>
                    <h4>
                      {`${moment(dateRange?.start)
                        ?.toDate()
                        ?.toLocaleDateString("en-US", {
                          month: "short",
                          year: "numeric",
                        })} ${moment(dateRange?.end)
                        ?.toDate()
                        ?.toLocaleDateString("en-US", {
                          month: "short",
                          year: "numeric",
                        })}`}
                      {/* {`${new Date(dateRange?.start).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      )} ${new Date(dateRange?.end)?.toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      )}`} */}
                    </h4>
                    <button
                      onClick={() => {
                        let d = new Date(dateRange?.start);
                        d.setMonth(d.getMonth() + 1);
                        const startDate = commonService.getDateInDBFormat(
                          new Date(d.getFullYear(), d.getMonth(), 1)
                        );
                        const endDate = commonService.getDateInDBFormat(
                          new Date(d.getFullYear(), d.getMonth() + 1, 0)
                        );
                        setDateRange({
                          start: startDate,
                          end: endDate,
                        });
                        handleNextMonth();
                      }}
                      disabled={loading ? true : false}
                    >
                      <img src={nextmonth} alt="nextmonth" />
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={6} className="p-0 pe-2">
              <div className="rateIq-calender">
                <Table className="calendar-table" responsive>
                  <thead>
                    <tr>
                      {daysOfWeek.map((day) => (
                        <th key={day} className="width100">
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      renderCalendar(
                        commonService.convertDateInTimezone(dateRange?.start)
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={6} className="p-0 ps-2 calenderleft-border">
              <div className="rateIq-calender">
                <Table className="calendar-table" responsive>
                  <thead>
                    <tr>
                      {daysOfWeek.map((day) => (
                        <th key={day} className="width100">
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      renderCalendar(
                        commonService.convertDateInTimezone(dateRange?.end)
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
      {viewReminderModal && (
        <ToolsSingleTicketPopUp
          showModal={viewReminderModal}
          closeModal={closeViewReminderModal}
          eventDetails={reminderListPropsRef.current}
          isReminder={isReminderRef.current}
        />
      )}
      {showToolsSummaryModal && (
        <ToolsSummaryModal
          showToolsSummaryModal={showToolsSummaryModal}
          closeToolsSummaryModal={closeToolsSummaryModal}
          selectedDate={ClickedDate}
          propertyDetail={
            window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK
              : propertyDetailFromRTK
          }
          ismlRateExist={true}
        />
      )}
      {/* {rateIqViewModal && (
        <RateIqViewModal
          showModal={rateIqViewModal}
          closeModal={closeRateIqViewModal}
          data={clickedDateDetail.current}
        />
      )} */}
      <Overlay
        show={groupModal.IsDisplay}
        target={groupModal.Target}
        placement={groupModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option my-special-horizontal-menu-option active">
            <RateIqViewModal
              // showModal={rateIqViewModal}
              // closeModal={closeRateIqViewModal}
              closeModal={closeGroupDate}
              data={clickedDateDetail.current}
            />
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};

export default RMRateIqCalenderView;
