import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import Loader from "../../../Loader/Loader";
import { compName, month } from "../../../../utils/constant";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const RevYoYChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let lastYear = {
    label: "STLY",
    data: [],
    backgroundColor: "#4F98CA",
    stack: "Stack 2",
    type: "bar",
    order: 1,
  };
  let currentYear = {
    label: `${new Date().getFullYear()}`,
    data: [],
    backgroundColor: "#3EB978",
    stack: "Stack 1",
    type: "bar",
    order: 1,
  };
  let occupancy = {
    label: "Occupancy",
    data: [],
    borderColor: "#919191",
    fill: false,
    backgroundColor: "#919191",
    type: "line",
    yAxisID: "y2",
    order: 0,
  };

  const getChartData = async () => {
    let groupingJson = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [],
    };
    let chartDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.REV_YOY_CHART,
        body: { AsOfDate: para?.asOfDate, propertyCode: para?.propertyCode },
      },
      false
    );

    let response = apiResponse(false, chartDataResponse);

    if (response?.isValidate) {
      lastYear.data = [];
      currentYear.data = [];
      occupancy.data = [];
      let dataFromApi = response?.data?.data;
      let tableData = {
        "LY Revenue": { data: [], total: 0 },
        "CY Revenue": { data: [], total: 0 },
        Occupancy: { data: [], total: 0 },
      };

      month?.map((item, index) => {
        let findMonthDetail = dataFromApi?.filter(
          (x) => x?.month?.trim() === item
        );

        tableData?.["LY Revenue"]?.data?.push(
          findMonthDetail?.[0]?.LY_Revenue
            ? findMonthDetail?.[0]?.LY_Revenue
            : 0
        );
        tableData["LY Revenue"].total =
          tableData?.["LY Revenue"]?.total + findMonthDetail?.[0]?.LY_Revenue;

        tableData?.["CY Revenue"]?.data?.push(
          findMonthDetail?.[0]?.CY_Revenue
            ? findMonthDetail?.[0]?.CY_Revenue
            : 0
        );
        tableData["CY Revenue"].total =
          tableData?.["CY Revenue"]?.total + findMonthDetail?.[0]?.CY_Revenue;

        tableData?.Occupancy?.data?.push(
          findMonthDetail?.[0]?.occ ? findMonthDetail?.[0]?.occ : 0
        );
        tableData.Occupancy.total =
          tableData?.Occupancy?.total + findMonthDetail?.[0]?.occ;

        lastYear.data.push(
          findMonthDetail?.[0]?.LY_Revenue
            ? findMonthDetail?.[0]?.LY_Revenue
            : 0
        );
        currentYear.data.push(
          findMonthDetail?.[0]?.CY_Revenue
            ? findMonthDetail?.[0]?.CY_Revenue
            : 0
        );
        occupancy.data.push(
          findMonthDetail?.[0]?.occ ? findMonthDetail?.[0]?.occ : 0
        );
      });
      groupingJson = {
        ...groupingJson,
        datasets: [currentYear, lastYear, occupancy],
      };
      setChartData(groupingJson);
      setTableData(tableData);
    }

    if (!response?.isValidate) {
      lastYear.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      currentYear.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      occupancy.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      groupingJson = {
        ...groupingJson,
        datasets: [currentYear, lastYear, occupancy],
      };
      setChartData(groupingJson);
    }
  };

  useEffect(() => {
    if (api === 0) {
      lastYear.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      currentYear.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      occupancy.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let groupingJson = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [currentYear, lastYear, occupancy],
      };
      setChartData(groupingJson);
    } else {
      if (para?.asOfDate) {
        getChartData();
      }
    }
  }, [para?.asOfDate]);

  const options = {
    responsive: true,
    barThickness: 25,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 100,
          callback: function (value, index, values) {
            return `${
              propertyDetailFromRTK?.currency_term
                ? propertyDetailFromRTK?.currency_term
                : "$"
            }${value}`;
          },
        },
      },
      y2: {
        stacked: false,
        // min: 0,
        // max: 100,
        beginAtZero: true,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          //   min: 0,
          //   max: 100,
          stepSize: 10,
          callback: function (value, index, values) {
            return `${value} %`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            let value = context.formattedValue;
            if (label === "STLY" || label === `${new Date().getFullYear()}`) {
              label +=
                `: ${
                  propertyDetailFromRTK?.currency_term
                    ? propertyDetailFromRTK?.currency_term
                    : "$"
                }` + value;
            } else if (label === "Occupancy") {
              label += ": " + value + "%";
            }

            return label;
          },
        },
      },
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    aspectRatio: 3.5 / 1,
    responsive: true,
    maintainAspectRatio: true,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.revYoYChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Container fluid>
        <Row>
          <Col xl={12}>
            {chartData ? (
              <Bar data={chartData} options={options} />
            ) : (
              <Loader />
            )}
          </Col>
          <Col xl={12}>
            {tableData && (
              <div className="ViewPropertySetupTable mt-2">
                <div className="stripped__table">
                  <Table responsive>
                    <thead>
                      <th></th>
                      <th>Jan</th>
                      <th>Feb</th>
                      <th>Mar</th>
                      <th>Apr</th>
                      <th>May</th>
                      <th>Jun</th>
                      <th>Jul</th>
                      <th>Aug</th>
                      <th>Sep</th>
                      <th>Oct</th>
                      <th>Nov</th>
                      <th>Dec</th>
                      <th>Total</th>
                    </thead>
                    <tbody>
                      {Object?.entries(tableData)?.map(
                        ([key, value], index) => {
                          let total = value?.total;
                          if (key === "Occupancy") {
                            total = Math.round(total / 12);
                          }
                          return (
                            <tr key={index}>
                              <td>{key}</td>
                              {value?.data?.map((data, dataIndex) => (
                                <td key={dataIndex}>{`${
                                  key !== "Occupancy"
                                    ? `${
                                        propertyDetailFromRTK?.currency_term
                                          ? propertyDetailFromRTK?.currency_term
                                          : "$"
                                      }`
                                    : ""
                                }${data}${key === "Occupancy" ? "%" : ""}`}</td>
                              ))}
                              <td>{`${
                                key !== "Occupancy"
                                  ? `${
                                      propertyDetailFromRTK?.currency_term
                                        ? propertyDetailFromRTK?.currency_term
                                        : "$"
                                    }`
                                  : ""
                              }${total}${key === "Occupancy" ? "%" : ""}`}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </Col>
        </Row>
        {api !== 0 && (
          <Comments widgetDetails={para} editorId={"RevYoYChart"} />
        )}
      </Container>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default RevYoYChart;
