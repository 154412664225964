import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import closebtn from "../../Assets/Images/Configuration/closebtn.svg";
import { validationRule } from "../../utils/constant";
import placeHolder from "../../Assets/Images/Configuration/image_placeholder.svg";
import { commonService } from "../../utils/commonService";
import { checkValidation } from "../../utils/helper";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { successToastMessage } from "../../utils/toastMessage";

const UserProfileUpdateModal = ({ showModal, closeModal }) => {
  const userDetail = commonService.getLoginUserData();
  let validationObj = {
    userid: userDetail?.userid,
    istwofactorenable: false,
    authtypeterm: "",
    mobileno: "",
    profilepic: "",
    preferredotpsendonterm: "",
    firstname: "",
    lastname: "",
    timezone: "",
    issinglesingno: false,
    validationRule: [
      {
        fieldName: "mobileno",
        rule: validationRule.required,
        message: "Mobile no. is required",
      },
      {
        fieldName: "mobileno",
        rule: validationRule.phone,
        message: "Please enter as +xxxx xxxxxxxxxx",
      },
      {
        fieldName: "firstname",
        rule: validationRule.required,
        message: "Firstname is required",
      },
      {
        fieldName: "lastname",
        rule: validationRule.required,
        message: "Lastname is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [userData, setUserData] = useState(validationObj);
  const [projectTerm, setProjectTerm] = useState(null);
  const [file, setFile] = useState("");
  const inputFile = useRef();
  const [loading, setLoading] = useState(false);
  const userPreferenceObj = {
    Heatmap: {
      "7 Day Pickup From": false,
      "Total OTB": true,
    },
  };
  const defaultUserPrefData =
    userDetail?.userpreferences && userDetail?.userpreferences !== "null"
      ? JSON.parse(userDetail?.userpreferences)
      : {
          Heatmap: {
            DefaultDataView: "7 Day Pickup From",
          },
        };
  const [userPref, setUserPref] = useState(defaultUserPrefData);
  const userPrefRef = useRef(defaultUserPrefData);

  const getProjectTermByMultipleCategory = async () => {
    let projectTermListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Auth Type,Timezone,OTP Send On",
        },
      },
      false
    );

    let response = apiResponse(false, projectTermListResponse);

    if (response?.isValidate) {
      setProjectTerm(response?.data?.data);
    }
  };

  const getAllDetailsById = async () => {
    let allDetailsByIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER.GET_ALL_DETAILS_BY_ID,
        body: {
          id: userDetail?.userid,
        },
      },
      false
    );

    let response = apiResponse(false, allDetailsByIdResponse);

    if (response?.isValidate) {
      setUserData({
        ...userData,
        istwofactorenable: response?.data?.data?.user?.istwofactorenable,
        authtypeterm: response?.data?.data?.user?.authtypeterm
          ? response?.data?.data?.user?.authtypeterm
          : "",
        mobileno: response?.data?.data?.user?.mobileno
          ? response?.data?.data?.user?.mobileno
          : "",
        profilepic: response?.data?.data?.user?.profilepic
          ? response?.data?.data?.user?.profilepic
          : "",
        preferredotpsendonterm: response?.data?.data?.user
          ?.preferredotpsendonterm
          ? response?.data?.data?.user?.preferredotpsendonterm
          : "",
        firstname: response?.data?.data?.user?.firstname
          ? response?.data?.data?.user?.firstname
          : "",
        lastname: response?.data?.data?.user?.lastname
          ? response?.data?.data?.user?.lastname
          : "",
        timezone: response?.data?.data?.user?.timezone
          ? response?.data?.data?.user?.timezone
          : "",
        issinglesingno: response?.data?.data?.user?.issinglesingno,
      });
      setFile(
        response?.data?.data?.user?.profilepic
          ? response?.data?.data?.user?.profilepic
          : ""
      );
      // setUserPref(
      //   response?.data?.data?.user?.userpreferences
      //     ? JSON.parse(response?.data?.data?.user?.userpreferences)
      //     : userPreferenceObj
      // );
      // userPrefRef.current = response?.data?.data?.user?.userpreferences
      //   ? JSON.parse(response?.data?.data?.user?.userpreferences)
      //   : userPreferenceObj;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let validation = checkValidation(userData);

    if (!validation?.isValidate) {
      setUserData(validation);
      setLoading(false);
      return;
    }

    let saveResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER.USER_PROFILE_UPDATE,
        body: {
          userid: userDetail?.userid,
          istwofactorenable: userData?.istwofactorenable,
          authtypeterm: userData?.authtypeterm,
          mobileno: userData?.mobileno,
          profilepic: userData?.profilepic,
          preferredotpsendonterm: userData?.preferredotpsendonterm,
          firstname: userData?.firstname,
          lastname: userData?.lastname,
          timezone: userData?.timezone,
          issinglesingno: userData?.issinglesingno,
          userpreferences: JSON.stringify(userPref),
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);

    if (response?.isValidate) {
      successToastMessage(response?.data?.data?.message);
      closeModal();
    }
  };

  useEffect(() => {
    getProjectTermByMultipleCategory();
    getAllDetailsById();
  }, []);
  return (
    <>
      <Modal
        className="userManagementModalMain"
        centered
        dialogClassName="modal-90w"
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Body>
          <div className="userManagementModalMain-wrapper">
            <div className="userManagementModalMain-innner">
              <div className="allInputsUserManagementModal">
                <Row>
                  <Col xl={12}>
                    <Row>
                      <Col xl={2}>
                        <div className="userManagementModalMain-innner-img">
                          <input
                            type="file"
                            className="form-control d-none"
                            id="profileImg"
                            ref={inputFile}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                profilepic: e?.target?.files?.[0]
                                  ? e?.target?.files?.[0]
                                  : "",
                              });
                              e?.target?.files?.[0]
                                ? setFile(
                                    URL.createObjectURL(e.target.files[0])
                                  )
                                : setFile("");
                            }}
                          />

                          {userData?.profilepic ? (
                            <img
                              src={file}
                              alt=""
                              type="file"
                              className="image-select"
                              onClick={() => {
                                inputFile.current.click();
                              }}
                            />
                          ) : (
                            <img
                              src={placeHolder}
                              alt=""
                              type="file"
                              className="image-select"
                              onClick={() => {
                                inputFile.current.click();
                              }}
                            />
                          )}
                          <img
                            className="user-closebtn"
                            src={closebtn}
                            slt="closebtn"
                            onClick={closeModal}
                          />
                        </div>
                      </Col>
                      <Col xl={10}>
                        <Row>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                maxLength="67"
                                placeholder="Enter First Name"
                                value={userData?.firstname}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    firstname: e.target.value,
                                  })
                                }
                              />
                              {userData?.errorObj?.firstname && (
                                <small className="text-danger">
                                  {userData?.errorObj?.firstname}
                                </small>
                              )}
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                maxLength="67"
                                placeholder="Enter Last Name"
                                value={userData?.lastname}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    lastname: e.target.value,
                                  })
                                }
                              />
                              {userData?.errorObj?.lastname && (
                                <small className="text-danger">
                                  {userData?.errorObj?.lastname}
                                </small>
                              )}
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>Phone No</Form.Label>
                              <Form.Control
                                type="text"
                                maxLength="17"
                                placeholder="Enter Phone No"
                                value={userData?.mobileno}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    mobileno: e.target.value,
                                  })
                                }
                              />
                              {userData?.errorObj?.mobileno && (
                                <small className="text-danger">
                                  {userData?.errorObj?.mobileno}
                                </small>
                              )}
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>Auth Type</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                value={userData?.authtypeterm}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    authtypeterm: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Select Auth Type</option>
                                {projectTerm?.map((item, index) => {
                                  if (item?.termcategoryname === "Auth Type") {
                                    return (
                                      <option key={index} value={item?.term}>
                                        {item?.term}
                                      </option>
                                    );
                                  }
                                })}
                              </Form.Select>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>OTP Send Type</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                value={userData?.preferredotpsendonterm}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    preferredotpsendonterm: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Select OTP Send Type</option>
                                {projectTerm?.map((item, index) => {
                                  if (
                                    item?.termcategoryname === "OTP Send On"
                                  ) {
                                    return (
                                      <option key={index} value={item?.term}>
                                        {item?.term}
                                      </option>
                                    );
                                  }
                                })}
                              </Form.Select>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>TimeZone</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                value={userData?.timezone}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    timezone: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Select TimeZone</option>
                                {projectTerm?.map((item, index) => {
                                  if (item?.termcategoryname === "Timezone") {
                                    return (
                                      <option key={index} value={item?.term}>
                                        {item?.term}
                                      </option>
                                    );
                                  }
                                })}
                              </Form.Select>
                            </div>
                          </Col>

                          <Col xl={3}>
                            <div className="form-check mt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="twoFactor"
                                checked={userData?.istwofactorenable}
                                onChange={() => {
                                  setUserData({
                                    ...userData,
                                    istwofactorenable:
                                      !userData?.istwofactorenable,
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="twoFactor"
                              >
                                Two Factor
                              </label>
                            </div>
                            <div className="form-check ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="singleSign"
                                checked={userData?.issinglesingno}
                                onChange={() => {
                                  setUserData({
                                    ...userData,
                                    issinglesingno: !userData?.issinglesingno,
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="singleSign"
                              >
                                Single Sign
                              </label>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="from-groups no-border-break mb-3">
                              <Form.Label>HeatMap</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                value={userPref?.Heatmap?.DefaultDataView}
                                onChange={(e) => {
                                  userPrefRef.current.Heatmap = {
                                    DefaultDataView: e.target.value,
                                  };
                                  userPrefRef.current = {
                                    ...userPrefRef.current,
                                  };
                                  setUserPref(userPrefRef.current);
                                }}
                              >
                                <option value={"7 Day Pickup From"}>
                                  7 Day Pickup From
                                </option>
                                <option value={"Total OTB"}>Total OTB</option>
                              </Form.Select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileUpdateModal;
