import React, { useEffect, useState } from "react";
import { apiResponse } from "../../utils/apiResponse";
import moment from "moment";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { commonService } from "../../utils/commonService";
import { Table } from "react-bootstrap";
import Loader from "../Loader/Loader";
import downArrow from "../../Assets/Images/Header/downArrow.svg";

const SingleDateRMSModal = ({
  showModal,
  closeModal,
  asOfDate,
  selectedDate,
  pickupDate,
  propertyCode,
  marketSegment,
}) => {
  const [singleDateData, setSingleDateData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSingleDateData = async () => {
    setLoading(true);
    let singleDateResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.RMS_SINGLE_DATE_SUMMARY,
        body: {
          // AsOfDate: moment(asOfDate).format("YYYY-MM-DD"),
          // SelectedDate: moment(selectedDate).format("YYYY-MM-DD"),
          AsOfDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(asOfDate)
          ),
          SelectedDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(selectedDate)
          ),
          propertyCode: propertyCode,
          // PickUpDate: moment(pickupDate).format("YYYY-MM-DD"),
          PickUpDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(pickupDate)
          ),
          MarketSegment: marketSegment,
        },
      },
      false
    );

    let response = apiResponse(false, singleDateResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = [];

      const checkMarketSegmentExist = (marketSegmentName) => {
        let filterData = groupingJson?.findIndex(
          (x) => x.name === marketSegmentName
        );

        return filterData;
      };

      const checkRateCodeExist = (rateCodeName) => {
        let filterData = groupingJson?.findIndex(
          (x) => x.name === rateCodeName
        );
        return filterData;
      };

      const checkCompanyExists = (indexofRateCode, companyName) => {
        let filterData = groupingJson[indexofRateCode]?.items?.findIndex(
          (x) => x.name === companyName
        );

        return filterData;
      };

      const marketSegmentWiseTotal = (marketSegmentName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
        };
        dataFromApi?.map((item, index) => {
          if (item.MarketSegment === marketSegmentName) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue = obj.lyRevenue + parseInt(item?.LY_REVENUE);
          }
        });
        return obj;
      };

      const rateCodeWiseTotal = (rateCodeName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
        };
        dataFromApi?.map((item, index) => {
          if (item.RateCode === rateCodeName) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue + item?.LY_REVENUE ? parseInt(item?.LY_REVENUE) : 0;
          }
        });
        return obj;
      };

      const companyWiseTotal = (rateCodeName, companyName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
        };
        dataFromApi?.map((item, index) => {
          if (item.RateCode === rateCodeName && item.Company === companyName) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue + item?.LY_REVENUE ? parseInt(item?.LY_REVENUE) : 0;
          }
        });
        return obj;
      };

      for (let i = 0; i < dataFromApi?.length; i++) {
        let indexOfRateCode = checkRateCodeExist(
          `${dataFromApi[i].RateCode}${
            dataFromApi[i]?.RateCodeDescript
              ? ` - ${dataFromApi[i]?.RateCodeDescript}`
              : ""
          }`
        );
        if (indexOfRateCode >= 0) {
          let indexOfCompany = checkCompanyExists(
            indexOfRateCode,
            dataFromApi[i].Company
          );

          if (indexOfCompany >= 0) {
            groupingJson[indexOfRateCode].items[indexOfCompany].items = [
              ...groupingJson[indexOfRateCode]?.items[indexOfCompany]?.items,
              {
                name: dataFromApi[i].GuestName,
                guid: commonService.GenerateGUID(),
                items: "",
                room: dataFromApi[i].CURRENT_ROOM,
                lyRoom: dataFromApi[i].LY_ROOM,
                pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                pickupAdr: dataFromApi[i].ADR_PICKUP,
                // adr: dataFromApi[i].CURRENT_ADR,
                adr:
                  dataFromApi[i].CURRENT_REVENUE > 0 &&
                  dataFromApi[i].CURRENT_ROOM > 0
                    ? Math.round(
                        dataFromApi[i].CURRENT_REVENUE /
                          dataFromApi[i].CURRENT_ROOM
                      )
                    : 0,
                // lyAdr: dataFromApi[i].LY_ADR,
                lyAdr:
                  dataFromApi[i].LY_REVENUE > 0 && dataFromApi[i].LY_ROOM > 0
                    ? Math.round(
                        dataFromApi[i].LY_REVENUE / dataFromApi[i].LY_ROOM
                      )
                    : 0,
                revenue: dataFromApi[i].CURRENT_REVENUE,
                lyRevenue: dataFromApi[i].LY_REVENUE,
              },
            ];
          } else {
            // let rateCodeTotal = rateCodeWiseTotal(dataFromApi[i].RateCode);
            let companyTotal = companyWiseTotal(
              dataFromApi[i].RateCode,
              dataFromApi[i].Company
            );
            groupingJson[indexOfRateCode].items = [
              ...groupingJson[indexOfRateCode].items,
              {
                // name: `${dataFromApi[i].RateCode}${
                //   dataFromApi[i]?.RateCodeDescript
                //     ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                //     : ""
                // }`,
                // guid: commonService.GenerateGUID(),
                // room: rateCodeTotal?.room,
                // lyRoom: rateCodeTotal?.lyRoom,
                // pickupFrom: rateCodeTotal?.pickupFrom,
                // pickupAdr: rateCodeTotal?.pickupAdr,
                // adr: rateCodeTotal?.adr,
                // lyAdr: rateCodeTotal?.lyAdr,
                // revenue: rateCodeTotal?.revenue,
                // lyRevenue: rateCodeTotal?.lyRevenue,

                name: dataFromApi[i].Company,
                guid: commonService.GenerateGUID(),
                room: companyTotal?.room,
                lyRoom: companyTotal?.lyRoom,
                pickupFrom: companyTotal?.pickupFrom,
                pickupAdr: companyTotal?.pickupAdr,
                // adr: companyTotal?.adr,
                adr:
                  companyTotal?.revenue > 0 && companyTotal?.room > 0
                    ? Math.round(companyTotal?.revenue / companyTotal?.room)
                    : 0,
                // lyAdr: companyTotal?.lyAdr,
                lyAdr:
                  companyTotal?.lyRevenue > 0 && companyTotal?.lyRoom > 0
                    ? Math.round(companyTotal?.lyRevenue / companyTotal?.lyRoom)
                    : 0,
                revenue: companyTotal?.revenue,
                lyRevenue: companyTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].GuestName,
                    guid: commonService.GenerateGUID(),
                    items: "",
                    room: dataFromApi[i].CURRENT_ROOM,
                    lyRoom: dataFromApi[i].LY_ROOM,
                    pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                    pickupAdr: dataFromApi[i].ADR_PICKUP,
                    // adr: dataFromApi[i].CURRENT_ADR,
                    adr:
                      dataFromApi[i].CURRENT_REVENUE > 0 &&
                      dataFromApi[i].CURRENT_ROOM > 0
                        ? Math.round(
                            dataFromApi[i].CURRENT_REVENUE /
                              dataFromApi[i].CURRENT_ROOM
                          )
                        : 0,
                    // lyAdr: dataFromApi[i].LY_ADR,
                    lyAdr:
                      dataFromApi[i].LY_REVENUE > 0 &&
                      dataFromApi[i].LY_ROOM > 0
                        ? Math.round(
                            dataFromApi[i].LY_REVENUE / dataFromApi[i].LY_ROOM
                          )
                        : 0,
                    revenue: dataFromApi[i].CURRENT_REVENUE,
                    lyRevenue: dataFromApi[i].LY_REVENUE,
                  },
                ],
              },
            ];
          }
        } else {
          let rateCodeTotal = rateCodeWiseTotal(dataFromApi[i].RateCode);
          let companyTotal = companyWiseTotal(
            dataFromApi[i].RateCode,
            dataFromApi[i].Company
          );
          let newObj = {
            name: `${dataFromApi[i].RateCode}${
              dataFromApi[i]?.RateCodeDescript
                ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                : ""
            }`,
            guid: commonService.GenerateGUID(),
            room: rateCodeTotal?.room,
            lyRoom: rateCodeTotal?.lyRoom,
            pickupFrom: rateCodeTotal?.pickupFrom,
            pickupAdr: rateCodeTotal?.pickupAdr,
            // adr: rateCodeTotal?.adr,
            adr:
              rateCodeTotal?.revenue > 0 && rateCodeTotal?.room > 0
                ? Math.round(rateCodeTotal?.revenue / rateCodeTotal?.room)
                : 0,
            // lyAdr: rateCodeTotal?.lyAdr,
            lyAdr:
              rateCodeTotal?.lyRevenue > 0 && rateCodeTotal?.lyRoom > 0
                ? Math.round(rateCodeTotal?.lyRevenue / rateCodeTotal?.lyRoom)
                : 0,
            revenue: rateCodeTotal?.revenue,
            lyRevenue: rateCodeTotal?.lyRevenue,
            items: [
              {
                name: dataFromApi[i].Company,
                guid: commonService.GenerateGUID(),
                room: companyTotal?.room,
                lyRoom: companyTotal?.lyRoom,
                pickupFrom: companyTotal?.pickupFrom,
                pickupAdr: companyTotal?.pickupAdr,
                // adr: companyTotal?.adr,
                adr:
                  companyTotal?.revenue > 0 && companyTotal?.room > 0
                    ? Math.round(companyTotal?.revenue / companyTotal?.room)
                    : 0,
                // lyAdr: companyTotal?.lyAdr,
                lyAdr:
                  companyTotal?.lyRevenue > 0 && companyTotal?.lyRoom > 0
                    ? Math.round(companyTotal?.lyRevenue / companyTotal?.lyRoom)
                    : 0,
                revenue: companyTotal?.revenue,
                lyRevenue: companyTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].GuestName,
                    guid: commonService.GenerateGUID(),
                    items: "",
                    room: dataFromApi[i].CURRENT_ROOM,
                    lyRoom: dataFromApi[i].LY_ROOM,
                    pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                    pickupAdr: dataFromApi[i].ADR_PICKUP,
                    // adr: dataFromApi[i].CURRENT_ADR,
                    adr:
                      dataFromApi[i].CURRENT_REVENUE > 0 &&
                      dataFromApi[i].CURRENT_ROOM > 0
                        ? Math.round(
                            dataFromApi[i].CURRENT_REVENUE /
                              dataFromApi[i].CURRENT_ROOM
                          )
                        : 0,
                    // lyAdr: dataFromApi[i].LY_ADR,
                    lyAdr:
                      dataFromApi[i].LY_REVENUE > 0 &&
                      dataFromApi[i].LY_ROOM > 0
                        ? Math.round(
                            dataFromApi[i].LY_REVENUE / dataFromApi[i].LY_ROOM
                          )
                        : 0,
                    revenue: dataFromApi[i].CURRENT_REVENUE,
                    lyRevenue: dataFromApi[i].LY_REVENUE,
                  },
                ],
              },
            ],
          };

          groupingJson = [...groupingJson, newObj];
        }
      }
      setSingleDateData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSingleDateData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleDateData();
  }, [selectedDate]);

  const handleShowHideRow = (className, itemDetail) => {
    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
        } else {
          classList[i].classList.add("nestedTableRowNone");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };
  return (
    <div className="GroupNameModalMain ToolsSingleTicketPopUpMain singleDateSummaryModalMain">
      <div className="GroupNameModalHeader">
        <h2>Single Date Summary</h2>
        <button className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Market Segment</th>
                    <th>Room</th>
                    <th className="border_right">LY</th>
                    <th className="border_right">Pickup From</th>
                    {/* <th className="border_right">ADR Pickup</th> */}
                    <th className="text-end">ADR</th>
                    <th className="text-end border_right">LY</th>
                    <th className="text-end">Revenue</th>
                    <th className="text-end">LY</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    singleDateData &&
                    singleDateData?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border_right">
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  const classList =
                                    document.getElementsByClassName(item?.guid);

                                  if (
                                    classList[0].classList.contains(
                                      "nestedTableRowNone"
                                    )
                                  ) {
                                    handleShowHideRow(item?.guid);
                                  } else {
                                    handleShowHideRow(item?.guid, item);
                                  }
                                }}
                              >
                                <p>{item?.name}</p>
                                <img src={downArrow} />
                              </div>
                            </td>
                            <td>{item?.room}</td>
                            <td className="border_right">{item?.lyRoom}</td>
                            <td className="border_right">{item?.pickupFrom}</td>
                            {/* <td className="border_right">{item?.pickupAdr}</td> */}
                            <td className="text-end">
                              {commonService.formateAmount(item?.adr)}
                            </td>
                            <td className="text-end border_right">
                              {commonService.formateAmount(item?.lyAdr)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.revenue)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.lyRevenue)}
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => (
                              <>
                                <tr
                                  className={`${item?.guid} nestedTableRowNone`}
                                >
                                  <td
                                    className="border_right"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <div
                                      className="d-flex gap-2"
                                      onClick={() => {
                                        const classList =
                                          document.getElementsByClassName(
                                            rateCodeItem?.guid
                                          );

                                        if (
                                          classList[0].classList.contains(
                                            "nestedTableRowNone"
                                          )
                                        ) {
                                          handleShowHideRow(rateCodeItem?.guid);
                                        } else {
                                          handleShowHideRow(
                                            rateCodeItem?.guid,
                                            rateCodeItem
                                          );
                                        }
                                      }}
                                    >
                                      <p>{rateCodeItem?.name}</p>
                                      <img src={downArrow} />
                                    </div>
                                  </td>
                                  <td>{rateCodeItem?.room}</td>
                                  <td className="border_right">
                                    {rateCodeItem?.lyRoom}
                                  </td>
                                  <td className="border_right">
                                    {rateCodeItem?.pickupFrom}
                                  </td>
                                  {/* <td className="border_right">{rateCodeItem?.pickupAdr}</td> */}
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.adr
                                    )}
                                  </td>
                                  <td className="text-end border_right">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyAdr
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.revenue
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyRevenue
                                    )}
                                  </td>
                                </tr>
                                {Array.isArray(rateCodeItem?.items) &&
                                  rateCodeItem?.items?.length > 0 &&
                                  rateCodeItem?.items?.map(
                                    (companyItem, companyIndex) => (
                                      <>
                                        <tr
                                          className={`${rateCodeItem?.guid} nestedTableRowNone`}
                                        >
                                          <td
                                            className="border_right"
                                            style={{ paddingLeft: "20px" }}
                                          >
                                            <div className="d-flex gap-2">
                                              <p>{companyItem?.name}</p>
                                            </div>
                                          </td>
                                          <td>{companyItem?.room}</td>
                                          <td className="border_right">
                                            {companyItem?.lyRoom}
                                          </td>
                                          <td className="border_right">
                                            {companyItem?.pickupFrom}
                                          </td>
                                          {/* <td className="border_right">{companyItem?.pickupAdr}</td> */}
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.adr
                                            )}
                                          </td>
                                          <td className="text-end border_right">
                                            {commonService.formateAmount(
                                              companyItem?.lyAdr
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.revenue
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.lyRevenue
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                              </>
                            ))}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDateRMSModal;
