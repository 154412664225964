import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Table } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { errorToastMessage } from "../../../../utils/toastMessage";
import moment from "moment";

const GroupCommon = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const OTBTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const TransientTableData = [
    { month: "January", rms: 0, adr: 0, rev: 0 },
    { month: "February", rms: 0, adr: 0, rev: 0 },
    { month: "March", rms: 0, adr: 0, rev: 0 },
    { month: "April", rms: 0, adr: 0, rev: 0 },
    { month: "May", rms: 0, adr: 0, rev: 0 },
    { month: "June", rms: 0, adr: 0, rev: 0 },
    { month: "July", rms: 0, adr: 0, rev: 0 },
    { month: "August", rms: 0, adr: 0, rev: 0 },
    { month: "September", rms: 0, adr: 0, rev: 0 },
    { month: "October", rms: 0, adr: 0, rev: 0 },
    { month: "November", rms: 0, adr: 0, rev: 0 },
    { month: "December", rms: 0, adr: 0, rev: 0 },
  ];
  const TransientLastYearTableData = [
    { month: "January", rms: 0, adr: 0, rev: 0 },
    { month: "February", rms: 0, adr: 0, rev: 0 },
    { month: "March", rms: 0, adr: 0, rev: 0 },
    { month: "April", rms: 0, adr: 0, rev: 0 },
    { month: "May", rms: 0, adr: 0, rev: 0 },
    { month: "June", rms: 0, adr: 0, rev: 0 },
    { month: "July", rms: 0, adr: 0, rev: 0 },
    { month: "August", rms: 0, adr: 0, rev: 0 },
    { month: "September", rms: 0, adr: 0, rev: 0 },
    { month: "October", rms: 0, adr: 0, rev: 0 },
    { month: "November", rms: 0, adr: 0, rev: 0 },
    { month: "December", rms: 0, adr: 0, rev: 0 },
  ];
  const GroupTableData = [
    { month: "January", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "February", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "March", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "April", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "May", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "June", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "July", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "August", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "September", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "October", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "November", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "December", rms: 0, adr: 0, rev: 0, blk: 0 },
  ];
  const GroupLastYearTableData = [
    { month: "January", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "February", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "March", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "April", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "May", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "June", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "July", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "August", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "September", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "October", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "November", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "December", rms: 0, adr: 0, rev: 0, blk: 0 },
  ];

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [OTBData, setOTBData] = useState(null);
  const [transientData, setTransientData] = useState(null);
  const [transientLastYearData, setTransientLastYearData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [groupLastYearData, setGroupLastYearData] = useState(null);

  const [otbLoading, setOtbLoading] = useState(false);
  const [transientLoading, setTransientLoading] = useState(false);
  const [transientLastYearLoading, setTransientLastYearLoading] =
    useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupLastYearLoading, setGroupLastYearLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let otbocc = 0,
    otbrms = 0,
    otbadr = 0,
    otbrev = 0,
    TransientRms = 0,
    TransientAdr = 0,
    TransientRev = 0,
    TransientLastYearRms = 0,
    TransientLastYearAdr = 0,
    TransientLastYearRev = 0,
    groupRms = 0,
    groupBlk = 0,
    groupAdr = 0,
    groupRev = 0,
    groupLastYearRms = 0,
    groupLastYearBlk = 0,
    groupLastYearAdr = 0,
    groupLastYearRev = 0;

  const getOnTheBookDetail = async () => {
    setOtbLoading(true);
    let onTheBookResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.ON_THE_BOOK,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, onTheBookResponse);
    if (response?.isValidate) {
      setOTBData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOTBData(OTBTableData);
      setOtbLoading(false);
    }
  };
  const getTransientDetail = async () => {
    setTransientLoading(true);
    let transientDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.TRANSIENT_GROUP.TRANSIENT,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, transientDetailResponse);

    if (response?.isValidate) {
      setTransientData(response?.data?.data);
      setTransientLoading(false);
    }

    if (!response?.isValidate) {
      setTransientData(TransientTableData);
      setTransientLoading(false);
    }
  };
  const getTransientLastYearDetail = async () => {
    setTransientLastYearLoading(true);
    let transientLastYearDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.TRANSIENT_GROUP.TRANSIENT_LAST_YEAR,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, transientLastYearDetailResponse);

    if (response?.isValidate) {
      setTransientLastYearData(response?.data?.data);
      setTransientLastYearLoading(false);
    }

    if (!response?.isValidate) {
      setTransientLastYearData(TransientLastYearTableData);
      setTransientLastYearLoading(false);
    }
  };
  const getGroupDetail = async () => {
    setGroupLoading(true);
    let groupDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.TRANSIENT_GROUP.GROUP,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupDetailResponse);

    if (response?.isValidate) {
      setGroupData(response?.data?.data);
      setGroupLoading(false);
    }

    if (!response?.isValidate) {
      setGroupData(GroupTableData);
      setGroupLoading(false);
    }
  };
  const getGroupLastYearDetail = async () => {
    setGroupLastYearLoading(true);
    let groupLastYearDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.TRANSIENT_GROUP.GROUP_LAST_YEAR,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupLastYearDetailResponse);

    if (response?.isValidate) {
      setGroupLastYearData(response?.data?.data);
      setGroupLastYearLoading(false);
    }

    if (!response?.isValidate) {
      setGroupLastYearData(GroupLastYearTableData);
      setGroupLastYearLoading(false);
    }
  };

  const [tblids, settblids] = useState({
    groupCommonMonth: commonService.GenerateGUID(),
    onThebookWidgetGroup: commonService.GenerateGUID(),
    tblTransientGroup: commonService.GenerateGUID(),
    tblTransientLastYearGroup: commonService.GenerateGUID(),
    tblGroupGroup: commonService.GenerateGUID(),
    tblGroupLastYearGroup: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.onThebookWidgetGroup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblTransientGroup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblTransientLastYearGroup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblGroupGroup} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblGroupLastYearGroup} .perbackground-rev`
  );

  useEffect(() => {
    if (api === 0) {
      setOTBData(OTBTableData);
      setTransientData(TransientTableData);
      setTransientLastYearData(TransientLastYearTableData);
      setGroupData(GroupTableData);
      setGroupLastYearData(GroupLastYearTableData);
    } else {
      if (para?.asOfDate) {
        getOnTheBookDetail();
        getTransientDetail();
        getTransientLastYearDetail();
        getGroupDetail();
        getGroupLastYearDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  let AllWidgetContainer = document.getElementById("GroupAllWidgetInSingle");
  if (AllWidgetContainer !== null) {
    let CW = AllWidgetContainer.clientWidth;
    CW = CW - 120;
    CW = Math.round(CW / 5);
    if (CW < 250) {
      CW = 250;
    }
    let Widget = AllWidgetContainer.getElementsByClassName(
      "allTableTogather-widget"
    );
    if (Widget !== null && Widget.length !== 0) {
      for (let i = 0; i < Widget.length; i++) {
        Widget[i].style.width = CW + "px";
      }
    }
  }

  const handleExport = async (type) => {
    let tableId = document.querySelector("#GroupAllWidgetInSingle");

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-AnnualSummary-${moment(
        new Date()
      ).format("MMDDYYYY")}`,
    };

    let groupingRowData = [
      {
        widgetname: "GroupCommon",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [{ data: ["Total"] }],
      },
    ];

    let rowItem = document.querySelector("#GroupAllWidgetInSingle");

    groupingRowData[0].rowdata.push({
      data: para?.rowTitle ? [para?.rowTitle] : [],
    });
    groupingRowData[0].rowdata.push({
      data: [],
    });

    let findMonthTable = rowItem?.querySelector(
      ".allTableTogatherWrapper .ViewPropertySetupTable table"
    );

    let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
    let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

    findMonthTableHeader?.forEach((tblHeaderItem, tblHeaderIndex) => {
      if (tblHeaderIndex === 0) {
        let text = tblHeaderItem.querySelector("tr th span").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      } else {
        let text = tblHeaderItem.querySelector("tr th").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    findMonthTableTbody?.forEach((tblBodyItem, tblBodyIndex) => {
      let text = tblBodyItem.querySelector("td").innerText;

      if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
      } else {
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    const getAllTableData = (tbl) => {
      let findMonthTable = tbl?.querySelector(
        ".allTableTogather-widget .ViewPropertySetupTable table"
      );

      let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
      let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

      findMonthTableHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
        let tblTh = tblHeaderItem.querySelectorAll("tr th");
        let tblSpan = "";
        if (rowIndex === 0) {
          tblSpan = tblHeaderItem.querySelector("tr th span");
        }

        tblTh?.forEach((tblThItem) => {
          let datePickerExists = tblThItem.querySelector(
            ".react-datepicker-wrapper"
          );

          if (!datePickerExists) {
            let text = tblSpan ? tblSpan?.innerText : tblThItem?.innerText;
            groupingRowData[0].rowdata[tblHeaderIndex + 2].data.push(
              text ? (text === "ema" ? "SYSTEM" : text) : ""
            );
          }
        });
      });

      findMonthTableTbody.forEach((tblBodyItem, tblBodyIndex) => {
        let tblTd = tblBodyItem.querySelectorAll("td");

        tblTd?.forEach((tblTdItem, tblTdIndex) => {
          let text = tblTdItem?.innerText;
          if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
            totalJson[0].rowdata[0].data.push(text ? text : "");
          } else {
            groupingRowData[0].rowdata[tblBodyIndex + 4].data.push(
              text ? text : ""
            );
          }
        });
      });
    };

    let findOtherTable = rowItem?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget"
    );

    findOtherTable?.forEach((otherTblItem) => {
      getAllTableData(otherTblItem);
    });

    groupingJson.data = [...groupingJson.data, ...groupingRowData];
    groupingJson.data = [...groupingJson.data, ...totalJson];
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  const handleGroupChat = () => {
    let tableId = document?.querySelector(`#GroupAllWidgetInSingle`);

    if (tableId?.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }
    let groupingJson = {
      propertyid: para?.propertyDetail?.propertyid,
      clientid: userDetail?.clientid,
      userid: userDetail?.userid,
      prompt: "Please generate summary",
      json: {},
    };

    let getAllTable = tableId?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget .ViewPropertySetupTable .stripped__table table"
    );

    getAllTable?.forEach((tableItem, tableIndex) => {
      let getTbody = tableItem?.querySelectorAll("tbody tr");
      let tableName = "On The Books";
      if (tableIndex === 1) {
        tableName = "Transient";
      } else if (tableIndex === 2) {
        tableName = "Transient Last Year";
      } else if (tableIndex === 3) {
        tableName = "Group";
      } else if (tableIndex === 4) {
        tableName = "Group Last Year";
      }
      let arr = [];
      const removeSymbol = (value) => {
        let data = value?.replaceAll("$", "");
        let removePercentage = data?.replaceAll("%", "");
        let finalValue = removePercentage?.replaceAll(",", "");
        return finalValue;
      };

      getTbody?.forEach((tbodyItem, tbodyIndex) => {
        if (tbodyIndex !== getTbody?.length - 1) {
          let getAllTds = tbodyItem?.querySelectorAll("td");
          let obj = {
            propertyCode: para?.propertyCode,
            AsOfDate: para?.asOfDate,
            year: selectedYear,
            month: month[tbodyIndex],
          };

          getAllTds?.forEach((tdItem, tdIndex) => {
            if (tableIndex === 0) {
              if (tbodyIndex !== getTbody?.length - 1) {
                if (tdIndex == 0) {
                  obj.occ = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 1) {
                  obj.rms = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 2) {
                  obj.adr = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 3) {
                  obj.rev = removeSymbol(tdItem?.innerText);
                }
              }
            } else if (tableIndex === 1 || tableIndex === 2) {
              if (tbodyIndex !== getTbody?.length - 1) {
                if (tdIndex == 0) {
                  obj.rms = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 1) {
                  obj.adr = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 2) {
                  obj.rev = removeSymbol(tdItem?.innerText);
                }
              }
            } else {
              if (tbodyIndex !== getTbody?.length - 1) {
                if (tdIndex == 0) {
                  obj["otb/blk"] = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 1) {
                  obj.adr = removeSymbol(tdItem?.innerText);
                } else if (tdIndex === 2) {
                  obj.rev = removeSymbol(tdItem?.innerText);
                }
              }
            }
          });
          arr.push(obj);
        }
      });
      groupingJson.json[tableName] = arr;
    });
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.groupCommonMonth +
                      "," +
                      tblids.onThebookWidgetGroup +
                      "," +
                      tblids.tblTransientGroup +
                      "," +
                      tblids.tblTransientLastYearGroup +
                      "," +
                      tblids.tblGroupGroup +
                      "," +
                      tblids.tblGroupLastYearGroup,
                    "Group",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.groupCommon,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={
                tblids.groupCommonMonth +
                "," +
                tblids.onThebookWidgetGroup +
                "," +
                tblids.tblTransientGroup +
                "," +
                tblids.tblTransientLastYearGroup +
                "," +
                tblids.tblGroupGroup +
                "," +
                tblids.tblGroupLastYearGroup
              }
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.groupCommonMonth +
                      "," +
                      tblids.onThebookWidgetGroup +
                      "," +
                      tblids.tblTransientGroup +
                      "," +
                      tblids.tblTransientLastYearGroup +
                      "," +
                      tblids.tblGroupGroup +
                      "," +
                      tblids.tblGroupLastYearGroup,
                    // "Group"
                    `${para?.propertyCode}-Group-${moment(new Date()).format(
                      "MMDDYYYY"
                    )}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <Row>
        <div
          className="d-flex allTableTogatherWrapper"
          id="GroupAllWidgetInSingle"
        >
          <div style={{ width: "110px" }} className="pe-0">
            <div className="ViewPropertySetupTable monthSelectTable">
              <div className="stripped__table">
                <Table id={tblids.groupCommonMonth} responsive>
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            style={{ padding: 0 }}
                          >
                            <option value="">Select</option>
                            {yearsArr?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedYear}
                          </span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {month?.map((item, index) => (
                      <tr key={item}>
                        <td>{item}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="boldTd">Total</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="allTableTogather">
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.onThebookWidgetGroup} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          On The Books
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        OTBData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === OTBData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : otbLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        OTBData &&
                        month?.map((item, index) => {
                          let findMonthData = OTBData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.occ) {
                            otbocc = otbocc + findMonthData?.[0]?.occ;
                          } else {
                            otbocc = otbocc + 0;
                          }
                          if (findMonthData?.[0]?.rms) {
                            otbrms = otbrms + findMonthData?.[0]?.rms;
                          } else {
                            otbrms = otbrms + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            otbadr = otbadr + findMonthData?.[0]?.adr;
                          } else {
                            otbadr = otbadr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            otbrev = otbrev + findMonthData?.[0]?.rev;
                          } else {
                            otbrev = otbrev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(otbocc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(otbrms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {otbrev !== 0 && otbrms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(otbrev / otbrms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(otbrev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.tblTransientGroup} responsive>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          Transient
                        </th>
                      </tr>
                      <tr>
                        <th>RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        transientData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === transientData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : transientLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        transientData &&
                        month?.map((item, index) => {
                          let findMonthData = transientData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.rms) {
                            TransientRms =
                              TransientRms + findMonthData?.[0]?.rms;
                          } else {
                            TransientRms = TransientRms + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            TransientAdr =
                              TransientAdr + findMonthData?.[0]?.adr;
                          } else {
                            TransientAdr = TransientAdr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            TransientRev =
                              TransientRev + findMonthData?.[0]?.rev;
                          } else {
                            TransientRev = TransientRev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {commonService.formateRoom(TransientRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {TransientRev !== 0 && TransientRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(TransientRev / TransientRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(TransientRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.tblTransientLastYearGroup} responsive>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          Transient Last Year
                        </th>
                      </tr>
                      <tr>
                        <th>RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        transientLastYearData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === transientLastYearData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : transientLastYearLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        transientLastYearData &&
                        month?.map((item, index) => {
                          let findMonthData = transientLastYearData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.rms) {
                            TransientLastYearRms =
                              TransientLastYearRms + findMonthData?.[0]?.rms;
                          } else {
                            TransientLastYearRms = TransientLastYearRms + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            TransientLastYearAdr =
                              TransientLastYearAdr + findMonthData?.[0]?.adr;
                          } else {
                            TransientLastYearAdr = TransientLastYearAdr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            TransientLastYearRev =
                              TransientLastYearRev + findMonthData?.[0]?.rev;
                          } else {
                            TransientLastYearRev = TransientLastYearRev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {commonService.formateRoom(
                                      TransientLastYearRms
                                    )}
                                  </td>
                                  <td className="boldTd text-end">
                                    {TransientLastYearRev !== 0 &&
                                    TransientLastYearRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(
                                            TransientLastYearRev /
                                              TransientLastYearRms
                                          )
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      TransientLastYearRev
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.tblGroupGroup} responsive>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          Group
                        </th>
                      </tr>
                      <tr>
                        <th>OTB/BLK</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        groupData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>
                                {item?.rms}/{item?.blk}
                              </td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === groupData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0/0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : groupLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        groupData &&
                        month?.map((item, index) => {
                          let findMonthData = groupData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.rms) {
                            groupRms = groupRms + findMonthData?.[0]?.rms;
                          } else {
                            groupRms = groupRms + 0;
                          }
                          if (findMonthData?.[0]?.blk) {
                            groupBlk = groupBlk + findMonthData?.[0]?.blk;
                          } else {
                            groupBlk = groupBlk + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            groupAdr = groupAdr + findMonthData?.[0]?.adr;
                          } else {
                            groupAdr = groupAdr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            groupRev = groupRev + findMonthData?.[0]?.rev;
                          } else {
                            groupRev = groupRev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                  /
                                  {findMonthData?.[0]?.blk
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.blk
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {commonService.formateRoom(groupRms)}/
                                    {commonService.formateRoom(groupBlk)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {groupRev !== 0 && groupRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(groupRev / groupRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(groupRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table responsive id={tblids.tblGroupLastYearGroup}>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          Group Last Year
                        </th>
                      </tr>
                      <tr>
                        <th>OTB/BLK</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        groupLastYearData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>
                                {item?.rms}/{item?.blk}
                              </td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === groupLastYearData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0/0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : groupLastYearLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        groupLastYearData &&
                        month?.map((item, index) => {
                          let findMonthData = groupLastYearData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.rms) {
                            groupLastYearRms =
                              groupLastYearRms + findMonthData?.[0]?.rms;
                          }
                          if (findMonthData?.[0]?.blk) {
                            groupLastYearBlk =
                              groupLastYearBlk + findMonthData?.[0]?.blk;
                          }
                          if (findMonthData?.[0]?.adr) {
                            groupLastYearAdr =
                              groupLastYearAdr + findMonthData?.[0]?.adr;
                          }
                          if (findMonthData?.[0]?.rev) {
                            groupLastYearRev =
                              groupLastYearRev + findMonthData?.[0]?.rev;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                  /
                                  {findMonthData?.[0]?.blk
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.blk
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {commonService.formateRoom(
                                      groupLastYearRms
                                    )}
                                    /
                                    {commonService.formateRoom(
                                      groupLastYearBlk
                                    )}
                                  </td>
                                  <td className="boldTd text-end">
                                    {groupLastYearRev !== 0 &&
                                    groupLastYearRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(
                                            groupLastYearRev / groupLastYearRms
                                          )
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      groupLastYearRev
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {api !== 0 && <Comments widgetDetails={para} editorId={"GroupCommon"} />}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default GroupCommon;
